import * as React from 'react';
import styled from 'styles/Styled';

interface IProps {
  title: string;
  subtitle?: string;
}

const StyledComponent = styled.section`
  .title {
    padding-bottom: 0.5rem;
    font-size: 1.15rem;
    font-weight: 800;
  }
`;

const EastimationItem: React.FC<IProps> = ({ title, subtitle, children }) => (
  <StyledComponent>
    <h6 className="title">{title}</h6>
    <h6
      className="sub-title"
      dangerouslySetInnerHTML={{ __html: subtitle || '' }}
    />
    {children}
  </StyledComponent>
);

export default EastimationItem;
