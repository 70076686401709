import * as React from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Input,
  ButtonGroup,
  Button,
} from 'reactstrap';

import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import koStrings from '../../../../meta/ko';

interface IProps {
  dataSource: { [key: string]: string };
  callback: Function;
  onSubmit: Function;
}

interface MarkupArray {
  type: HTMLElement;
}

const { useState, useEffect, useCallback } = React;

const {
  clientSearch: {
    inputDropdowns,
    searchFunction,
    searchFunction: { filters },
  },
} = koStrings;

const StyledComponent = styled.form`
  ${({ theme: { colors } }) => css`
    padding: 1rem;
    border-bottom: 1px solid ${colors.border};

    .estimation-search {
      &__buttons {
        min-height: 25.5px;
      }
    }

    ${media.mobile`
      margin-bottom: 1rem;
      padding: 0;
      border-bottom: none;
    `}
  `}
`;

const EstimationSearch: React.FC<IProps> = ({
  dataSource,
  callback,
  onSubmit,
}) => {
  const [state, setState] = useState(dataSource);
  const [inputDropdownMarkup, setInputDropdownMarkup] = useState<MarkupArray[]>(
    [],
  );
  const [filterButtonsMarkup, setFilterButtonsMarkup] = useState<MarkupArray[]>(
    [],
  );

  const handleInput = useCallback(
    e => {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    },
    [state],
  );

  const handleChangeStatus = useCallback(
    estStatus => {
      const nextState = {
        ...state,
        estStatus,
      };
      setState(nextState);
    },
    [state],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  useEffect(() => {
    const inputDropdownMarkup = inputDropdowns.map(input => {
      return (
        <option value={input.value} key={input.value}>
          {input.string}
        </option>
      );
    });
    setInputDropdownMarkup(inputDropdownMarkup);
  }, []);

  useEffect(() => {
    const filtersMarkup = filters.map((filter, index) => {
      return (
        <Button
          key={index}
          type="button"
          size="sm"
          color={state.estStatus === filter.status ? 'success' : 'secondary'}
          onClick={() => handleChangeStatus(filter.status)}
        >
          {filter.string}
        </Button>
      );
    });
    setFilterButtonsMarkup(filtersMarkup);
  }, [handleChangeStatus, state.estStatus]);

  useEffect(() => {
    callback(state);
  }, [state, callback]);

  return (
    <StyledComponent onSubmit={handleSubmit}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Input
            type="select"
            name="searchType"
            value={state.searchType}
            onChange={handleInput}
          >
            {inputDropdownMarkup}
          </Input>
        </InputGroupAddon>
        <Input
          name="searchKeyword"
          placeholder="검색어"
          value={state.searchKeyword}
          onChange={handleInput}
        />
        <InputGroupAddon addonType="append">
          <Button type="submit" color="primary">
            {searchFunction.buttonString}
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <div className="mt-2 d-flex estimation-search__buttons">
        {/* 검색어가 없을 경우에만 상태 검색 노출 */}
        {!state.searchKeyword && (
          <ButtonGroup>{filterButtonsMarkup}</ButtonGroup>
        )}
        {/* <Input type="select" bsSize="sm" className="w-auto ml-auto"></Input> */}
      </div>
    </StyledComponent>
  );
};

export default EstimationSearch;
