import * as React from 'react';
import Section from 'elements/ui/section';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import sampleImage from './images/sample-image.png';

interface IProps {}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .my-shop-sample {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 0.95rem;
        color: ${colors.primary};
      }

      small {
        margin: 0.25rem;
        font-size: 0.85rem;
        color: ${colors.gray3};
      }

      img {
        width: 250px;
        margin-top: 1rem;
      }

      ${media.mobile`
        display: none;
      `}
    }
  `}
`;

const MyShopSample: React.FC<IProps> = () => (
  <StyledComponent>
    <Section className="my-shop-sample" l={5} r={2}>
      <p>실제 유저에게 노출되는 화면입니다.</p>
      <small>(업체 등록에 필요한 정보를 우측에 입력해주세요)</small>
      <img src={sampleImage} alt="예시 이미지" />
    </Section>
  </StyledComponent>
);

export default MyShopSample;
