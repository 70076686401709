import * as React from 'react';
import { Button } from 'reactstrap';
import styled, { css } from 'styled-components';

import useFileResult from 'core/hooks/form/UseFileResult';
import Icon from 'elements/helper/icon';
import OriginImage from 'elements/helper/originImage';

interface IProps {
  isSending: boolean;
  onSendMessage: (message: string) => void;
  onSendFiles: (files: FileList) => void;
  onAddFile: () => void;
}

const { useRef, useState, useEffect, useCallback } = React;
const StyledComponent = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    border-top: 1px solid ${colors.border};

    textarea,
    button {
      &:focus {
        outline: none !important;
      }
    }

    .chatting-detail-footer {
      &__send-wrap,
      &__button-wrap {
        padding: 0.5rem;
        button:nth-of-type(2) {
          margin-left: 0.5rem;
        }
      }

      &__image-wrap {
        padding: 0.35rem;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid ${colors.border};

        span {
          width: 49px;
          height: 49px;
          margin: 0.15rem;
          position: relative;
          border: 1px solid ${colors.border};
          border-radius: 2px;
          overflow: hidden;

          i {
            position: absolute;
            z-index: 1;
            top: 1px;
            right: 3px;
            color: ${colors.gray2};
            transition: color 300ms ease;

            &:hover {
              color: ${colors.black};
              cursor: pointer;
            }
          }

          img {
            min-height: 100%;
            position: relative;
            top: -1px;
            left: -1px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &__send-wrap {
        display: flex;
        border-bottom: 1px solid ${colors.border};

        textarea {
          flex: 1;
          border: none;
          resize: none;
        }

        .submit-button {
          width: 4.25rem;
        }
      }

      &__button-wrap {
        button {
          padding: 0.5rem 0.75rem;
          border: 1px solid ${colors.border};
          border-radius: ${sizes.borderRadius};
          background-color: white;
          color: ${colors.gray1};
          font-size: 0.925rem;
          transition: color 500ms, border-color 500ms ease;

          span {
            margin-left: 0.25rem;
          }

          &:hover {
            border: 1px solid ${colors.gray3};
            color: ${colors.gray1};
          }
        }
      }
    }
  `}
`;

const ChattingDetailFooter: React.FC<IProps> = ({
  isSending,
  onSendMessage,
  onSendFiles,
  onAddFile,
}) => {
  const $textarea = useRef<any>(null);
  const imagesRef = useRef<any>(null);

  const [value, setValue] = useState('');
  const [images, setImages] = useState<any>([]);
  const [originImage, setOriginImage] = useState('');
  const [fileResults] = useFileResult(images);

  const handleSubmit = useCallback(() => {
    if (!value.trim() && !images.length) {
      // 메세지와 이미지가 모두 없다면 전송할 수 없음
      return;
    }

    if (!!value.trim()) {
      // 메세지가 있다면 메세지 전송
      onSendMessage(value);
    }

    if (!!images.length) {
      // 이미지가 있다면 이미지 전송
      onSendFiles(images);
    }

    setValue('');
    setImages([]);
  }, [value, images, onSendFiles, onSendMessage]);

  // 메세지 입력
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  // 키 입력 이벤트
  const handleKeyPress = useCallback(
    e => {
      if (!e.shiftKey && e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  // 이미지 삭제
  const handleRemoveImage = (index: number) => {
    setImages(images.filter((_: any, idx: number) => idx !== index));
  };

  // 클립보드의 이미지 추가
  const setImagePasteEvent = (e: ClipboardEvent) => {
    [].forEach.call(e.clipboardData?.items, (item: DataTransferItem) => {
      if (item.type.startsWith('image/')) {
        e.preventDefault();
        setImages([...imagesRef.current, item.getAsFile()]);
      }
    });
  };

  useEffect(() => {
    const $t = $textarea.current as HTMLTextAreaElement;

    // 마운트시 이미지 paste 이벤트 추가
    $t.addEventListener('paste', setImagePasteEvent);

    return () => {
      $t.removeEventListener('paste', setImagePasteEvent);
    };
  }, []);

  useEffect(() => {
    // addEventListener에서 최신 state를 기억할 수 있도록 담아 둠
    imagesRef.current = images;
  }, [images]);

  return (
    <>
      <StyledComponent>
        <OriginImage
          imageSrc={originImage}
          onClose={() => setOriginImage('')}
        />

        {!!fileResults?.length && (
          <div className="chatting-detail-footer__image-wrap">
            {fileResults?.map((result, index) => (
              <span key={index}>
                <i onClick={() => handleRemoveImage(index)}>
                  <Icon iconName="fa fa-times" />
                </i>
                <img
                  src={result}
                  alt=""
                  onClick={() => setOriginImage(result)}
                />
              </span>
            ))}
          </div>
        )}
        <div className="chatting-detail-footer__send-wrap">
          <textarea
            ref={$textarea}
            rows={5}
            value={value}
            onKeyPress={handleKeyPress}
            onChange={handleInput}
          />
          <Button
            type="submit"
            className="submit-button"
            color="primary"
            disabled={!value.trim() && !images.length}
            onClick={handleSubmit}
          >
            전송
          </Button>
        </div>
        <div className="chatting-detail-footer__button-wrap">
          <button type="button" disabled={isSending} onClick={onAddFile}>
            <Icon iconName="fa fa-camera" />{' '}
            <span>{isSending ? '전송중...' : '사진첨부'}</span>
          </button>
        </div>
      </StyledComponent>
    </>
  );
};

export default ChattingDetailFooter;
