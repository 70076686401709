import * as React from 'react';
import { IContProp } from 'containers/connect/chatting/chattingListCont/interface';
import PageTemp from 'components/template/pageTemp';
import ChattingList from 'components/connect/chatting/chattingList';
import Section from 'elements/ui/section';

const ChattingListPage: React.FC<IContProp> = ({
  pending,
  channels,
  selectedChannel,
  onLinkToDetail,
}) => {
  return (
    <PageTemp size={6} header={<PageTemp.Title>채팅</PageTemp.Title>}>
      <Section b={5}>
        <ChattingList
          pending={pending}
          items={channels}
          selectedChannelUrl={selectedChannel?.url || ''}
          onLinkToDetail={onLinkToDetail}
        />
      </Section>
    </PageTemp>
  );
};

export default ChattingListPage;
