import { cardocApiCreator } from 'core/api/ApiCreator';

/**
 * 후기 목록 조회
 */
export type TReviewItem = {
  reviewid: string;
  reqid: string;
  score: '0' | '1';
  desc: string;
  userdesc: string;
  pt_quality: string;
  pt_kindness: string;
  pt_est_accuracy: string;
  regdttm: string;
  files: {
    thumb_w222: string;
  }[];
};

interface GetReviewParams {
  page: number;
  service: string;
  score: string;
  itemCount: number;
}
export const getReviews = ({
  page,
  service = '',
  score = '',
  itemCount = 10,
}: GetReviewParams) => {
  const shopId = sessionStorage.getItem('connectShopId');
  return cardocApiCreator(
    `/v2/connectShopReview/getType/list/connectShopId/${shopId}/${
      !!service ? `service/${service}/` : ''
    }${!!score ? `score/${score}/` : ''}page/${page}/itemCnt/${itemCount}`,
  );
};
