export const numberFormat = (number: string | number) =>
  !number ? '' : String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const telNumberFormat = (telNumber: string) =>
  !telNumber
    ? ''
    : telNumber.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        '$1-$2-$3',
      );
