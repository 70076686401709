import * as React from 'react';
import toastr from 'toastr';

import useFetch from 'core/hooks/UseFetch';
import {
  getEstimationsByAuthuserkey,
  updateMemoMeta,
  getMemoMeta,
  updateAssignee,
} from 'core/api/estimation';

import ChattingEstPage from 'pages/connect/chatting/ChattingEstPage';
import { IContProp } from './interface';
import { IContProp as IParentContProps } from '../chattingCont/interface';

import koStrings from '../../../../meta/ko';

const {
  chattingEstimateContainer: {
    toastr: {
      memoAndAssignee: { updateSuccess, updateFail },
    },
  },
} = koStrings;

const { useState, useEffect, useCallback } = React;

const ChattingEstCont: React.FC<IParentContProps> = ({ user }) => {
  // 견적서 목록 조회 API
  // GET new memo meta
  // PUT responsibility (assignee)
  const [onFetch, , estimations] = useFetch(getEstimationsByAuthuserkey, null);
  // GET new memo meta and PUT responsibility (assignee)
  const [onGetMemoMetaReq] = useFetch(getMemoMeta, null);
  const [onUpdateAssignee] = useFetch(updateAssignee, null);

  const [estimationsState, updateEstimationsState] = useState(estimations);

  useEffect(() => {
    updateEstimationsState(estimations);
  }, [estimations]);

  const [state, setState] = useState({
    page: 1,
  });

  useEffect(() => {
    if (!user || !(user?.metaData as any).userkey) return;
    onFetch(state.page, (user.metaData as any).userkey);
    // eslint-disable-next-line
  }, [user, state.page]);

  // updateMemoMeta and updateAssignee in one function to prevent race conditions.
  const updateMemoMetaThenUpdateAssignee = useCallback(
    async (estimationId, memoMeta, assignee, indexToUpdate) => {
      try {
        // Update memoMeta (PUT), and then fetch additionally necessary memoMeta and assignee
        // data from appropriate endpoints, to then immutably 'insert' this data into 
        // Frontend state to pass down to appropriate components from higher up containers.
        await updateMemoMeta(estimationId, { description: memoMeta });
        const updatedMemoMeta = await onGetMemoMetaReq(estimationId);
        const updateAssignee = await onUpdateAssignee(estimationId, assignee);

        let newState = {
          ...estimationsState,
          content: [
            ...estimationsState.content.map(
              (estimationContent: any, index: number) => {
                if (index === indexToUpdate) {
                  const contentToUpdate = {
                    ...estimationContent,
                    connectEstimation: {
                      ...updateAssignee,
                    },
                    memoMeta: {
                      ...updatedMemoMeta,
                    },
                  };
                  return contentToUpdate;
                }
                return estimationContent;
              },
            ),
          ],
        };
        updateEstimationsState(newState);
        toastr.success(updateSuccess);
      } catch {
        toastr.error(updateFail);
      }
    },
    [estimationsState, onGetMemoMetaReq, onUpdateAssignee],
  );

  // 페이지 이동
  const handleChangePage = useCallback(
    nextPage => {
      setState({
        ...state,
        page: nextPage,
      });
    },
    [state],
  );

  const dataSource: IContProp = {
    page: state.page,
    itemCount: estimationsState?.totalElements || 0,
    perPage: estimationsState?.size || 0,
    estimations: estimationsState?.content || [],
    updateMemoMetaThenUpdateAssignee,
    onChangePage: handleChangePage,
  };

  return <ChattingEstPage {...dataSource} />;
};

export default ChattingEstCont;
