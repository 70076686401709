import * as React from 'react';
import { Button } from 'reactstrap';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import FormItem, { IFormItem } from './FormItem';

const { useMemo } = React;

interface IProps {
  form: IFormItem[];
  onSubmit: Function;
  onReset?: React.MouseEventHandler;
}

const StyledComponent = styled.form`
  ${({ theme: { colors } }) => css`
    padding: 0 1rem;
    border: 1px solid ${colors.border};
    background-color: ${colors.gray9};

    .input-wrap {
      padding: 1rem 1rem 0;
      display: flex;
      flex-wrap: wrap;
    }

    .button-wrap {
      width: 100%;
      padding: 0.5rem 0;
      border-top: 1px dotted ${colors.border};
      text-align: center;

      button {
        width: 7rem;
        margin: 0.25rem;
        padding: 0.65rem 0;
      }
    }

    ${media.mobile`
      .input-wrap {
        padding: 0.75rem 0 0;
      }
    `}
  `}
`;

const SearchForm: React.FC<IProps> = ({ form, onSubmit, onReset }) => {
  const formItems = useMemo(
    () => form.map((item, index) => <FormItem key={index} item={item} />),
    [form],
  );

  return (
    <StyledComponent
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="input-wrap">{formItems}</div>
      <div className="button-wrap">
        {onReset && (
          <Button type="button" onClick={onReset}>
            초기화
          </Button>
        )}
        <Button color="primary">검색</Button>
      </div>
    </StyledComponent>
  );
};

export default SearchForm;
