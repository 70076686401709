import * as React from 'react';
import styled from 'styles/Styled';
import media from 'styles/Media';
import { Input } from 'reactstrap';

const { useMemo } = React;

export type TFormType = 'element' | 'input' | 'select';
export interface IFormItem {
  name?: string;
  type: TFormType;
  style?: React.CSSProperties;
  label?: string;
  value?: string;
  element?: React.ReactNode;
  onChange?: (e: React.ChangeEvent) => void;
}

interface IProps {
  item: IFormItem;
}

const StyledComponent = styled.section`
  margin-bottom: 1rem;
  margin-right: 2rem;
  display: flex;
  flex-wrap: wrap;

  > label {
    margin: 0;
    margin-right: 1rem;
    padding-top: 0.35rem;
    font-weight: 500;
  }

  ${media.mobile`
    width: 100% !important;
    margin-right: 0;
    flex-direction: column;

    > label {
      margin-bottom: 0.35rem;
      margin-right: 0;
      padding-top: 0;
    }
  `}
`;

const FormItem: React.FC<IProps> = ({
  item: { name, type, style, label, value, element, onChange },
}) => {
  const content = useMemo(() => {
    if (element) {
      return element;
    }
    if (type === 'input') {
      return <Input name={name} value={value} onChange={onChange} />;
    }
    return null;
  }, [name, type, value, element, onChange]);

  return (
    <StyledComponent style={style}>
      {label && <label>{label}</label>}
      {content && <div>{content}</div>}
    </StyledComponent>
  );
};

export default FormItem;
