import * as React from 'react';
import { Route } from 'react-router-dom';
import toastr from 'toastr';

import * as PATH from 'core/consts/Path';
import { connect } from 'core/sdk/sendbird/connection';
import { useAppContext } from 'core/context/appContext';
import { account, logout } from 'core/api/account';
import Loading from 'elements/helper/loading';

interface IProps {
  path: string;
  component: React.FC;
}

const { useState, useEffect, useCallback } = React;

const WithAuthRoute: React.FC<IProps> = ({ path, component }) => {
  const { state: ctxState, setState: setCtxState } = useAppContext();
  const [pending, setPending] = useState(true);

  const fetch = useCallback(async () => {
    try {
      const { data }: any = await account();
      const { sendbirdId, shop: connectShop } = data.result;
      const nextCtxState = {
        ...ctxState,
        sendbirdId,
        connectShop,
      };

      // 샵 아이디를 session storage에 저장 해둠
      sessionStorage.setItem('connectShopId', connectShop.connectShopId);

      // 샌드버드 커넥션
      if (!!sendbirdId) {
        const sendbirdConnection = connect(sendbirdId);
        nextCtxState.sendbirdConnection = sendbirdConnection;
      }

      // 컨텍스트 데이터 저장
      setCtxState({
        ...nextCtxState,
      });

      // 백오피스 준비 완료
      setPending(false);
    } catch (e) {
      toastr.error('권한이 없습니다.');
      await logout();

      window.sessionStorage.setItem('redirectionPath', window.location.href);
      window.location.href = PATH.LOGIN;
    }
  }, [ctxState, setCtxState]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  if (pending) {
    return <Loading>샵 정보를 불러오고 있습니다.</Loading>;
  }

  return <Route path={path} component={component} />;
};

export default WithAuthRoute;
