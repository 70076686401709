import * as React from 'react';
import { Input, Button } from 'reactstrap';
import styled, { css } from 'styles/Styled';

import EstimationSection from 'components/connect/estimation/estimationSection';

import koStrings from '../../../../meta/ko';

interface IProps {
  pending: boolean;
  defaultValue: string;
  callback: Function;
}

const { useState, useEffect } = React;

const {
  estimationAssignResponsibility: {
    assignee: { title, assigneePlaceholder },
    saveButton: { edit, save, saving },
  },
} = koStrings;

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .estimation-request {
      &__assignee {
        height: auto;
        padding: 0.5rem 0.65rem 0.7rem 0.65rem;
        font-size: 1.35rem;
        font-weight: 600;
        text-align: center;

        &[disabled] {
          background-color: ${colors.gray8};
        }
        &::placeholder {
          font-weight: 500;
          font-size: 1.05rem;
          text-align: center;
        }
      }
    }
  `}
`;

const EstimationAssignResponsibility: React.FC<IProps> = ({
  pending,
  defaultValue,
  callback,
}) => {
  const [assigneeNameInput, setAssigneeNameInput] = useState(
    defaultValue || '',
  );
  const [editMode, activateEditMode] = useState(true);

  useEffect(() => {
    setAssigneeNameInput(defaultValue || '');
    if (defaultValue) {
      activateEditMode(false);
    }
  }, [defaultValue]);

  const activatedButtonText = editMode ? save : edit;

  const processFormButton = (e: any) => {
    e.preventDefault();
    if (!editMode) {
      activateEditMode(true);
    } else {
      callback(assigneeNameInput);
      activateEditMode(false);
    }
  };

  return (
    <StyledComponent>
      <EstimationSection title={title}>
        <form onSubmit={e => processFormButton(e)}>
          <Input
            className="estimation-request__assignee"
            disabled={!editMode}
            rows={1}
            placeholder={assigneePlaceholder}
            value={assigneeNameInput}
            onChange={e => setAssigneeNameInput(e.target.value)}
          />
          <div className="mt-2 text-right">
            <Button
              size="lg"
              color='primary'
              outline={!editMode}
              disabled={pending}
            >
              {pending ? saving : activatedButtonText}
            </Button>
          </div>
        </form>
      </EstimationSection>
    </StyledComponent>
  );
};

export default EstimationAssignResponsibility;
