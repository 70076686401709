import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { getReviews } from 'core/api/Review';
import { REVIEW as REVIEW_PAGE } from 'core/consts/Path';
import useFetch from 'core/hooks/UseFetch';
import ReviewPage from 'pages/review/reviewPage';
import OriginImage from 'elements/helper/originImage';
import { IContProps } from './interface';

const { useRef, useState, useEffect, useCallback } = React;

const ReviewCont: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const queryParams = {
    page: params.page ? Number(params.page) : 1,
    service: params.service || '',
    score: params.score || '',
  };

  const [state, setState] = useState({
    originImgSrc: '',
  });

  // 검색 폼
  const stateRef = useRef<{
    form: {
      [key: string]: string;
    };
  }>({
    form: {
      service: queryParams.service as string,
      score: queryParams.score as string,
    },
  });

  // 후기 목록 조회
  const [fetch, pending, reviewItem] = useFetch(getReviews, null);

  // 후기 조회하기
  const getReviewItems = useCallback(() => {
    fetch(queryParams);
  }, [queryParams, fetch]);

  // 검색 폼 입력
  const handleInputForm = useCallback((key, value) => {
    stateRef.current.form[key] = value;
  }, []);

  // 페이지 변경
  const handleChangePage = useCallback(
    page => {
      history.push(
        `${REVIEW_PAGE}?${queryString.stringify({
          ...queryParams,
          page,
        })}`,
      );
    },
    [history, queryParams],
  );

  // 검색
  const handleSearch = useCallback(() => {
    history.push(
      `${REVIEW_PAGE}?${queryString.stringify({
        page: 1,
        ...stateRef.current.form,
      })}`,
    );
  }, [history]);

  // 원본 이미지 보기
  const handleShowOriginImage = useCallback(
    originImgSrc => {
      setState({
        ...state,
        originImgSrc,
      });
    },
    [state],
  );

  const dataSource: IContProps = {
    pending,
    page: queryParams.page,
    form: stateRef.current.form,
    items: reviewItem?.result?.list || [],
    summary: reviewItem?.result?.summary || {},
    isSelectedScore: !!queryParams.score,
    onInputForm: handleInputForm,
    onSearch: handleSearch,
    onChangePage: handleChangePage,
    onShowOriginImage: handleShowOriginImage,
  };

  // 페이지 변경시 목록 조회
  useEffect(() => {
    getReviewItems();
    // eslint-disable-next-line
  }, [queryParams.page, queryParams.service, queryParams.score]);

  return (
    <>
      <ReviewPage {...dataSource} />
      <OriginImage
        imageSrc={state.originImgSrc}
        onClose={() => handleShowOriginImage('')}
      />
    </>
  );
};

export default ReviewCont;
