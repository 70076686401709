import * as React from 'react';
import styled from 'styles/Styled';
import Icon from 'elements/helper/icon';
import Button from 'elements/form/button';
import CsCenterModal from 'elements/helper/csCenterModal';

const { useState } = React;
const StyledComponent = styled.div`
  padding: 7.5rem 0;
  text-align: center;

  .add-field {
    &__main-title {
      font-size: 2.5rem;
      font-weight: 800;
    }

    &__process-button {
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      button {
        width: 200px;
        height: 55px;
        border-radius: 2.5rem;
        font-size: 1.35rem;
        font-weight: 500;
      }
    }
  }
`;

const ActivityField: React.FC = () => {
  const [showCsModal, setShowCsModal] = useState(false);

  return (
    <StyledComponent>
      {/* 고객센터 모달 */}
      <CsCenterModal show={showCsModal} onClose={() => setShowCsModal(false)}>
        활동분야를 변경하시려면 카닥 고객센터로 문의해주시기 바랍니다.
      </CsCenterModal>

      <h1 className="add-field__main-title">활동분야를 추가해주세요.</h1>
      <div className="add-field__process-button">
        <Button color="primary" onClick={() => setShowCsModal(true)}>
          <Icon iconName="fa fa-plus" /> 활동분야 추가
        </Button>
      </div>
      <a
        href="https://static.cardoc.co.kr/partners/cardoc_partners_service_info_v2.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="add-field__download-button"
      >
        서비스 소개서 다운로드
      </a>
    </StyledComponent>
  );
};

export default ActivityField;
