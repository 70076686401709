import * as React from 'react';
import styled from 'styles/Styled';

interface IProps {
  searchForm?: JSX.Element;
  pagination?: JSX.Element;
}

const Component = styled.div`
  .board-temp {
    &__search-form {
      margin-bottom: 2rem;
    }

    &__pagination {
      margin-top: 2rem;
    }

    &__content {
      position: relative;
    }
  }
`;

const BoardListTemp: React.FC<IProps> = ({
  searchForm,
  pagination,
  children,
}) => (
  <Component>
    {!!searchForm && (
      <div className="board-temp__search-form">{searchForm}</div>
    )}
    <div className="board-temp__content">{children}</div>
    {!!pagination && <div className="board-temp__pagination">{pagination}</div>}
  </Component>
);

export default BoardListTemp;
