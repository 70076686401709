import { ThemedCssFunction, css } from 'styled-components';
import theme from './Theme';

export default (Object.keys(
  theme.breakPoint,
) as (keyof typeof theme.breakPoint)[]).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (max-width: ${theme.breakPoint[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {} as { [key in keyof typeof theme.breakPoint]: ThemedCssFunction<{ [key: string]: string | number }> });
