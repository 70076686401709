import * as React from 'react';
import styled, { css } from 'styles/Styled';
import Icon from 'elements/helper/icon';

interface IProps {
  isShow: boolean;
  onSelect: Function;
  onClose: Function;
}

const { useRef, useEffect, useCallback } = React;
const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);

    .wrap {
      width: 550px;
      height: 650px;
      max-width: 90%;
      max-height: 80%;
      position: relative;
      border: 1px solid ${colors.gray2};
      background-color: white;

      .close-button {
        position: absolute;
        top: -32px;
        right: -7px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        outline: none;
        transition: color 500ms ease;

        &:hover {
          color: ${colors.black};
        }
      }
    }
  `}
`;

const Postcode: React.FC<IProps> = ({ isShow, onSelect, onClose }) => {
  const $el = useRef(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleShow = useCallback(() => {
    new (window as any).daum.Postcode({
      oncomplete: (data: any) => {
        onSelect({
          address: data.roadAddress,
          zipcode: data.zonecode,
        });
      },
      width: '100%',
      height: '100%',
    }).embed($el.current);
  }, [onSelect]);

  useEffect(() => {
    if (isShow) {
      handleShow();
    }
  }, [isShow, handleShow]);

  return isShow ? (
    <StyledComponent>
      <div className="wrap" ref={$el}>
        <button className="close-button" onClick={handleClose}>
          <Icon iconName="fa fa-times" />
        </button>
      </div>
    </StyledComponent>
  ) : null;
};

export default Postcode;
