import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import Textarea from 'elements/form/textarea';
import Icon from 'elements/helper/icon';

interface IProps {
  defaultValue: string;
  onChange: (nextState: string) => void;
}

const ARROW_SIZE = 1;
const MESSAGE =
  '안녕하세요, [카닥 모터스] 인사드립니다.\n[20년 째] [서울 송파구] 지역에서 활동하며 단골 고객분들의 사랑을 받고 있습니다.\n[문정역 테라타워 지하주차장 3층]에 위치해 있으며, 찾기 어려우시면 연락 주세요.\n[사고수리, 엔진오일 외에도 소모품]을 모두 점검해 드리고 있습니다.';

const { useState, useEffect, useCallback } = React;
const StyledComponent = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    position: relative;

    .view-example-button {
      font-size: 0.85rem;
      font-weight: 300;
      text-decoration: underline;
      color: ${colors.primary};

      &:hover {
        cursor: pointer;
      }
    }

    .example-wrap {
      width: 22.625rem;
      padding: 1.5rem;
      display: none;
      position: absolute;
      z-index: 5;
      top: 2px;
      left: calc(100% + 2rem);
      border-radius: ${sizes.radius};
      border-top-left-radius: 0;
      background-color: ${colors.gray7};
      font-size: 0.925rem;
      color: ${colors.gray3};
      word-break: keep-all;
      white-space: pre-wrap;

      &:before {
        content: '';
        width: ${ARROW_SIZE}rem;
        height: ${ARROW_SIZE}rem;
        display: block;
        position: absolute;
        top: 0;
        left: -${ARROW_SIZE}rem;
        border-left: ${ARROW_SIZE}rem solid transparent;
        border-top: ${ARROW_SIZE}rem solid ${colors.gray7};
      }

      .close-button-wrap {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        cursor: pointer;
      }

      .copy-button-wrap {
        text-align: right;

        span {
          margin-top: 0.5rem;
          font-size: 0.925rem;
          color: ${colors.primary};

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    #shop-description-example {
      display: none;

      &:checked {
        + .example-wrap {
          display: block;
        }
      }
    }

    ${media.mobile`
      .view-example-button,
      .example-wrap {
        display: none !important;
      }
    `}
  `}
`;

const Description: React.FC<IProps> = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  const handleCopy = useCallback(() => {
    if (!!value && !window.confirm('작성된 소개글을 지우시겠습니까?')) {
      return;
    }
    setValue(MESSAGE);
  }, [value]);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <StyledComponent>
      <MyShopFormItem
        label="업체 소개글"
        labelSurffix={
          <label
            className="view-example-button"
            htmlFor="shop-description-example"
          >
            예시보기
          </label>
        }
      >
        <Textarea
          rows={10}
          maxLength={500}
          placeholder="업체소개 글을 작성할 수 있습니다."
          value={value || ''}
          onChange={handleChange}
        />
      </MyShopFormItem>
      <input id="shop-description-example" type="checkbox" defaultChecked />
      <div className="example-wrap">
        <span>
          <label
            className="close-button-wrap"
            htmlFor="shop-description-example"
          >
            <Icon iconName="fa fa-times" />
          </label>
        </span>
        <div>{`간단한 [인사말], [경력], [위치 설명], [제공 서비스]를 안내해주는 것이 좋습니다.\n\n예시)\n${MESSAGE}`}</div>
        <div className="copy-button-wrap">
          <span onClick={handleCopy}>복사</span>
        </div>
      </div>
    </StyledComponent>
  );
};

export default Description;
