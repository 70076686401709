import * as React from 'react';
import { Button } from 'reactstrap';
import styled, { css } from 'styles/Styled';

import EstimationSection from 'components/connect/estimation/estimationSection';
import InsuranceCertificateModal from 'reusable/InsuranceCertificateModal';

import { numberFormat } from 'core/function/Formatter';

import koStrings from '../../../../meta/ko';

interface IProps {
  finalizedCustomerInsuranceDetails: any;
}

const { useState } = React;

const {
  estimationFinalizedDetails: {
    title,
    details: {
      insuName,
      insuranceRenewalDate,
      price,
      propertyDamageLiability,
      bodilyInjuryCoverageDescription,
    },
    viewInsuranceVertificate: { buttonText },
  },
} = koStrings;

const StyledComponent = styled.div`
  ${() => css`
    .float-detail-right {
      float: right;
      font-size: 1rem;
      font-weight: 500;
      color: #4b4d51;
    }
    .dimmed-background-section {
      border-radius: 2px;
      background-color: #f2f2f2;
      padding: 1rem;
      * {
        padding-bottom: 8px;
        font-weight: 500;
      }
      div {
        color: #9d9d9d;
      }
      div:last-of-type {
        padding-bottom: 0.2rem;
      }
    }
    .view-insurance-certificate-button {
      margin-top: 0.5rem;
    }
  `}
`;

const EstimationFinalizedDetails: React.FC<IProps> = ({
  finalizedCustomerInsuranceDetails,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <StyledComponent>
      <InsuranceCertificateModal 
        isOpen={modal}
        toggle={toggle}
        insuranceCertificateUrl={finalizedCustomerInsuranceDetails.insuranceCertificate}
      />
      {Object.keys(finalizedCustomerInsuranceDetails).length !== 0 &&
        finalizedCustomerInsuranceDetails.constructor === Object && (
          <EstimationSection title={title}>
            <div className="dimmed-background-section">
              <div>
                {insuName}
                <span className="float-detail-right">
                  {
                    finalizedCustomerInsuranceDetails.cdCarInsuranceCorp
                      ?.insuName
                  }
                </span>
              </div>
              <div>
                {insuranceRenewalDate}
                <span className="float-detail-right">
                  {finalizedCustomerInsuranceDetails.insuranceRenewalDate?.slice(
                    0,
                    10,
                  )}
                </span>
              </div>
              <div>
                {price}
                <span className="float-detail-right">
                  {numberFormat(Number(finalizedCustomerInsuranceDetails.price))} 원
                </span>
              </div>
              <div>
                {propertyDamageLiability}
                <span className="float-detail-right">
                  {numberFormat(Number(finalizedCustomerInsuranceDetails.propertyDamageLiability))} 억
                </span>
              </div>
              <div>
                {bodilyInjuryCoverageDescription}
                <span className="float-detail-right">
                  {
                    finalizedCustomerInsuranceDetails.cdBodilyInjuryCoverage
                      ?.description
                  }
                </span>
              </div>
            </div>
            {finalizedCustomerInsuranceDetails.insuranceCertificate && (
              <div className="text-right">
                <Button
                  className="view-insurance-certificate-button"
                  size="lg"
                  color="primary"
                  onClick={toggle}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </EstimationSection>
        )}
    </StyledComponent>
  );
};

export default EstimationFinalizedDetails;
