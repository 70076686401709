import * as React from 'react';
import styled from 'styles/Styled';
import media from 'styles/Media';

import Section from 'elements/ui/section';
import Flexbox from 'elements/ui/flexbox';
import Input from 'elements/form/input';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import Address from 'components/myPage/address';
import TimeSelect from 'components/myPage/timeSelect';
import Description from 'components/myPage/description';
import PhotoSelect from 'components/myPage/photoSelect';
import TelNumber from 'components/myPage/telNumber';
import ActivityField from 'components/myPage/activityField';

interface IProps {
  defaultForm: any;
  errors: { [key: string]: string };
  onChange: (nextState: object) => void;
}

const Component = styled.div`
  ${media.mobile`
    .my-shop-form {&__twin-wrap {
        flex-direction: column;
      }

      &__twin-item {
        width: 100% !important;
      }

      &__twin-section {
        padding: 0;
      }

      &__activity-field {
        padding-left: 0;
      }

      .my-shop-form__twin-item + .my-shop-form__twin-item {
        padding-top: 1rem;
      }
    `}}
`;

const MyShopForm: React.FC<IProps> = ({ errors, defaultForm, onChange }) => {
  return (
    <Component>
      <Section b={1}>
        <Flexbox className="my-shop-form__twin-wrap">
          <Flexbox.Item width="50%" className="my-shop-form__twin-item">
            <Section l={5} className="my-shop-form__twin-section">
              <Section b={1}>
                {/* 업체명 */}
                <MyShopFormItem label="업체명" required>
                  <Input
                    name="shopName"
                    maxLength={25}
                    placeholder="업체명"
                    errorMessage={errors.shopName}
                    defaultValue={defaultForm.shopName}
                    onChange={e =>
                      onChange({
                        shopName: e.target.value,
                      })
                    }
                  />
                </MyShopFormItem>
              </Section>
              <Section t={1} b={1}>
                {/* 업체주소 */}
                <Address
                  errors={errors}
                  defaultValue={{
                    address: defaultForm.address,
                    addressDetail: defaultForm.addressDetail,
                  }}
                  callback={onChange}
                />
              </Section>
              <Section t={1} b={1}>
                {/* 영업시간 */}
                <TimeSelect
                  defaultValue={defaultForm.operationTimes}
                  onChange={operationTimes => onChange({ operationTimes })}
                />
              </Section>
              <Section t={1} b={1}>
                {/* 업체 소개글 */}
                <Description
                  defaultValue={defaultForm.description}
                  onChange={description => onChange({ description })}
                />
              </Section>
            </Section>
          </Flexbox.Item>
          <Flexbox.Item width="50%" className="my-shop-form__twin-item">
            <Section l={5} className="my-shop-form__twin-section">
              <Section b={1}>
                {/* 업체사진 */}
                <PhotoSelect
                  defaultImages={defaultForm.shopImages}
                  onChange={shopImages => onChange({ shopImages })}
                />
              </Section>
              <Section t={1}>
                {/* 담당자 연락처 */}
                <TelNumber
                  errors={errors}
                  activeFields={defaultForm.activityFields}
                  defaultValue={defaultForm.telNumbers}
                  onChange={telNumbers => onChange({ telNumbers })}
                />
              </Section>
            </Section>
          </Flexbox.Item>
        </Flexbox>
      </Section>
      <Section className="my-shop-form__activity-field" t={1} l={5}>
        <ActivityField activeFields={defaultForm.activityFields} />
      </Section>
    </Component>
  );
};

export default MyShopForm;
