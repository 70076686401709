import * as React from 'react';
import classnames from 'classnames';
import {
  Pagination as RtPagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import StlyedComponent from './Pagination.styles';

interface IProps {
  itemCount: number;
  currentPage: number;
  perPage?: number;
  perBlock?: number;
  onChange: (nextPage: number) => void;
}

const Pagination: React.SFC<IProps> = ({
  itemCount,
  currentPage,
  perPage = 20,
  perBlock = 10,
  onChange,
}) => {
  const endPage = Math.ceil(itemCount / perPage);
  const currentBlock = Math.ceil(currentPage / perBlock);
  const startBlock = Math.max((currentBlock - 1) * perBlock + 1, 1);
  const endBlock = Math.min(currentBlock * perBlock, endPage);
  const pageItems = [];

  if (!itemCount || !currentPage || !perPage || itemCount <= perPage) {
    return null;
  }

  const onChangePage = (nextPage: number) => {
    if (nextPage === currentPage) {
      return;
    }
    onChange(nextPage);
  };

  for (let i = startBlock; i <= endBlock; i += 1) {
    pageItems.push(
      <PaginationItem
        key={i}
        className={classnames('page-number', { active: i === currentPage })}
        active={i === currentPage}
      >
        <PaginationLink onClick={() => onChangePage(i)}>{i}</PaginationLink>
      </PaginationItem>,
    );
  }

  return (
    <StlyedComponent>
      <RtPagination>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink onClick={() => onChangePage(1)}>처음</PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink onClick={() => onChangePage(currentPage - 1)}>
            이전
          </PaginationLink>
        </PaginationItem>
        {pageItems}
        <PaginationItem disabled={currentPage === endPage}>
          <PaginationLink onClick={() => onChangePage(currentPage + 1)}>
            다음
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={currentPage === endPage}>
          <PaginationLink onClick={() => onChangePage(endPage)}>
            끝
          </PaginationLink>
        </PaginationItem>
      </RtPagination>
    </StlyedComponent>
  );
};

export default Pagination;
