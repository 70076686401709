import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LOGIN as LOGIN_PAGE } from 'core/consts/Path';
import splitRoute from './SplitRoute';

const LandingRoutes: React.FC = () => {
  return (
    <Switch>
      {/* 로그인 */}
      <Route
        exact
        path={LOGIN_PAGE}
        component={splitRoute(() => import('pages/login'))}
      />
      {/* 없는 페이지는 로그인으로 redirect */}
      <Redirect to={LOGIN_PAGE} />
    </Switch>
  );
};

export default LandingRoutes;
