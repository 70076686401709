import * as React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { BACKOFFICE as BACKOFFICE_PAGE } from 'core/consts/Path';
import BackofficeRoutes from './BackofficeRoutes';
import LandingRoutes from './LandingRoutes';
import WithAuthRoute from './withAuthRoute';

const { useEffect } = React;

const Routes: React.FC = () => {
  const location = useLocation();

  // location 이동시 스크롤 상단으로
  useEffect(() => {
    if ('scrollTo' in window) {
      window.scrollTo(0, 0);
    }
  }, [location.key]);

  return (
    <Switch>
      {/* 백오피스 페이지 */}
      <WithAuthRoute path={BACKOFFICE_PAGE} component={BackofficeRoutes} />

      {/* 렌더링 페이지 */}
      <Route path="/" component={LandingRoutes} />
    </Switch>
  );
};

export default Routes;
