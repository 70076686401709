import * as React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import toastr from 'toastr';

import useFetch from 'core/hooks/UseFetch';
import { getPossibleInsuranceCompanies } from 'core/api/estimation';

import styled from 'styles/Styled';

import ToggleArrowDown from 'reusable/toggle-arrow-down.svg';

import koStrings from '../../../../meta/ko';

interface FinalizedInsuranceForm {
  insuranceId?: number;
  insuranceRenewalDate?: string;
  price?: number;
  propertyDamageLiability?: number;
  bodilyInjuryCoverageId?: number;
  file?: { name: string };
}

interface IProps {
  insuranceCompanySavedString?: string;
  insuranceCompany: string;
  insuranceCompanyPlaceholder: string;
  formInput: FinalizedInsuranceForm;
  setFormInput: (formInput: {}) => void;
}

const {
  estimationDetailContainer: {
    toastr: {
      getInsuranceCompanies: { fetchFail },
    },
  },
} = koStrings;

const { useState, useEffect, useCallback } = React;

const StyledComponent = styled.div`
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      border-color: #ced4da;
      color: #b5b5b5;
      background-color: white;
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        right: 3%;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(46, 151, 250, 0.25);
      }
      &:active {
        color: #b5b5b5;
        background-color: white;
      }
      &.black-text {
        color: black;
      }
    }
  }
`;

const ChatInsuranceCompanyDropDown: React.FC<IProps> = ({
  insuranceCompanySavedString,
  insuranceCompany,
  insuranceCompanyPlaceholder,
  formInput,
  setFormInput,
}) => {
  const [dropdownClass, updateDropdownClass] = useState('');

  const [insuranceIdStringValue, setInsuranceIdValue] = useState();
  // get list of all possible insurance companies
  const [onGetPossibleInsuranceCompanies] = useFetch(
    getPossibleInsuranceCompanies,
    null,
  );
  const getInsuranceCompanies = useCallback(async () => {
    const processDropdownClick = (e: any) => {
      e.preventDefault();
      const insuranceCompanyNameString = e.target.innerHTML;
      setInsuranceIdValue(insuranceCompanyNameString);
      const insuranceId = e.target.getAttribute('data-index');
      setFormInput({
        ...formInput,
        insuranceId,
        insuranceIdStringValue: insuranceCompanyNameString,
      });
    };
    try {
      const response = await onGetPossibleInsuranceCompanies();
      if (!response.error) {
        const dropdownItemsMarkup = response.result.map(
          (insuranceCompany: any) => {
            return (
              <DropdownItem
                key={insuranceCompany.insuId}
                data-index={insuranceCompany.insuId}
                onClick={processDropdownClick}
              >
                {insuranceCompany.insuName}
              </DropdownItem>
            );
          },
        );
        setDropdownMarkup(dropdownItemsMarkup);
      } else {
        toastr.error(fetchFail);
      }
    } catch {
      toastr.error(fetchFail);
    }
  }, [onGetPossibleInsuranceCompanies, formInput, setFormInput]);

  useEffect(() => {
    getInsuranceCompanies();
  }, [getInsuranceCompanies]);

  const [dropdownMarkup, setDropdownMarkup] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (insuranceCompanySavedString) {
      updateDropdownClass('dropdown-toggle black-text');
    }
  }, [insuranceCompanySavedString]);

  return (
    <StyledComponent>
      <label htmlFor="insuranceCompany">{insuranceCompany}</label>
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className={dropdownClass}>
          {insuranceIdStringValue ||
            insuranceCompanySavedString ||
            insuranceCompanyPlaceholder}
            <img
              className="toggle-icon"
              src={ToggleArrowDown}
              alt="toggle-arrow-down-icon"
            />
        </DropdownToggle>
        <DropdownMenu>{dropdownMarkup}</DropdownMenu>
      </Dropdown>
    </StyledComponent>
  );
};

export default ChatInsuranceCompanyDropDown;
