import * as React from 'react';
import { IContProp } from 'containers/connect/chatting/chattingDetailCont/interface';
import ChattingDetailTemp from 'components/connect/chatting/chattingDetailTemp';
import ChattingDetailHeader from 'components/connect/chatting/chattingDetailHeader';
import ChattinWindow from 'components/connect/chatting/chattinWindow';
import ChattingDetailFooter from 'components/connect/chatting/chattingDetailFooter';
import styled from 'styles/Styled';

const Component = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ChattingDetailPage: React.FC<IContProp> = ({
  hasMore,
  isSending,
  chatting,
  user,
  setWindowEl,
  onSendMessage,
  onSendFiles,
  onAddFile,
  onToggleOriginImage,
}) => {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    onSendFiles(files);
  };

  return (
    <ChattingDetailTemp.ChatWindow>
      <Component onDragOver={handleDragOver} onDrop={handleDrop}>
        <ChattingDetailHeader user={user} />
        <ChattinWindow
          hasMore={hasMore}
          items={chatting}
          setEl={setWindowEl}
          onToggleOriginImage={onToggleOriginImage}
        />
        <ChattingDetailFooter
          isSending={isSending}
          onSendMessage={onSendMessage}
          onSendFiles={onSendFiles}
          onAddFile={onAddFile}
        />
      </Component>
    </ChattingDetailTemp.ChatWindow>
  );
};

export default ChattingDetailPage;
