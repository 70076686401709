import io from 'socket.io-client';

export default (
  connectShopId: string,
  authUserKey: string,
  callback: Function,
) => {
  const socket = io(`${process.env.REACT_APP_CHAT_HOST}/shop`, {
    transports: ['websocket'],
  });

  socket.on('error', (err: any) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`error: ${err}`);
    }
  });

  socket.on('connect', () => {
    socket.emit('joinRoom', connectShopId, authUserKey);
  });
  socket.on('notify', (type: string, message: string, data: string) => {
    callback({
      type,
      message,
      data,
    });
  });
};
