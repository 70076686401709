export const BACKOFFICE = '/backoffice';
export const LOGIN = '/login';

/**
 * 활동 분야 추가
 */
export const ACTIVITY_FIELD = `${BACKOFFICE}/activity-field`;

/**
 * 내 정보
 */
export const MY_PAGE = `${BACKOFFICE}/my-page`;

/**
 * 공지사항
 */
export const NOTICE = `${BACKOFFICE}/notice`;
export const NOTICE_DETAIL = `${NOTICE}/detail`;

/**
 * 알림
 */
export const NOTIFICATION = `${BACKOFFICE}/notification`;

/**
 * 후기
 */
export const REVIEW = `${BACKOFFICE}/review`;

/**
 * 커넥트
 */
const CONNECT = `${BACKOFFICE}/connect`;

// 커넥트 - 견적
export const CONNECT_ESTIMATION = `${CONNECT}/estimation`;

// 커넥트 - 채팅
export const CONNECT_CHATTING = `${CONNECT}/chatting`;

// 백오피스 메인 화면
export const BACKOFFICE_HOME = CONNECT_ESTIMATION;

export default null;
