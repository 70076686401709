import { cardocServiceKeys } from 'core/data/cardocService';

export const parseShopData = (shop: any, connectShop: any) => {
  return {
    // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
    // loginId: shop.loginId,
    shopName: connectShop?.name,
    address: connectShop?.addressDoro || '',
    addressDetail: connectShop?.addressDetail || '',
    description: connectShop?.desc,
    shopImages: connectShop?.image?.slice(0, 20) || [], // 대표 사진은 20개 까지만 노출
    operationTimes: {
      weekday: {
        startTime: connectShop?.weekdayStartTime,
        endTime: connectShop?.weekdayEndTime,
      },
      saturday: {
        startTime: connectShop?.saturdayStartTime,
        endTime: connectShop?.saturdayEndTime,
        isHoliday: connectShop?.isSaturdayOff === 'Y',
      },
      sunday: {
        startTime: connectShop?.sundayStartTime,
        endTime: connectShop?.sundayEndTime,
        isHoliday: connectShop?.isSundayOff === 'Y',
      },
      holiday: {
        startTime: connectShop?.holidayStartTime,
        endTime: connectShop?.holidayEndTime,
        isHoliday: connectShop?.isHolidayOff === 'Y',
      },
    },
    telNumbers: {
      repair: ['01011112222', '01022223322'],
      techshop: ['01033334422', '01055550522'],
      wash: ['01011110002'],
    },
    activityFields: {
      ...cardocServiceKeys.reduce((obj: any, key) => {
        obj[key] = connectShop[key] || null;
        return obj;
      }, {}),
    },
    vendorName: connectShop?.vendor?.name,
    corpNumber: connectShop?.vendor?.corpNumber,
    corpOwnerName: connectShop?.vendor?.corpOwnerName,
    corpAddress: connectShop?.vendor?.corpAddress,
    businessCondition: connectShop?.vendor?.businessCondition,
    businessCategory: connectShop?.vendor?.businessCategory,
    bankAccount: connectShop?.vendor?.bankAccount,
    bankAccountHolder: connectShop?.vendor?.bankAccountHolder,
    bankName: connectShop?.vendor?.bankName,
    email: connectShop?.vendor?.email,
    issueMethod: connectShop?.vendor?.issueMethod,
    businessCertImgUrl: connectShop?.vendor?.businessCertImgUrl,
    bankBookImgUrl: connectShop?.vendor?.bankBookImgUrl,
    isCorpChecked: connectShop?.vendor?.isCorpChecked === '1',
    isBankChecked: connectShop?.vendor?.isBankChecked === '1',
  };
};
