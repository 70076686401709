import * as React from 'react';
import { Button } from 'reactstrap';

import PageTemp from 'components/template/pageTemp';
import BoardListTemp from 'components/template/boardListTemp';
import Empty from 'elements/helper/empty';
import Loading from 'elements/helper/loading';
import Pagination from 'components/base/pagination';
import NotificationItem from 'components/notification/notificationItem';
import { IContProps } from 'containers/notification/notificationCont/interface';

const NotificationListPage: React.FC<IContProps> = ({
  pending,
  items,
  itemCount,
  page,
  perPage,
  onRead,
  onReadAll,
  onChangePage,
}) => {
  return (
    <PageTemp
      size={6}
      header={
        <>
          <PageTemp.Title>알림</PageTemp.Title>
          <PageTemp.SubTitle>
            견적, 예약 등 카닥 서비스에 대한 알림입니다.
          </PageTemp.SubTitle>
        </>
      }
    >
      {pending && (
        <Loading style={{ minHeight: '30rem' }}>
          알림 목록을 불러오고 있습니다.
        </Loading>
      )}
      <div className="mb-2 text-right">
        <Button color="primary" onClick={onReadAll}>
          전체 읽음
        </Button>
      </div>
      <BoardListTemp
        pagination={
          <Pagination
            itemCount={itemCount}
            currentPage={page}
            perPage={perPage}
            onChange={onChangePage}
          />
        }
      >
        {!pending && items.length === 0 ? (
          <Empty>알림 목록이 없습니다.</Empty>
        ) : (
          <div>
            {items.map(item => (
              <NotificationItem
                key={item.notiid}
                notiId={item.notiid}
                isReaded={item.readyn === 'Y'}
                message={item.notimsg.msg}
                createdAt={item.regdttm}
                onClick={() => onRead(item)}
              />
            ))}
          </div>
        )}
      </BoardListTemp>
    </PageTemp>
  );
};

export default NotificationListPage;
