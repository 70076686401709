import * as React from 'react';
import classnames from 'classnames';
import styled from 'styles/Styled';

interface IProps {
  className?: string;
  color: string;
}

const StlyedComponent = styled.label`
  margin: 0;
  padding: 0.35rem 0.85rem;
  border-radius: ${props => props.theme.sizes.borderRadius};
  color: white;
  font-size: 11px;
`;

const ServiceLabel: React.FC<IProps> = ({ className, color, children }) => (
  <StlyedComponent
    className={classnames(
      className,
      `bg-${color}`,
      color === 'warning' && 'text-dark',
    )}
  >
    {children}
  </StlyedComponent>
);

export default ServiceLabel;
