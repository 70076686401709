import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { getNotificationCount } from 'core/api/Notification';
import { useAppContext } from 'core/context/appContext';
import * as PATH from 'core/consts/Path';
import chatSocket from 'core/function/chatSocket';
import {
  askNotificationPermission,
  notificationEvent,
} from 'core/function/webNotification';

import Layout from 'containers/base/layoutContainer';
import WithPermissionRoute from './withPermissionRoute';

/**
 * FIXME
 *
 * root page를 container로 사용하는 패턴으로 변경 했음.
 * 기존 root page들은 일단 두고, 나중에 변경할 수 있도록 FIXME를 기록해 둔다.
 */
import ActivityField from 'components/activityField';
import NotificationCont from 'containers/notification/notificationCont';
import ReviewCont from 'containers/review/reviewCont';
import EstimationCont from 'containers/connect/estimation/estimationCont';
import ChattingCont from 'containers/connect/chatting/chattingCont';
import MyPageCont from 'containers/myPage/myPageCont';

const { useEffect, useCallback } = React;

const BackofficeRoutes: React.FC = () => {
  const history = useHistory();
  const { state: ctxState, setState: setCtxState } = useAppContext();

  // 새로운 알림 도착 노티 이벤트
  const addNotificationEvent = useCallback(() => {
    chatSocket(
      ctxState.connectShop.connectShopId,
      // FIXME: authuserkey를 어찌해야 할지 의논해봐야 함
      'authuserkey',
      ({ type, message, data }: any) =>
        notificationEvent({
          title: '새로운 알림이 도착했습니다.',
          body: message,
          onClick: () => {
            if (type === 'sendbird_chat') {
              // 샌드버드 채팅 도착 알림
              history.push(`${PATH.CONNECT_CHATTING}/${data}`);
            } else if (type === 'connect_allocation') {
              // 커넥트 견적 요청 수신
              history.push(`${PATH.CONNECT_ESTIMATION}/${data}`);
            }
          },
        }),
    );
  }, [history, ctxState]);

  // 카운트 계산
  const getCount = useCallback(async () => {
    const notiCount: { [key: string]: number } = {};

    // 안 읽은 알림 카운트
    const notiCountResponse = await getNotificationCount('unread');
    notiCount['notification'] = notiCountResponse?.data?.count || 0;

    return notiCount;
  }, []);

  // 백오피스 마운트
  useEffect(() => {
    // 알림 권한 요청
    askNotificationPermission();

    // 알림 노티 리스너 등록
    addNotificationEvent();

    (async () => {
      // 카운트 계산
      const notiCount = await getCount();

      // 컨텍스트 저장
      const nextCtxState = {
        ...ctxState,
        notiCount,
      };

      setCtxState(nextCtxState);

      // 샌드버드 안 읽은 채팅 카운트
      if (ctxState.sendbirdConnection) {
        ctxState.sendbirdConnection.getTotalUnreadMessageCount(
          (count: number, error: any) => {
            if (error || !count) return;

            setCtxState({
              ...nextCtxState,
              notiCount: {
                ...nextCtxState.notiCount,
                'connect-chatting': count,
              },
            });
          },
        );
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Switch>
        {/* 활동분야 추가 */}
        <Route path={PATH.ACTIVITY_FIELD} component={ActivityField} />

        {/* 마이페이지 */}
        <Route path={`${PATH.MY_PAGE}/:type`} component={MyPageCont} />

        {/* 알림 */}
        <Route
          path={`${PATH.NOTIFICATION}/:page?`}
          component={NotificationCont}
        />

        {/* 후기 */}
        <Route path={PATH.REVIEW} component={ReviewCont} />

        {/* 커넥트 - 견적서 */}
        <WithPermissionRoute
          path={`${PATH.CONNECT_ESTIMATION}/:id?`}
          component={EstimationCont}
        />

        {/* 커넥트 - 채팅 */}
        <WithPermissionRoute
          path={`${PATH.CONNECT_CHATTING}/:channelUrl?`}
          component={ChattingCont}
        />

        {/* 없는 페이지는 백오피스 홈으로 redirect */}
        <Redirect to={PATH.BACKOFFICE_HOME} />
      </Switch>
    </Layout>
  );
};

export default BackofficeRoutes;
