import * as React from 'react';
import styled, { css } from 'styles/Styled';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import Input from 'elements/form/input';
import Icon from 'elements/helper/icon';
import Postcode from 'elements/helper/postcode';

interface Iform {
  address: string;
  addressDetail: string;
}

interface IProps {
  errors: { [key: string]: string };
  defaultValue: Iform;
  callback: (nextValues: Iform) => void;
}

const { useState, useCallback } = React;

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .search-button {
      width: 3rem;
      height: 100%;
      display: flex;
      justify-content:center;
      align-items:center;
      transition: color 500ms ease;

      &:hover {
        color: ${colors.primary};
        cursor: pointer;
      }
    `}
`;

const Address: React.FC<IProps> = ({ errors, defaultValue, callback }) => {
  const [state, setState] = useState({
    form: defaultValue,
    isShowPostcode: false,
  });

  const handleChange = useCallback(
    e => {
      const nextFormState = {
        ...state.form,
        [e.target.name]: e.target.value,
      };
      setState({
        isShowPostcode: false,
        form: nextFormState,
      });
      callback(nextFormState);
    },
    [state.form, callback],
  );

  const handleTogglePostcode = useCallback(
    isShowPostcode => {
      setState({
        ...state,
        isShowPostcode,
      });
    },
    [state],
  );

  const handleSelectPostcode = useCallback(
    data => {
      setTimeout(() => {
        handleChange({
          target: {
            name: 'address',
            value: data.address,
          },
        });
      });
    },
    [handleChange],
  );

  return (
    <>
      <Postcode
        isShow={state.isShowPostcode}
        onSelect={handleSelectPostcode}
        onClose={() => handleTogglePostcode(false)}
      />
      <MyShopFormItem label="업체주소" required>
        <StyledComponent>
          <Input
            name="address"
            surffix={
              <span
                className="search-button"
                onClick={() => handleTogglePostcode(true)}
              >
                <Icon iconName="fa fa-search" />
              </span>
            }
            disabled
            placeholder="업체주소"
            value={state.form.address}
            errorMessage={errors.address}
          />
          <Input
            name="addressDetail"
            className="mt-3"
            placeholder="업체 상세주소"
            errorMessage={errors.addressDetail}
            value={state.form.addressDetail}
            onChange={handleChange}
          />
        </StyledComponent>
      </MyShopFormItem>
    </>
  );
};

export default Address;
