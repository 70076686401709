import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

/**
 * 견적 발송 영역
 */
const StyledRequest = styled.div`
  width: 40%;
  max-width: 550px;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;

  h1 {
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 800;
  }

  ${media.mobile`
    width: 100%;
    padding: 0;
  `}
`;
const Request: React.FC = ({ children }) => (
  <StyledRequest>
    <h1>견적 메시지 보내기</h1>
    {children}
  </StyledRequest>
);

/**
 * 받은 견적 요청 영역
 */
const StyledRecieve = styled.div`
  ${({ theme: { colors } }) => css`
    flex: 1;
    height: 100%;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid ${colors.border};

    ${media.mobile`
      width: 100%;
      padding: 0;
      border-right: none;
    `}
  `}
`;
const Receive: React.FC = ({ children }) => (
  <StyledRecieve>{children}</StyledRecieve>
);

/**
 * 상세 영역
 */
const StyledDetail = styled.div`
  flex: 1;
  height: 100%;
  min-height: 300px;
  display: flex;
  position: relative;

  ${media.mobile`
    flex-direction: column;
  `}
`;
const Detail: React.FC = ({ children }) => (
  <StyledDetail>{children}</StyledDetail>
);

/**
 * 리스트 영역
 */
const StyledList = styled.div`
  ${({ theme: { colors } }) => css`
    width: 30%;
    max-width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-right: 1px solid ${colors.border};

    .estimation-list {
      &__wrap {
        flex: 1;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &__items {
          flex: 1;
          overflow: hidden;

          &-box {
            height: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
          }
        }
      }
    }

    ${media.mobile`
      width: 100%;
      border-right: none;
    `}
  `}
`;

const Items: React.FC = ({ children }) => (
  <div className="estimation-list__wrap__items">
    <div className="estimation-list__wrap__items-box">{children}</div>
  </div>
);

const List: React.FC & {
  Items: typeof Items;
} = ({ children }) => (
  <StyledList>
    <div className="estimation-list__wrap">{children}</div>
  </StyledList>
);

List.Items = Items;

/**
 * 견적서 템플릿 타입
 */
type EstimationType = {
  Request: typeof Request;
  Receive: typeof Receive;
  Detail: typeof Detail;
  List: typeof List;
};

/**
 * 견적서 템플릿
 */
const StyledComponent = styled.div`
  ${({ theme: { sizes } }) => css`
    height: calc(100vh - ${sizes.headerHeight} - ${sizes.breadcrumnbHeight});
    margin: -${sizes.mainPadding};
    display: flex;
    position: relative;

    ${media.mobile`
      min-width:0;
      height: auto;
      margin: 0;
      display: block;
    `}
  `}
`;
const EstimationTemp: React.FC & EstimationType = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

EstimationTemp.Request = Request;
EstimationTemp.Receive = Receive;
EstimationTemp.Detail = Detail;
EstimationTemp.List = List;
export default EstimationTemp;
