import * as React from 'react';
import { darken } from 'polished';
import styled, { css } from 'styles/Styled';

interface IProps {
  notiId: number;
  isReaded: boolean;
  message: string;
  createdAt: string;
  onClick: () => void;
}

const Component = styled.figure<{ isReaded: boolean }>`
  ${({ isReaded, theme: { sizes, colors } }) => css`
    padding: 2rem 2.25rem;
    position: relative;
    border: 1px solid ${colors.border};
    border-bottom-width: 0;
    transition: background-color 500ms ease;

    &:before {
      content: '';
      width: 2px;
      height: calc(100% + 2px);
      display: none;
      position: absolute;
      z-index: 1;
      top: -1px;
      left: -1px;
      background-color: ${colors.primary};
    }

    &:nth-child(odd) {
      background-color: ${colors.gray9};
    }

    &:first-child {
      border-top-left-radius: ${sizes.borderRadius};
      border-top-right-radius: ${sizes.borderRadius};
    }

    &:last-child {
      border-bottom: 1px solid ${colors.border};
      border-bottom-left-radius: ${sizes.borderRadius};
      border-bottom-right-radius: ${sizes.borderRadius};
    }

    &:hover {
      background-color: ${colors.gray6};
      cursor: pointer;
    }

    p {
      margin: 0;
      white-space: pre-wrap;
      font-weight: 500;
      color: ${colors.gray2};
    }

    time {
      margin-top: 0.5rem;
      display: block;
      font-size: 0.925rem;
      color: ${colors.gray3};
    }

    ${!isReaded &&
      css`
        background-color: ${colors.blueLightGray} !important;

        &:before {
          display: block;
        }

        &:hover {
          background-color: ${darken(0.05, colors.blueLightGray)} !important;
        }

        p {
          font-weight: 400;
          color: ${colors.primary};
        }
      `}
  `}
`;

const NotificationListItem: React.FC<IProps> = ({
  notiId,
  isReaded,
  message,
  createdAt,
  onClick,
}) => (
  <Component isReaded={isReaded} onClick={onClick}>
    <p>{message}</p>
    <time>{createdAt}</time>
  </Component>
);

export default NotificationListItem;
