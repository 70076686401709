import * as React from 'react';

import UserProfile from 'elements/helper/userProfile';
import StyledComponent from './ChattingDetailItem.styles';

interface IProps {
  type: 'user' | 'file';
  myMessage: boolean;
  message: string;
  fileUrl?: string;
  fileName?: string;
  createdAt: string;
  onShowImage: (imageSrc: string) => void;
}

const ChattingDetailItem: React.FC<IProps> = ({
  type,
  myMessage,
  message,
  fileUrl,
  fileName,
  createdAt,
  onShowImage,
}) => (
  <StyledComponent myMessage={myMessage}>
    {!myMessage && <UserProfile />}
    <div className="content">
      <div className="wrap">
        {type === 'user' && <div className="box">{message}</div>}
        {type === 'file' && fileUrl && (
          <img
            src={fileUrl}
            alt={fileName || ''}
            title="원본 이미지 보기"
            onClick={() => onShowImage(fileUrl)}
          />
        )}
        <time>{createdAt}</time>
      </div>
    </div>
  </StyledComponent>
);

export default ChattingDetailItem;
