const breakPoint = {
  mobile: 768,
};

const colors = {
  primary: '#2e96f9',
  black: '#222',
  red: '#dc3545',
  subGray: '#333340',
  blueLightGray: '#f6fafd',
  subBlue: '#00c6ff',
  border: '#ddd',
  gray1: '#666',
  gray2: '#888',
  gray3: '#aaa',
  gray4: '#ccc',
  gray5: '#ddd',
  gray6: '#eee',
  gray7: '#f2f2f2',
  gray8: '#f9f9f9',
  gray9: '#fbfbfb',
  // Named colors
  'linkWater' : '#ced3d6'
};

const sizes = {
  headerHeight: '3.85rem',
  asideWidth: '180px',
  containerWidth: '1250px',
  mainPadding: '2.5rem',
  breadcrumnbHeight: '3rem',
  borderRadius: '2px',
  radius: '4px',
};

export default { breakPoint, colors, sizes };
