import * as React from 'react';
import styled, { css } from 'styles/Styled';

interface IProps {
  label: string;
  required?: boolean;
  labelSurffix?: JSX.Element;
}

const StyledComponent = styled.section<{ required: boolean }>`
  ${({ required, theme: { colors } }) => css`
    position: relative;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-size: 1.15rem;
      }

      small {
        font-size: 0.85rem;
        font-weight: 300;
        color: ${colors.gray3};
      }
    }

    .item-content {
      margin-top: 0.75rem;
    }

    ${required &&
      css`
        .item-header .label {
          color: ${colors.primary};
        }
      `}
  `}
`;

const MyShopFormItem: React.FC<IProps> = ({
  label,
  required = false,
  labelSurffix = null,
  children,
}) => (
  <StyledComponent required={required}>
    <div className="item-header">
      <label className="label">{label}</label>
      {required && <small>*필수입력</small>}
      {labelSurffix}
    </div>
    <div className="item-content">{children}</div>
  </StyledComponent>
);

export default MyShopFormItem;
