import * as React from 'react';
import { numberFormat } from 'core/function/Formatter';
import Section from 'elements/ui/section';
import ReviewLabel from 'elements/helper/reviewLabel';
import styled, { css } from 'styles/Styled';

interface IProps {
  isSelectedScore: boolean;
  total: number;
  good: number;
  bad: number;
  avg: number;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    strong {
      font-weight: 600;
    }

    label {
      transform: scale(0.85);
    }

    .review-result {
      &__number {
        font-size: 0.875rem;
      }

      &__avg {
        position: relative;
        top: -1px;

        span {
          color: ${colors.primary};
        }
      }
    }
  `}
`;

const ReviewResult: React.FC<IProps> = ({
  isSelectedScore,
  total,
  good,
  bad,
  avg,
}) => (
  <StyledComponent>
    <Section>
      <strong>{numberFormat(total)}</strong>개의 후기
    </Section>
    {!isSelectedScore && (
      <>
        <Section l={0.5} r={0.25}>
          <ReviewLabel isGood />
          <span className="review-result__number">{numberFormat(good)}개</span>
        </Section>
        <Section l={0.25}>
          <ReviewLabel isGood={false} />
          <span className="review-result__number">{numberFormat(bad)}개</span>
        </Section>
        <Section className="review-result__avg" l={0.75}>
          <strong>
            만족도 <span>{Number(avg).toFixed(1)}</span>%
          </strong>
        </Section>
      </>
    )}
  </StyledComponent>
);

export default ReviewResult;
