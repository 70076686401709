import { createGlobalStyle } from 'styled-components';
import ResetCSS from './Reset';
import media from './Media';

export default createGlobalStyle`
  ${ResetCSS}

  html {
    font-size: 13px;
  }

  body {
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1.35;
  }

  ${media.mobile`
    html {
      font-size: 12px;
    }
  `}
`;
