import * as React from 'react';
import Sendbird from 'sendbird';
import styled, { css } from 'styles/Styled';
import UserProfile from 'elements/helper/userProfile';

interface IProps {
  user: Sendbird.Member | null;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    margin-bottom: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.border};

    .user-profile {
      &__user-name {
        margin-left: 0.75rem;
        font-size: 1.025rem;
        font-weight: 500;
      }
    }
  `}
`;

const ChattingDetailHeader: React.FC<IProps> = ({ user }) => {
  return (
    <StyledComponent>
      <UserProfile size="2.25rem" />
      {user && (
        <span className="user-profile__user-name">{user?.nickname}</span>
      )}
    </StyledComponent>
  );
};

export default ChattingDetailHeader;
