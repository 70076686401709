import { cardocApiCreator } from 'core/api/ApiCreator';

/**
 * 인증 정보 조회
 */
export const account = () => cardocApiCreator(`/v2/accountConnectShop`);

/**
 * 로그인
 */
export const login = (params: { loginid: string; password: string }) =>
  cardocApiCreator.post(`/v2/dtsession`, params);

/**
 * 로그아웃
 */
export const logout = () => cardocApiCreator.delete('/v2/dtsession');

/**
 * 비밀번호 변경
 */
export const updatePassword = (params: {
  loginId: string;
  password: string;
  oldPassword: string;
}) => {
  /**
   * TODO
   *
   * 새로운 비밀번호 변경 API를 연동해야 함
   */
};
