import * as React from 'react';
import styled from 'styles/Styled';

interface ItemProps {
  className?: string;
  flex?: number;
  width?: string;
}
const StyledItem = styled.div``;
const Item: React.FC<ItemProps> = ({ className, flex, width, children }) => (
  <StyledItem
    className={className}
    style={{
      flex,
      width,
    }}
  >
    {children}
  </StyledItem>
);

type FlexBoxType = {
  Item: typeof Item;
};

interface IProps {
  className?: string;
  justifyContent?: string;
  alignItems?: string;
}
const StyledComponent = styled.div`
  display: flex;
`;
const FlexBox: React.FC<IProps> & FlexBoxType = ({
  className,
  justifyContent,
  alignItems,
  children,
}) => (
  <StyledComponent style={{ justifyContent, alignItems }} className={className}>
    {children}
  </StyledComponent>
);

FlexBox.Item = Item;
export default FlexBox;
