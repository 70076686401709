import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import Modal from 'elements/helper/modal';
import Button from 'elements/form/button';
import iconImage from './icon.svg';

interface IProps {
  show: boolean;
  onClose: () => void;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${iconImage}) no-repeat top;

    .tel-number {
      border-bottom: 3px solid ${colors.primary};
      font-size: 3.25rem;
      font-weight: 700;
      color: ${colors.primary};

      span {
        margin-bottom: -0.5rem;
        display: block;
        font-size: 1.05rem;
        font-weight: 500;
      }
    }

    .description-wrap {
      margin-top: 1.75rem;
      font-size: 1.05rem;
      text-align: center;
      color: ${colors.gray2};
    }

    .tel-button-wrap {
      margin-top: 2rem;
      display: none;
    }

    ${media.mobile`
      .tel-button-wrap {
        display: block
      }
    `}
  `}
`;

const CsCenterModal: React.FC<IProps> = ({ children, ...props }) => (
  <Modal width="300px" {...props}>
    <StyledComponent>
      <div className="tel-number">
        <span>카닥 고객센터</span>1599-4572
      </div>
      <p className="description-wrap">{children}</p>
      <div className="tel-button-wrap">
        <Button color="primary">
          <a href="tel:15994572">전화하기</a>
        </Button>
      </div>
    </StyledComponent>
  </Modal>
);

export default CsCenterModal;
