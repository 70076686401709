import * as React from 'react';
import Sendbird from 'sendbird';
import classnames from 'classnames';
import moment from 'moment';

import { useAppContext } from 'core/context/appContext';
import UserProfile from 'elements/helper/userProfile';
import Loading from 'elements/helper/loading';
import Empty from 'elements/helper/empty';
import styled, { css } from 'styles/Styled';

interface IProps {
  pending: boolean;
  items: Sendbird.GroupChannel[];
  selectedChannelUrl: string;
  onLinkToDetail: (channelUrl: string) => void;
}

const StyledComponent = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    .loading-wrap {
      height: 200px;
      position: relative;
      border: 1px solid ${colors.gray7};
    }

    .empty-wrap {
    }

    ul {
      border: 1px solid ${colors.border};
      border-radius: ${sizes.borderRadius};

      li {
        padding: 1.75rem;
        display: flex;
        transition: background-color 500ms ease;

        &.active {
          background-color: ${colors.gray6} !important;
        }

        &:not(:last-child) {
          border-bottom: 1px solid ${colors.border};
        }

        &:nth-child(odd) {
          background-color: ${colors.gray9};
        }

        &:hover {
          background-color: ${colors.gray7};
          cursor: pointer;
        }

        .chatting-list-item {
          &__content {
            flex: 1;
            padding-left: 1.5rem;
            overflow: hidden;

            time {
              display: block;
              margin-top: 0.75rem;
              font-size: 0.85rem;
              color: ${colors.gray3};
            }
          }

          &__user-name {
            font-weight: 600;
          }

          &__message {
            height: 18px;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            overflow: hidden;

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            span {
              flex: none;
              width: 18px;
              height: 18px;
              margin-left: 0.5rem;
              padding-bottom: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background-color: ${colors.red};
              font-size: 10px;
              color: white;
            }
          }
        }
      }
    }
  `}
`;

const getUserName = (sendbirdId: string, members: any) => {
  return members
    .filter(({ userId }: any) => userId !== sendbirdId)
    .map(({ nickname }: any) => nickname)
    .join(', ');
};

const getMessage = (message: any) => {
  if (message?.type) {
    if (message.type.indexOf('image') === 0) {
      return '사진';
    }
  }
  return message.message;
};

const ChattingList: React.FC<IProps> = ({
  pending,
  items,
  selectedChannelUrl,
  onLinkToDetail,
}) => {
  const {
    state: { sendbirdId },
  } = useAppContext();

  return (
    <StyledComponent>
      {pending ? (
        <div className="loading-wrap">
          <Loading>채팅 목록을 불러오고 있습니다.</Loading>
        </div>
      ) : items.length !== 0 ? (
        <ul>
          {items.map(({ url, members, lastMessage, unreadMessageCount }) => (
            <li
              key={url}
              className={classnames({ active: selectedChannelUrl === url })}
              onClick={() => onLinkToDetail(url)}
            >
              <UserProfile />
              <div className="chatting-list-item__content">
                <span className="chatting-list-item__user-name">
                  {getUserName(sendbirdId, members)}
                </span>
                <div className="chatting-list-item__message">
                  <p>{getMessage(lastMessage)}</p>
                  {unreadMessageCount !== 0 && (
                    <span>
                      {unreadMessageCount > 99
                        ? `${unreadMessageCount}+`
                        : unreadMessageCount}
                    </span>
                  )}
                </div>
                <time>
                  {moment(lastMessage.createdAt).format('YYYY.MM.DD HH:mm')}
                </time>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <Empty>진행중인 채팅이 없습니다.</Empty>
      )}
    </StyledComponent>
  );
};

export default ChattingList;
