import * as React from 'react';
import classnames from 'classnames';
import tostr from 'toastr';

import Icon from 'elements/helper/icon';
import OriginImage from 'elements/helper/originImage';
import useFileUpload from 'core/hooks/form/UseFileUpload';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import SmallDescription from 'components/myPage/smallDescription';
import StyledComponent from './photoSelect.styles';

interface IPhoto {
  imgURL: string;
  thumb_w222: string;
}

interface IProps {
  defaultImages: IPhoto[];
  onChange: (nextState: IPhoto[]) => void;
}

const { useState, useEffect, useCallback } = React;
const MAX_COUNT = 20;

const PhotoSelect: React.FC<IProps> = ({ defaultImages, onChange }) => {
  const [originImageUrl, setOriginImageUrl] = useState<string>('');
  const [state, setState] = useState<{
    photos: IPhoto[];
  }>({
    photos: defaultImages,
  });

  // 업체 사진 업로드 callback
  const uploadImages = useCallback(
    files => {
      if (state.photos.length + files.length > MAX_COUNT) {
        tostr.error(`최대 ${MAX_COUNT}장 까지만 등록할 수 있습니다.`);
        return;
      }

      setState({
        ...state,
        photos: [
          ...state.photos,
          ...files.map(({ url, thumb_w222 }: any) => ({
            imgURL: url,
            thumb_w222,
          })),
        ],
      });
    },
    [state],
  );

  // 업체 사진 업로드 hooks
  const [pending, onSelect] = useFileUpload(uploadImages, {
    multiple: true,
    accept: 'image/*',
    maxLength: MAX_COUNT,
  });

  // 사진이 업데이트 될때마다 callback
  useEffect(() => {
    onChange(state.photos);
    // eslint-disable-next-line
  }, [state.photos]);

  // 이미지 삭제
  const handleRemove = useCallback(
    targetIndex => {
      setState({
        ...state,
        photos: state.photos.filter((_, index) => index !== targetIndex),
      });
    },
    [state],
  );

  // 원본 이미지 토글
  const handleToggleImage = useCallback(src => {
    setOriginImageUrl(src);
  }, []);

  const handleDragStart = useCallback(e => {
    e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index);
  }, []);

  const handleDragOver = useCallback(e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    if (!e.currentTarget.classList.contains('hover')) {
      e.currentTarget.classList.add('hover');
    }
  }, []);

  const handleDragLeave = useCallback(e => {
    if (e.currentTarget.classList.contains('hover')) {
      e.currentTarget.classList.remove('hover');
    }
  }, []);

  const handleDrop = useCallback(
    e => {
      e.currentTarget.classList.remove('hover');
      const toIndex = Number(e.currentTarget.dataset.index);
      const fromIndex = Number(e.dataTransfer.getData('text'));
      const targetPhotos = [...state.photos];
      targetPhotos.splice(fromIndex, 1);
      targetPhotos.splice(toIndex, 0, state.photos[fromIndex]);
      setState({
        ...state,
        photos: targetPhotos,
      });
    },
    [state],
  );

  return (
    <StyledComponent>
      {/* 원본 이미지 */}
      <OriginImage
        imageSrc={originImageUrl}
        onClose={() => handleToggleImage('')}
      />

      <MyShopFormItem
        label="업체사진"
        labelSurffix={
          <div className="buttons">
            <button disabled={pending} onClick={onSelect}>
              {pending ? '업로드중...' : '파일첨부'}
            </button>
          </div>
        }
      >
        <div className="photo-items">
          <ul>
            {state.photos.map(({ thumb_w222, imgURL }, index) => (
              <li
                key={index}
                className={classnames({ represent: index === 0 })}
                data-index={index}
                draggable
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="image">
                  <img
                    src={thumb_w222}
                    title="크게보기"
                    alt=""
                    onClick={() => handleToggleImage(imgURL)}
                  />
                  <span
                    className="remove-button"
                    onClick={() => handleRemove(index)}
                  >
                    <Icon iconName="fa fa-times" />
                  </span>
                </div>
              </li>
            ))}
          </ul>
          {state.photos.length === 0 && (
            <div className="no-image">
              등록된 업체사진이 없습니다.
              <br />
              사진을 등록해주세요.
            </div>
          )}
        </div>
        <SmallDescription>
          <p>사진은 최대 20장까지 등록이 가능합니다.</p>
          <p>
            사진을 드래그해서 순서 변경이 가능하며, 첫번째 사진이 업체
            대표사진으로 등록됩니다.
          </p>
        </SmallDescription>
      </MyShopFormItem>
    </StyledComponent>
  );
};

export default PhotoSelect;
