import * as React from 'react';
import toastr from 'toastr';
import moment from 'moment';
import useFile from './UseFile';
import { uploadFiles } from 'core/api/file';

type TReturn = [any, () => void];
const { useState, useEffect, useCallback } = React;

export default (
  callback: Function,
  attrs?: {
    multiple?: boolean;
    accept: string;
    maxLength?: number;
  },
): TReturn => {
  const [state, setState] = useState({
    pending: false,
  });

  const [files, onSelect] = useFile(attrs);

  const handleUploadFile = useCallback(async targetFiles => {
    const promises = [].map.call(targetFiles, file => {
      return new Promise((resolve, reject) => {
        uploadFiles([file], 'shopjoin', moment().format('YYMMDD'))
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((e: any) => {
            reject(e);
          });
      });
    });

    return Promise.all(promises);
  }, []);

  useEffect(() => {
    if (!files || files.length === 0) return;

    (async () => {
      try {
        setState({ ...state, pending: true });
        const response = await handleUploadFile(files);
        callback(response);
      } catch (e) {
        toastr.error('이미지 업로드에 실패했습니다.');
      } finally {
        setState({
          ...state,
          pending: false,
        });
      }
    })();

    // eslint-disable-next-line
  }, [files]);

  return [state.pending, onSelect];
};
