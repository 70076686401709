import * as React from 'react';
import { Input } from 'reactstrap';
import { cardocServices, cardocServiceKeys } from 'core/data/cardocService';

const { useMemo } = React;

interface IProps {
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent) => void;
}

const ServiceSelect: React.FC<IProps> = ({ ...attrs }) => {
  const serviceOptions = useMemo(
    () =>
      cardocServiceKeys.map(key => (
        <option key={key} value={key}>
          {cardocServices[key]}
        </option>
      )),
    [],
  );

  return (
    <Input type="select" {...attrs}>
      <option value="">전체</option>
      {serviceOptions}
    </Input>
  );
};

export default ServiceSelect;
