import * as React from 'react';
import styled from 'styles/Styled';
import * as mixins from 'styles/Mixin';
import Icon from 'elements/helper/icon';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const StyledComponent = styled.label`
  ${mixins.checkboxRadio}

  .box {
    border-radius: 3px;
  }
`;

const Checkbox: React.FC<IProps> = ({ children, ...props }) => (
  <StyledComponent>
    <input {...props} type="checkbox" />
    <div className="box">
      <i>
        <Icon iconName="fa fa-check" />
      </i>
    </div>
    <div className="box-label">{children}</div>
  </StyledComponent>
);

export default Checkbox;
