import * as React from 'react';
import styled, { css } from 'styles/Styled';

interface IProps {
  className?: string;
  t?: number;
  b?: number;
  l?: number;
  r?: number;
}

const StyledComponent = styled.div<{
  t?: number;
  b?: number;
  l?: number;
  r?: number;
}>`
  ${({ t, b, l, r }) => css`
    ${t &&
      css`
        padding-top: ${t}rem;
      `}

    ${b &&
      css`
        padding-bottom: ${b}rem;
      `}

    ${l &&
      css`
        padding-left: ${l}rem;
      `}

    ${r &&
      css`
        padding-right: ${r}rem;
      `}
  `}
`;

const section: React.FC<IProps> = ({ className, t, b, l, r, children }) => (
  <StyledComponent className={className} t={t} b={b} l={l} r={r}>
    {children}
  </StyledComponent>
);

export default section;
