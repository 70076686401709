import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import Icon from 'elements/helper/icon';

const { useState, useEffect } = React;

interface IProps {
  width?: string;
  padding?: boolean;
  onClose: () => void;
}

const StyledComponent = styled.div<{ padding: boolean; mounted: boolean }>`
  ${({ padding, mounted, theme: { sizes, colors } }) => css`
    width: 0;
    height: calc(100vh - ${sizes.headerHeight});
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    border-left: 1px solid ${colors.border};
    box-shadow: 0 0 10px #ddd;
    background-color: white;
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;

    .close-button-wrap {
      position: fixed;
      top: 0.5rem;
      right: 0.75rem;
      font-size: 1.25rem;
      color: ${colors.gray3};
      transition: color 500ms ease;

      &:hover {
        color: ${colors.black};
        cursor: pointer;
      }
    }

    ${padding &&
      css`
        padding: 3rem;
      `}

    ${mounted &&
      css`
        width: auto;
        transform: translateX(0);
      `}

    ${media.mobile`
      width: 100% !important;
      height: calc(100vh - ${sizes.headerHeight});
      border-left: none;

      ${mounted &&
        css`
          width: 100%;
        `}
    `}
  `}
`;

const RightSection: React.FC<IProps> = ({
  width,
  padding = true,
  onClose,
  children,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <StyledComponent style={{ width }} padding={padding} mounted={mounted}>
      <div className="close-button-wrap" onClick={onClose}>
        <Icon iconName="fa fa-times" />
      </div>
      {children}
    </StyledComponent>
  );
};

export default RightSection;
