import * as React from 'react';
import styled, { css } from 'styles/Styled';

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    font-size: 0.875rem;
    color: ${colors.red};
  `}
`;

const ErrorMessage: React.FC = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

export default ErrorMessage;
