import Sendbird from 'sendbird';

export const getGroupChannelList = (
  connection: Sendbird.SendBirdInstance,
  listQuery?: SendBird.GroupChannelListQuery,
) =>
  new Promise((resolve, reject) => {
    const prevListQuery =
      listQuery || connection.GroupChannel.createMyGroupChannelListQuery();

    prevListQuery.includeEmpty = false;
    prevListQuery.order = 'latest_last_message';
    prevListQuery.limit = 10;

    if (prevListQuery.hasNext) {
      prevListQuery.next(function(channels, error) {
        if (error) {
          reject(error);
        }
        resolve({
          channels,
          prevListQuery: prevListQuery,
        });
      });
    }
  });
