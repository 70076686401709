import * as React from 'react';
import styled, { css } from 'styles/Styled';
import EstimationSection from 'components/connect/estimation/estimationSection';
import ReviewLabel from 'elements/helper/reviewLabel';
import Section from 'elements/ui/section';

interface IProps {
  score: number;
  review: string;
  createdAt: string;
}

const Component = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    padding: 1rem;
    border-radius: ${sizes.borderRadius};
    background-color: ${colors.gray7};

    time {
      font-size: 0.85rem;
      color: ${colors.gray2};
    }
  `}
`;

const EstimationReview: React.FC<IProps> = ({ score, review, createdAt }) => (
  <EstimationSection title="후기">
    <Component>
      <ReviewLabel isGood={score === 100} />
      <Section t={1} b={0.5}>
        {review}
      </Section>
      <Section t={0.5}>
        <time>{createdAt}</time>
      </Section>
    </Component>
  </EstimationSection>
);

export default EstimationReview;
