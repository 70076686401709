import * as React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import styled from 'styles/Styled';

interface IProps {
  defaultvalue: string;
  onChange: (nextState: string) => void;
}

const { useState } = React;
const Component = styled.div`
  button {
    padding: 0.475rem 1.25rem;
    font-size: 0.875rem;
  }
`;

const ReviewSelect: React.FC<IProps> = ({ defaultvalue, onChange }) => {
  const [state, setState] = useState(defaultvalue);
  onChange(state);

  return (
    <Component>
      <ButtonGroup>
        <Button
          type="button"
          color={state === '' ? 'success' : 'secondary'}
          onClick={() => setState('')}
        >
          전체
        </Button>
        <Button
          type="button"
          color={state === '100' ? 'success' : 'secondary'}
          onClick={() => setState('100')}
        >
          Good
        </Button>
        <Button
          type="button"
          color={state === '0' ? 'success' : 'secondary'}
          onClick={() => setState('0')}
        >
          Bad
        </Button>
      </ButtonGroup>
    </Component>
  );
};

export default ReviewSelect;
