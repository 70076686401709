import * as React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import * as mixins from 'styles/Mixin';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  block?: boolean;
  size?: 'small' | 'large';
}

const StyledComponent = styled.button`
  ${({ theme: { sizes, colors } }) => css`
    ${mixins.inputButton}

    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative;
    border-radius: ${sizes.radius};

    &.btn-border {
      border: 1px solid ${colors.primary};
      background-color: white;
      color: ${colors.primary};

      &:hover {
        background-color: ${colors.gray7};
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      text-decoration: none;
    }
  `}
`;

const Button: React.FC<IProps> = ({
  className,
  block,
  size,
  color = 'secondary',
  children,
  ...attrs
}) => (
  <StyledComponent
    style={{
      ...(block && {
        width: '100%',
      }),
    }}
    className={classnames(className, `btn btn-${color}`, {
      ...(size && { [size]: true }),
    })}
    {...attrs}
  >
    {children}
  </StyledComponent>
);

export default Button;
