import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN as LOGIN_PAGE } from 'core/consts/Path';
import { logout } from 'core/api/account';
import { useMediaQuery } from 'react-responsive';
import useFetch from 'core/hooks/UseFetch';

import { useAppContext } from 'core/context/appContext';
import theme from 'styles/Theme';
import menu from 'core/data/Menu';
import Header from 'components/base/layout/header';
import Aside from 'components/base/layout/aside';
import Content from 'components/base/layout/content';
import Main from 'components/base/layout/main';
import Breadcrumb from 'components/base/layout/breadcrumb';

const { useState, useEffect, useCallback } = React;

const LayoutContainer: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const {
    state: { notiCount },
  } = useAppContext();
  const [onLogout] = useFetch(logout, null);
  const [menuOpened, setMenuOpened] = useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoint.mobile}px)`,
  });

  // 로그아웃
  const handleLogout = useCallback(async () => {
    if (await onLogout()) {
      window.location.href = LOGIN_PAGE;
    }
  }, [onLogout]);

  // 링크 이동
  const handleLink = useCallback(
    (target: string, openWindow = false) => {
      if (
        target.indexOf('http://') === 0 ||
        target.indexOf('https://') === 0 ||
        target.indexOf('//') === 0
      ) {
        // path가 http 혹은 https로 시작한다면 location 이동
        if (openWindow) {
          window.open(target);
        } else {
          window.location.href = target;
        }
      } else {
        history.push(target);
      }
    },
    [history],
  );

  // 좌측 메뉴 토글 (모바일)
  const handleToggleAside = useCallback(() => {
    setMenuOpened(!menuOpened);
  }, [menuOpened]);

  // location이 변경될 때마다 모바일 좌측메뉴를 숨김
  useEffect(() => {
    if (menuOpened) {
      setMenuOpened(false);
    }
    // eslint-disable-next-line
  }, [location.key]);

  return (
    <>
      <Header onLogout={handleLogout} onToggleMenu={handleToggleAside} />
      <Aside
        opened={isMobile ? menuOpened : true}
        menu={menu}
        notiCount={notiCount}
        onLink={handleLink}
        onToggleMenu={handleToggleAside}
        onLogout={handleLogout}
      />
      <Content>
        <Breadcrumb menu={menu} />
        <Main>{children}</Main>
      </Content>
    </>
  );
};

export default LayoutContainer;
