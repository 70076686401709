import * as React from 'react';
import produce from 'immer';
import moment from 'moment';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import MyShopFormItem from 'components/myPage/myShopFormItem';
import Select from 'elements/form/select';
import Checkbox from 'elements/form/checkbox';

interface IDataSource {
  [key: string]: {
    startTime: string;
    endTime: string;
    isHoliday?: boolean;
  };
}

interface IProps {
  defaultValue: IDataSource;
  onChange: (nextValue: IDataSource) => void;
}

const START_TIME = 7;
const END_TIME = 20;
const TIMES: string[] = [];
for (let i = START_TIME; i <= END_TIME; i += 1) {
  TIMES.push(moment(i, 'H').format('HH:00:00'));
}

const getLabel = (day: string) => {
  switch (day) {
    case 'weekday':
      return '평일';
    case 'saturday':
      return '토요일';
    case 'sunday':
      return '일요일';
    case 'holiday':
      return '공휴일';
    default:
      return '';
  }
};

const { useState, useCallback } = React;
const StyledComponent = styled.ul`
  ${({ theme: { colors } }) => css`
    .time-selector {
      &__item {
        margin-bottom: 0.5rem;
        position: relative;

        &__check {
          position: absolute;
          top: 1.45rem;
          left: -2rem;
        }

        &__wrap {
          padding: 0.75rem 1.5rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: 4px;
          background-color: ${colors.gray8};
          color: ${colors.gray3};
          opacity: 0.5;
          transition: opacity 200ms ease;

          &__mark {
            margin-right: 0.25rem;
            color: ${colors.primary};
          }

          > div {
            margin: 0 1rem;

            .select-wrap {
              width: 5.75rem;
            }
          }
        }

        &--active {
          .time-selector__item__wrap {
            opacity: 1;
            color: ${colors.black};
          }
        }
      }

      ${media.mobile`
        &__item {
          display: flex;
          justify-content: flex-end;
          position: relative;

          &__check {
            position: absolute;
            top: 1.425rem;
            left: 0;
          }

          &__wrap {
            width: calc(100% - 25px);
            display: flex;

            > div {
              flex: 1;

              .select-wrap {
                width: 100%;
              }
            }

            &__pc {
              display: none;
            }
          }
        }
      `}
    }
  `};
`;

const TimeSelect: React.FC<IProps> = ({ defaultValue, onChange }) => {
  const [state, setState] = useState(defaultValue);

  const handleChange = useCallback(
    (day, key, value) => {
      const nextState = produce(state, draft => {
        (draft as any)[day][key] = value;
      });
      setState(nextState);
      onChange(nextState);
    },
    [state, onChange],
  );

  return (
    <MyShopFormItem label="영업시간" required>
      <StyledComponent>
        {Object.keys(state).map(day => {
          const { startTime, endTime, isHoliday } = state[day];
          const isRequred = typeof isHoliday === 'undefined';

          return (
            <li
              key={day}
              className={classnames('time-selector__item', {
                'time-selector__item--active': !isHoliday,
              })}
            >
              {!isRequred && (
                <span className="time-selector__item__check">
                  <Checkbox
                    checked={!isHoliday}
                    onChange={e => {
                      handleChange(
                        day,
                        'isHoliday',
                        !(e.target as HTMLInputElement).checked,
                      );
                    }}
                  />
                </span>
              )}
              <div className="time-selector__item__wrap">
                {isRequred && (
                  <span className="time-selector__item__wrap__mark">*</span>
                )}
                {getLabel(day)}
                <Select
                  bsSize="small"
                  disabled={isHoliday}
                  value={startTime}
                  onChange={e => {
                    handleChange(day, 'startTime', e.target.value);
                  }}
                >
                  {TIMES.map(time => (
                    <option key={time} value={time}>
                      {moment(time, 'HH:mm:ss').format('H')}시
                    </option>
                  ))}
                </Select>
                부터
                <Select
                  bsSize="small"
                  disabled={isHoliday}
                  value={endTime}
                  onChange={e => {
                    handleChange(day, 'endTime', e.target.value);
                  }}
                >
                  {TIMES.map(time => (
                    <option key={time} value={time}>
                      {moment(time, 'HH:mm:ss').format('H')}시
                    </option>
                  ))}
                </Select>
                <span className="time-selector__item__wrap__pc">까지</span>
              </div>
            </li>
          );
        })}
      </StyledComponent>
    </MyShopFormItem>
  );
};

export default TimeSelect;
