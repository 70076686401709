import * as React from 'react';
import classnames from 'classnames';

import '@fortawesome/fontawesome-free/js/all';

interface IProps {
  iconName: string;
  className?: string;
  onClick?: () => void;
}

const Icon: React.FC<IProps> = ({ iconName, onClick, className = '' }) => (
  <i className={classnames(iconName, className)} onClick={onClick} />
);

export default Icon;
