import * as React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import * as mixins from 'styles/Mixin';
import ErrorMessage from 'elements/form/errorMessage';

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  visibleLength?: boolean;
  errorMessage?: string;
  bsSize?: 'small' | 'large';
  value?: string;
  defaultValue?: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .input-wrap {
      ${mixins.inputSelectWrap}
      background-color: ${colors.gray8};

      textarea {
        ${mixins.inputButton}

        flex: 1;
        height: auto;
        padding:1rem;
        background-color: transparent;
        color: ${colors.gray3};
        resize: none;
        transition: all 300ms ease;

        &:focus {
          color: ${colors.black};
        }
      }

      .length {
        padding: 0.85rem;
        text-align: right;
        font-size: 0.825rem;
        color: ${colors.gray4};
      }
    }

    .error-message {
      margin-top: 0.35rem;
    }
  `}
`;

const Textarea: React.FC<IProps> = ({
  className,
  bsSize,
  visibleLength = true,
  errorMessage,
  value,
  ...attrs
}) => {
  return (
    <StyledComponent className={className}>
      <div className={classnames('input-wrap', { error: !!errorMessage })}>
        <textarea
          {...attrs}
          className={classnames({ ...(bsSize && { [bsSize]: true }) })}
          value={value}
        />
        {typeof value === 'string' && visibleLength && attrs.maxLength && (
          <div className="length">
            {value.length}/{attrs.maxLength}
          </div>
        )}
      </div>
      {!!errorMessage?.trim() && (
        <div className="error-message">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </StyledComponent>
  );
};

export default Textarea;
