import * as React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import * as mixins from 'styles/Mixin';
import ErrorMessage from 'elements/form/errorMessage';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  bsSize?: string;
  surffix?: JSX.Element;
  visibleLength?: boolean;
  errorMessage?: string;
  value?: string;
  defaultValue?: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .input-wrap {
      ${mixins.inputSelectWrap}

      display: flex;
      background-color: ${colors.gray8};

      input {
        ${mixins.inputButton}

        flex: 1;
        background-color: transparent;
        color: ${colors.gray3};
        transition: all 300ms ease;

        &:focus {
          color: ${colors.black};
        }
      }

      .length {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        font-size: 0.825rem;
        color: ${colors.gray4};
      }

      .surffix {
        border-left: 1px solid ${colors.gray6};
      }
    }

    .error-message {
      margin-top: 0.35rem;
    }
  `}
`;

const Input: React.FC<IProps> = ({
  className,
  bsSize,
  surffix,
  visibleLength = true,
  errorMessage,
  value,
  ...attrs
}) => {
  return (
    <StyledComponent className={className}>
      <div className={classnames('input-wrap', { error: !!errorMessage })}>
        <input
          {...attrs}
          {...(attrs.type === 'number' && { type: 'text', pattern: '[0-9]*' })}
          className={classnames({ ...(bsSize && { [bsSize]: true }) })}
          value={value}
          onChange={e => {
            if (attrs.onChange && e.target.validity.valid) {
              attrs.onChange(e);
            }
          }}
        />
        {typeof value !== 'undefined' && visibleLength && attrs.maxLength && (
          <div className="length">
            {value.length}/{attrs.maxLength}
          </div>
        )}
        {surffix && <div className="surffix">{surffix}</div>}
      </div>
      {!!errorMessage?.trim() && (
        <div className="error-message">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </StyledComponent>
  );
};

export default Input;
