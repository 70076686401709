import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CONNECT_ESTIMATION } from 'core/consts/Path';
import EstimationTemp from 'components/connect/estimation/estimationTemp';
import EstimationListCont from 'containers/connect/estimation/estimationListCont';
import EstimationDetailCont from 'containers/connect/estimation/estimationDetailCont';
import { IContProp } from './interface';

const { useState, useCallback } = React;

const EstimationCont: React.FC = () => {
  const history = useHistory();
  const { id: idParam } = useParams();
  const [state, setState] = useState({
    reloadTrigger: 0,
  });

  // 견적 요청서서 상세 보기
  const handleViewDetail = useCallback(
    estimationId => {
      history.push(`${CONNECT_ESTIMATION}/${estimationId}`);
    },
    [history],
  );

  // 견적 요청서 목록 보기
  const handleViewList = useCallback(() => {
    history.push(CONNECT_ESTIMATION);
  }, [history]);

  // 업데이트 대상 견적 요청서
  const handleReloadList = useCallback(() => {
    setState({ ...state, reloadTrigger: +new Date() });
  }, [state]);

  const dataSource: IContProp = {
    reloadTrigger: state.reloadTrigger,
    selectedId: !!idParam ? Number(idParam) : null,
    onViewDetail: handleViewDetail,
    onViewList: handleViewList,
    onReloadList: handleReloadList,
  };

  // 견적 요청서 목록과 상세를 같이 보여 주기 때문에, Switch는 넣지 않는다.
  return (
    <EstimationTemp>
      {/* 견적 요청서 목록 */}
      <EstimationListCont {...dataSource} />

      {/* 견적 요청서 상세 & 발송 */}
      {!!idParam && <EstimationDetailCont {...dataSource} />}
    </EstimationTemp>
  );
};

export default EstimationCont;
