import * as React from 'react';
import { Input, Button, Container, Row, Col } from 'reactstrap';

import MemoMetaInsuranceCompanyDropDown from './MemoMetaInsuranceCompanyDropDown';

import styled, { css } from 'styles/Styled';

import koStrings from '../../../../meta/ko';

import { MemoMeta } from 'core/interface/MemoMeta';
import { PossibleInsuranceCompaniesObject } from 'core/interface/PossibleInsuranceCompaniesObject';

interface IProps {
  pending: boolean;
  memoMeta: MemoMeta;
  callback: Function;
  possibleInsuranceCompanies: PossibleInsuranceCompaniesObject[];
}

const { useState, useEffect } = React;

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: #f2f2f2;
    padding: 1.2rem 0;
    .memo-meta-submit-form {
      @media (max-width: 481px) {
        margin-bottom: 30px;
      }
      .memo-meta-submit-form-container {
        @media (min-width: 1200px) {
          max-width: 100%;
        }
      }
      .memoMeta-row {
        padding-bottom: 1.2rem;
      }
      label {
        font-size: 1.05rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
      }
      input {
        height: auto;
        padding: 0.7rem 0.65rem 0.9rem 10px;
        font-size: 1.05rem;
        font-weight: 500;
        &::placeholder {
          font-weight: 500;
          font-size: 1.05rem;
        }
      }
      .two-column-row {
        div.col:nth-of-type(1) {
          padding-right: 4px;
        }
        div.col:nth-of-type(2) {
          padding-left: 4px;
        }
        @media (max-width: 481px) {
          display: none;
        }
      }
      .one-column-row {
        @media (min-width: 481px) {
          display: none;
        }
      }
      .input-with-counter {
        position: relative;
      }
      .input-counter {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        color: ${colors.linkWater};
        font-size: 85%;
      }
      .vin-input {
        /* Chrome, Safari, Edge, Opera */
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        -moz-appearance: textfield;
      }
      .form-divider {
        width: 95.5%;
        background-color: #ced4da;
        margin-top: 0.8rem;
        margin-bottom: 1.6rem;
      }
      .mt-2 {
        text-align: center;
      }
      .save-meta-button {
        width: 20%;
        @media (max-width: 481px) {
          width: 30%;
        }
      }
    }
  `}
`;

const {
  estimationDetail: {
    memo: {
      title,
      memoPlaceholder,
      maxMemoLength,
      vinTitle,
      vinPlaceholder,
      maxVinLength,
      carRegDate,
      carRegDatePlaceholder,
      regDocYear,
      regDocYearPlaceholder,
      prevInsuranceCompany,
      prevInsuranceCompanyPlaceholder,
      // NOTE: insuranceDate would better understood as plannedInsuranceRenewalDate
      insuranceDate,
      insuranceDatePlaceholder,
    },
    saveButton: { clickToSave, saving },
  },
} = koStrings;

const EstimationDetailMemo: React.FC<IProps> = ({
  pending,
  memoMeta,
  callback,
  possibleInsuranceCompanies,
}) => {
  const [memoMetaObject, setMemoMetaObject] = useState(memoMeta || '');
  const [disableButton, toggleDisableButton] = useState(false);
  const [memoCount, setMemoCount] = useState(0);
  const [vinCount, setVinCount] = useState(0);

  useEffect(() => {
    setMemoMetaObject(memoMeta || '');
    setMemoCount(memoMeta?.description?.length || 0);
    setVinCount(memoMeta?.vin?.length || 0);
  }, [memoMeta]);

  const onKeyDown = (e: any) => {
    const { key } = e;
    if (key === 'Backspace' || key === 'Delete') {
      let value = e.target.value;
      const name = e.target.name;
      value = value.replace(/-/g, '');
      setMemoMetaObject({
        ...memoMetaObject,
        [name]: value,
      });
    }
  };

  const handleInputChange = (e: any) => {
    let isValidInput = false;
    let value = e.target.value;
    const valueLength = value.length;
    let tempValueToCheck = '';
    const name = e.target.name;
    switch (name) {
      case 'description':
        isValidInput = true;
        setMemoCount(value.length);
        break;
      case 'vin':
        value = value.replace(/[\s-&/\\#, +()$~%.'":*?<>{}!@^_=[\];~`]/g, '');
        value = value.toUpperCase();
        if (valueLength > 0 && valueLength < 17) {
          toggleDisableButton(true);
        } else {
          toggleDisableButton(false);
        }
        setVinCount(value.length);
        isValidInput = true;
        break;
      case 'regDocYear':
        value = value.replace(/\s/g, '');
        if (!Number.isNaN(Number(value))) {
          isValidInput = true;
        }
        if (valueLength > 0 && valueLength < 4) {
          toggleDisableButton(true);
        } else {
          toggleDisableButton(false);
        }
        break;
      case 'insuranceDate':
      case 'carRegDate':
        value = value.replace(/[-\s]/g, '');
        tempValueToCheck = value.replace(/-/g, '');
        if (!Number.isNaN(Number(tempValueToCheck))) {
          value = value.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
          isValidInput = true;
          if (value.length > 0 && value.length < 10) {
            toggleDisableButton(true);
          } else {
            toggleDisableButton(false);
          }
        }
        break;
      default:
    }
    if (isValidInput) {
      setMemoMetaObject({
        ...memoMetaObject,
        [name]: value,
      });
    }
  };

  return (
    <StyledComponent>
      <form
        className="memo-meta-submit-form"
        onSubmit={e => {
          e.preventDefault();
          callback(memoMetaObject);
        }}
      >
        <Container className="memo-meta-submit-form-container">
          <Row className="memoMeta-row">
            <Col>
              <label htmlFor="description">{title}</label>
              <div className="input-with-counter">
                <Input
                  name="description"
                  type="text"
                  maxLength={30}
                  placeholder={memoPlaceholder}
                  value={memoMetaObject?.description || ''}
                  onChange={handleInputChange}
                />
                <div className="input-counter">
                  {memoCount}/{maxMemoLength}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="memoMeta-row">
            <Col>
              <label htmlFor="vin">{vinTitle}</label>
              <div className="input-with-counter">
                <Input
                  className="vin-input"
                  name="vin"
                  type="text"
                  maxLength={17}
                  placeholder={vinPlaceholder}
                  value={memoMetaObject?.vin || ''}
                  onChange={handleInputChange}
                />
                <div className="input-counter">
                  {vinCount}/{maxVinLength}
                </div>
              </div>
            </Col>
          </Row>
          {/* desktop 2 columns per row */}
          <Row className="memoMeta-row two-column-row">
            <Col>
              <label htmlFor="carRegDate">{carRegDate}</label>
              <Input
                name="carRegDate"
                type="text"
                maxLength={10}
                placeholder={carRegDatePlaceholder}
                value={memoMetaObject?.carRegDate || ''}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col>
              <label htmlFor="regDocYear">{regDocYear}</label>
              <Input
                name="regDocYear"
                type="text"
                maxLength={4}
                placeholder={regDocYearPlaceholder}
                value={memoMetaObject?.regDocYear || ''}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          {/* mobile 1 column per row */}
          <Row className="memoMeta-row one-column-row">
            <Col>
              <label htmlFor="carRegDate">{carRegDate}</label>
              <Input
                name="carRegDate"
                type="text"
                maxLength={10}
                placeholder={carRegDatePlaceholder}
                value={memoMetaObject?.carRegDate || ''}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
              />
            </Col>
          </Row>
          <Row className="memoMeta-row one-column-row">
            <Col>
              <label htmlFor="regDocYear">{regDocYear}</label>
              <Input
                name="regDocYear"
                type="text"
                maxLength={4}
                placeholder={regDocYearPlaceholder}
                value={memoMetaObject?.regDocYear || ''}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          {/* desktop 2 columns per row */}
          <Row className="memoMeta-row two-column-row">
            <Col>
              <MemoMetaInsuranceCompanyDropDown
                type="memo-meta"
                memoMetaObject={memoMetaObject}
                setMemoMetaObject={setMemoMetaObject}
                prevInsuranceCompany={prevInsuranceCompany}
                prevInsuranceCompanyPlaceholder={
                  prevInsuranceCompanyPlaceholder
                }
                possibleInsuranceCompanies={possibleInsuranceCompanies}
              />
            </Col>
            <Col>
              <label htmlFor="insuranceDate">{insuranceDate}</label>
              <Input
                name="insuranceDate"
                type="text"
                maxLength={10}
                placeholder={insuranceDatePlaceholder}
                value={memoMetaObject?.insuranceDate || ''}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
              />
            </Col>
          </Row>
          {/* mobile 1 column per row */}
          <Row className="memoMeta-row one-column-row">
            <Col>
              <MemoMetaInsuranceCompanyDropDown
                type="memo-meta"
                memoMetaObject={memoMetaObject}
                setMemoMetaObject={setMemoMetaObject}
                prevInsuranceCompany={prevInsuranceCompany}
                prevInsuranceCompanyPlaceholder={
                  prevInsuranceCompanyPlaceholder
                }
                possibleInsuranceCompanies={possibleInsuranceCompanies}
              />
            </Col>
          </Row>
          <Row className="memoMeta-row one-column-row">
            <Col>
              <label htmlFor="insuranceDate">{insuranceDate}</label>
              <Input
                name="insuranceDate"
                type="text"
                maxLength={10}
                placeholder={insuranceDatePlaceholder}
                value={memoMetaObject?.insuranceDate || ''}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
              />
            </Col>
          </Row>
        </Container>

        <hr className="form-divider" />

        <div className="mt-2">
          <Button
            className="save-meta-button btn-lg"
            color={disableButton ? 'secondary' : 'primary'}
            disabled={pending || disableButton}
          >
            {pending ? saving : clickToSave}
          </Button>
        </div>
      </form>
    </StyledComponent>
  );
};

export default EstimationDetailMemo;
