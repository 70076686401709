import * as React from 'react';
import { ServiceTypes, cardocServices } from 'core/data/cardocService';
import Label from 'elements/helper/label';

interface IProps {
  className?: string;
  type: string;
}

const { useMemo } = React;

const ServiceLabel: React.FC<IProps> = ({ className, type }) => {
  const item = useMemo(() => {
    const typed = type as 'common' | ServiceTypes;
    switch (typed) {
      case 'common':
        return {
          color: 'success',
        };

      case 'connectInsurance':
        return {
          color: 'info',
        };

      default:
        return null;
    }
  }, [type]);

  return item ? (
    <Label className={className} color={item.color}>
      {cardocServices[type as ServiceTypes]}
    </Label>
  ) : null;
};

export default ServiceLabel;
