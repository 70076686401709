import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { MY_PAGE } from 'core/consts/Path';
import { IMemuItems } from 'core/interface/Menu';
import { useAppContext } from 'core/context/appContext';
import Icon from 'elements/helper/icon';
import UserProfile from 'elements/helper/userProfile';
import StyledComponent from './Aside.styles';

const { useState, useEffect, useMemo, useCallback } = React;

interface IProps extends RouteComponentProps {
  opened: boolean;
  menu: IMemuItems;
  notiCount: { [key: string]: number };
  onLink: (path: string, openWindow?: boolean) => void;
  onToggleMenu: () => void;
  onLogout: () => void;
}

const Aside: React.FC<IProps> = ({
  location,
  opened,
  menu,
  notiCount,
  onLink,
  onToggleMenu,
  onLogout,
}) => {
  const {
    state: { connectShop },
  } = useAppContext();

  const [active, setActive] = useState(false);
  const [, path1, path2, path3] = location.pathname.split('/');
  const pathKey = `/${path1}/${path2}/${path3}`;

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        setActive(true);
      });
    }
  }, [opened, onToggleMenu]);

  const handleClose = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      onToggleMenu();
    }, 500);
  }, [onToggleMenu]);

  const menuItems = useMemo(
    () =>
      Object.keys(menu).map(key => {
        const { label, path, hideAside, openWindow, children } = menu[key];

        if (hideAside) {
          return null;
        }

        const firstDepthOpend =
          !!children &&
          Object.keys(children).some(childKey =>
            children[childKey].path.includes(pathKey),
          );
        const hasCount =
          !!children &&
          Object.keys(children).some(childKey => !!notiCount[childKey]);

        return (
          <li key={key}>
            {!!children && (
              <input
                type="checkbox"
                id={`checkbox-${key}`}
                defaultChecked={firstDepthOpend}
              />
            )}
            <label
              className="first-depth"
              htmlFor={`checkbox-${key}`}
              onClick={path ? () => onLink(path, openWindow) : undefined}
            >
              <span
                className={classnames('label', {
                  active: !!path && path.includes(`/${path1}/${path2}`),
                })}
              >
                {label}
              </span>
              {hasCount && <span className="dot" />}
              {!!children && (
                <span className="arrow">
                  <Icon iconName="fa fa-chevron-down" />
                </span>
              )}
            </label>
            {!!children && (
              <div className="second-depth">
                {Object.keys(children).map(childKey => {
                  const { label: childLabel, path: childPath } = children[
                    childKey
                  ];
                  return (
                    <p
                      key={childKey}
                      className={classnames({
                        active: childPath.includes(pathKey),
                      })}
                      onClick={() => onLink(childPath)}
                    >
                      {childLabel}
                      {!!notiCount[childKey] && (
                        <span className="count">{notiCount[childKey]}</span>
                      )}
                    </p>
                  );
                })}
              </div>
            )}
            <hr />
          </li>
        );
      }),
    [menu, notiCount, path1, path2, pathKey, onLink],
  );

  return opened ? (
    <StyledComponent active={active}>
      <div className="background" />
      <div className="wrap">
        <button type="button" className="close-button" onClick={handleClose}>
          <Icon iconName="fa fa-times" />
        </button>
        <div className="profile">
          <div className="icon">
            <UserProfile size="6rem" />
          </div>
          <p>{connectShop?.name}</p>
        </div>
        <nav className="nav">
          <ul>{menuItems}</ul>
        </nav>
        <nav className="sub-nav">
          <ul>
            <li className="util" onClick={() => onLink(`${MY_PAGE}/shop`)}>
              <hr className="dark" />
              <div className="first-depth">내정보</div>
              <hr className="dark" />
            </li>
            <li className="util" onClick={onLogout}>
              <div className="first-depth">로그아웃</div>
              <hr className="dark" />
            </li>
          </ul>
        </nav>
      </div>
    </StyledComponent>
  ) : null;
};

export default withRouter(Aside);
