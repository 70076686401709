import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Sendbird from 'sendbird';
import toastr from 'toastr';

import { CONNECT_CHATTING } from 'core/consts/Path';
import { useAppContext } from 'core/context/appContext';
import {
  getChatting,
  sendChatting,
  sendFile,
} from 'core/sdk/sendbird/groupChannelDetail';
import {
  messageReceiveHandler,
  removeChannelHandler,
} from 'core/sdk/sendbird/eventHandler';
import { convertHeicToJpeg } from 'core/api/util';
import useFile from 'core/hooks/form/UseFile';
import ChattingDetailPage from 'pages/connect/chatting/ChattingDetailPage';
import OriginImage from 'elements/helper/originImage';

import {
  getCurrentScrollHeight,
  setScrollPosition,
  setScollToBottom,
} from './elementEvent';
import { IContProp } from './interface';
import { IContProp as IParentContProps } from '../chattingCont/interface';

const { useRef, useState, useEffect, useCallback } = React;
const MESSAGE_COUNT = 20;

const ChattingDetailCont: React.FC<IParentContProps> = ({
  channel,
  user,
  onLinkToList,
}) => {
  const history = useHistory();

  const {
    state: { sendbirdConnection },
  } = useAppContext();

  const [state, setState] = useState<{
    isSending: boolean;
    chatting: any[];
    prevListQuery: Sendbird.PreviousMessageListQuery | null;
    originImgSrc: string;
  }>({
    isSending: false,
    chatting: [],
    prevListQuery: null,
    originImgSrc: '',
  });

  const channelRef = useRef<any>(null);
  const stateRef = useRef<any>({});

  const ref = useRef<any>({
    $chatWindow: null,
    prevScrollPosition: 0,
    beforeStateChatting: null,
  });

  // file hooks
  const [selectedFiles, onChangeFile] = useFile({
    multiple: true,
    accept: 'image/*, .heic',
  });

  // 스크롤 바닥으로
  const scrollToBottom = useCallback(() => {
    setScollToBottom(ref.current.$chatWindow as any);
    setTimeout(() => {
      setScollToBottom(ref.current.$chatWindow as any);
    }, 500);
  }, []);

  // 샌드버드 채팅 조회
  const getSbChatting = useCallback(
    async targetChannel => {
      try {
        const listQuery = targetChannel.createPreviousMessageListQuery();
        const { listQuery: prevListQuery, chatting }: any = await getChatting(
          targetChannel,
          listQuery,
          MESSAGE_COUNT,
        );

        setState({
          ...state,
          chatting,
          prevListQuery,
        });

        // 채팅 목록 불러온 후 스크롤 맨 밑으로
        scrollToBottom();

        // 스크롤 위치를 기억 해둠
        ref.current.prevScrollPosition = getCurrentScrollHeight(
          ref.current.$chatWindow as any,
        );
      } catch {
        toastr.error('채팅 목록을 불러오는데 실패했습니다.');
        history.replace(CONNECT_CHATTING);
      }
    },
    [history, state, scrollToBottom],
  );

  // 샌드버드 채팅 더보기 (페이지네이션)
  const loadSbChatting = useCallback(async () => {
    try {
      if (
        !channelRef.current ||
        !stateRef.current.prevListQuery ||
        !stateRef.current.prevListQuery?.hasMore
      ) {
        return;
      }

      const { listQuery: prevListQuery, chatting }: any = await getChatting(
        channelRef.current,
        stateRef.current.prevListQuery,
        MESSAGE_COUNT,
      );

      setState({
        ...stateRef.current,
        chatting: [...chatting, ...stateRef.current.chatting],
        prevListQuery,
      });

      // 더보기시 스크롤을 불러온 채팅의 위치로 이동
      const $window = ref.current.$chatWindow as any;
      setScrollPosition(
        $window,
        getCurrentScrollHeight($window) -
          ref.current.prevScrollPosition -
          $window.offsetHeight,
      );
      setTimeout(() => {
        ref.current.prevScrollPosition = getCurrentScrollHeight($window);
      }, 200);
    } catch {
      toastr.error('채팅 목록을 불러오는데 실패했습니다.');
    }
  }, []);

  // 스크롤 이벤트
  const setWindowEl = useCallback(
    $el => {
      ref.current.$chatWindow = $el;
      $el.addEventListener('scroll', () => {
        if ($el.scrollTop === 0) {
          loadSbChatting();
        }
      });
    },
    [loadSbChatting],
  );

  // 채팅 메세지 발송
  const handleSendMessage = useCallback(
    async messsage => {
      if (!channel) return;
      try {
        const newChatting: any = await sendChatting(
          sendbirdConnection,
          channel,
          messsage,
        );

        setState({
          ...state,
          chatting: [...stateRef.current.chatting, newChatting],
        });

        scrollToBottom();
      } catch {
        toastr.error('메세지 전송에 실패했습니다.');
      }
    },
    [sendbirdConnection, channel, state, scrollToBottom],
  );

  // 파일 메세지 발송
  const sendFileMessage = useCallback(
    async selectedFiles => {
      if (!channel || !selectedFiles?.length) return;

      let requestFiles = selectedFiles;

      // 이미지 파일만 전송 가능
      if (
        [...selectedFiles].filter((file: File) => {
          return file.type.indexOf('image/') !== 0;
        }).length !== 0
      ) {
        toastr.error('이미지 파일만 전송 가능합니다.');
        return;
      }

      setState({ ...state, isSending: true });

      // .heic 파일 변환
      const heicFiles = [...selectedFiles].filter((file: File) =>
        file.type.includes('image/heic'),
      );

      if (heicFiles.length) {
        const promises: any = [];

        [...heicFiles].forEach((file: File) => {
          promises.push(
            new Promise(resolve => {
              const newFile = convertHeicToJpeg(file);
              if (newFile) {
                resolve(newFile);
              }
            }),
          );
        });

        // .heic 파일 변환이 모두 완료되면 원래 file array에서 교체
        const heicResponse: File[] = await Promise.all(promises);
        requestFiles = [...requestFiles].map((file: File) => {
          if (file.type.includes('image/heic')) {
            const newFile = heicResponse[0];
            heicResponse.shift();
            return newFile;
          }
          return file;
        });
      }

      try {
        const newChatting: any = await sendFile(channel, requestFiles);

        setState({
          ...state,
          isSending: false,
          chatting: [...stateRef.current.chatting, ...newChatting],
        });

        scrollToBottom();
      } catch {
        setState({ ...state, isSending: false });
        toastr.error('메세지 전송에 실패했습니다.');
      }
    },
    [channel, state, scrollToBottom],
  );

  // 원본 이미지 토글
  const handleToggleImage = useCallback(
    originImgSrc => {
      setState({
        ...state,
        originImgSrc,
      });
    },
    [state],
  );

  // 메세지 수신 핸들러
  const onRecieveHandler = useCallback(
    reveceMessage => {
      // 보고 있는 채팅이므로 읽음 처리
      setState({
        ...stateRef.current,
        chatting: [...stateRef.current.chatting, reveceMessage],
      });

      scrollToBottom();
    },
    [scrollToBottom],
  );

  // 채널 url이 바뀔때마다 initialize
  useEffect(() => {
    if (!channel) return;

    // 채팅 내역 조회
    getSbChatting(channel);

    // 메세지 수신 이벤트 핸들러 등록
    messageReceiveHandler(sendbirdConnection, channel.url, onRecieveHandler);

    return () => {
      // 컴포넌트 언마운트시 채널에 대한 핸들러를 제거
      removeChannelHandler(sendbirdConnection, channel.url);
    };

    // eslint-disable-next-line
  }, [channel]);

  // 파일이 선택될 때마다 전송
  useEffect(() => {
    sendFileMessage(selectedFiles);
    // eslint-disable-next-line
  }, [selectedFiles]);

  // 이벤트 리스너에서 사용하기 위해 state가 변경될 때마다 reference에 저장 해둠
  useEffect(() => {
    channelRef.current = channel;
  }, [channel]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const dataSource: IContProp = {
    hasMore:
      typeof state.prevListQuery?.hasMore === 'boolean'
        ? state.prevListQuery?.hasMore
        : true,
    isSending: state.isSending,
    chatting: state.chatting,
    user,
    setWindowEl,
    onLinkToList,
    onSendMessage: handleSendMessage,
    onSendFiles: sendFileMessage,
    onAddFile: onChangeFile,
    onToggleOriginImage: handleToggleImage,
  };

  return (
    <>
      {/* 채팅 영역 */}
      <ChattingDetailPage {...dataSource} />

      {/* 원본 이미지 */}
      <OriginImage
        imageSrc={state.originImgSrc}
        onClose={() => handleToggleImage('')}
      />
    </>
  );
};

export default ChattingDetailCont;
