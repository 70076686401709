import queryString from 'query-string';
import { connectApiCreator, cardocApiCreator } from 'core/api/ApiCreator';

import { MemoMeta } from 'core/interface/MemoMeta';

interface MemoMetaOptional {
  id?: number;
  description?: string;
  vin?: string;
  carRegDate?: string;
  regDocYear?: string;
  prevInsuranceId?: string;
  insuranceDate?: string;
}
/**
 * 견적 요청서 키워드 검색
 */
interface ISearchKeywordParams {
  [key: string]: string | number;
  page: number;
}
export const searchkeywordEstimationReq = async (
  params: ISearchKeywordParams,
) => {
  const shopId = sessionStorage.getItem('connectShopId');
  const response = await connectApiCreator.get(
    `/api/v1/estimation/shop/${shopId}/search?${queryString.stringify(params)}`,
  );
  const { content } = response.data;
  const contentIdAndDescription = content.map(async (quote: any) => {
    const { id: connectEstimationId } = quote.connectEstimation;
    const newMemoMeta = await getMemoMeta(connectEstimationId);
    const { id, description } = newMemoMeta.data;
    return { id, description };
  });
  const fulfilledContentMeta: Array<MemoMeta> = await Promise.all(
    contentIdAndDescription,
  );

  const contentWithMemoMeta = content.map(
    (contentElement: any, index: number) => {
      const memoDescription = fulfilledContentMeta[index].description;
      return {
        ...contentElement,
        memoMeta: {
          description: memoDescription,
        },
      };
    },
  );

  const responseWithContentWithMemoMeta = {
    ...response,
    data: {
      ...response.data,
      content: [...contentWithMemoMeta],
    },
  };

  return responseWithContentWithMemoMeta;
};

/**
 * 견적 요청서 상태별 검색
 */
interface ISearchStatusParams {
  page: number;
  status: '' | 'on-call' | 'on-chat' | 'complete';
}

export const searchStatusEstimationReq = async ({
  status,
  ...params
}: ISearchStatusParams) => {
  const shopId = sessionStorage.getItem('connectShopId');
  let statusParam = '';

  if (status === 'complete') {
    statusParam = '/rvw-complete';
  } else if (!!status) {
    statusParam = `/${status}`;
  }

  const response = await connectApiCreator.get(
    `/api/v1/estimation/shop/${shopId + statusParam}?${queryString.stringify(
      params,
    )}`,
  );

  const { content } = response.data;
  const contentIdAndDescription = content.map(async (quote: any) => {
    const { id: connectEstimationId } = quote.connectEstimation;
    const newMemoMeta = await getMemoMeta(connectEstimationId);
    const { id, description } = newMemoMeta.data;
    return { id, description };
  });
  const fulfilledContentMeta: Array<MemoMeta> = await Promise.all(
    contentIdAndDescription,
  );

  const contentWithMemoMeta = content.map(
    (contentElement: any, index: number) => {
      const memoDescription = fulfilledContentMeta[index].description;
      return {
        ...contentElement,
        memoMeta: {
          description: memoDescription,
        },
      };
    },
  );

  const responseWithContentWithMemoMeta = {
    ...response,
    data: {
      ...response.data,
      content: [...contentWithMemoMeta],
    },
  };

  return responseWithContentWithMemoMeta;
};

/**
 * authuserkey로 견적서 목록 검색
 */
export const getEstimationsByAuthuserkey = async (
  page: number,
  authuserkey: string,
) => {
  const shopId = sessionStorage.getItem('connectShopId');
  const response = await connectApiCreator.get(
    `/api/v1/estimation/shop/${shopId}/user/${authuserkey}?page=${page}`,
  );

  const { content } = response.data;
  const contentIdAndDescription = content.map(async (quote: any) => {
    const { id: connectEstimationId } = quote.connectEstimation;
    const newMemoMeta = await getMemoMeta(connectEstimationId);
    const finalizedInsuranceDetails = await getFinalizedCustomerInsuranceDetails(
      connectEstimationId,
    );
    const { data: finalizedInsuranceDetailsMeta } = finalizedInsuranceDetails;
    const { id, description } = newMemoMeta.data;
    return { id, description, finalizedInsuranceDetailsMeta };
  });

  // getEstimationsByAuthuserkey needs to fetch additionally necessary memoMeta
  // data from appropriate endpoint, to then immutably 'insert' this data into
  // Frontend state to pass down to appropriate components from higher up containers.
  const fulfilledContentMeta: Array<{
    id: number;
    description: string;
    finalizedInsuranceDetailsMeta: object;
  }> = await Promise.all(contentIdAndDescription);

  const contentWithMemoMeta = content.map(
    (contentElement: any, index: number) => {
      const memoDescription = fulfilledContentMeta[index].description;
      const finalizedInsuranceDetails =
        fulfilledContentMeta[index].finalizedInsuranceDetailsMeta;
      return {
        ...contentElement,
        memoMeta: {
          description: memoDescription,
        },
        finalizedInsuranceDetails,
      };
    },
  );

  const responseWithContentWithMemoMeta = {
    ...response,
    data: {
      ...response.data,
      content: [...contentWithMemoMeta],
    },
  };

  return responseWithContentWithMemoMeta;
};

/**
 * 견적 요청서 상세 조회
 */
export const getEstimationReq = (requestId: number) => {
  const shopId = sessionStorage.getItem('connectShopId');
  return connectApiCreator.get(
    `/api/v1/estimation/detail?requestId=${requestId}&shopId=${shopId}`,
  );
};

/**
 * Get memoMeta
 */
export const getMemoMeta = (estimationId: number) => {
  return connectApiCreator.get(`/api/v1/ins-estimation/${estimationId}`);
};

/**
 * Get list of all possible previous insurance companies
 */
export const getPossibleInsuranceCompanies = () => {
  return cardocApiCreator.get(`/v2/carInsuranceCorp/getType/all`);
};

/**
 * 견적서 발송
 */

export const createEstimation = (params: object) => {
  return connectApiCreator.post(
    `/api/v1/estimation/send-estimation-detail`,
    params,
  );
};
/**
 * 메모 저장
 */
export const updateMemoMeta = (
  estimationId: number,
  memoMeta: MemoMetaOptional,
) => {
  const {
    description,
    vin,
    carRegDate,
    regDocYear,
    prevInsuranceId,
    insuranceDate,
  } = memoMeta;
  return connectApiCreator.put(`/api/v1/ins-estimation/update/detail`, {
    estimationId,
    description,
    vin: vin || null,
    carRegDate: carRegDate || null,
    regDocYear: regDocYear || null,
    prevInsuranceId,
    insuranceDate: insuranceDate || null,
  });
};

/**
 * 담당자 저장
 */
export const updateAssignee = (estimationId: number, responsibility: string) =>
  connectApiCreator.put(`/api/v1/estimation/update/responsibility`, {
    estimationId,
    responsibility,
  });

/**
 * 견적서 확인
 */
export const checkEstimation = (estimationId: Number) =>
  connectApiCreator.put(`/api/v1/estimation/${estimationId}/update/checkYn`);

// GET finalized customer insurance details
export const getFinalizedCustomerInsuranceDetails = (estimationId: Number) => {
  return connectApiCreator.get(
    `/api/v1/ins-estimation/register/${estimationId}`,
  );
};

// POST to get InsuranceCertificateTempUrl
export const getInsuranceCertificateTempUrl = (formData: any) => {
  return connectApiCreator.post(`/api/v1/ins-estimation/certificate`, formData);
};

// POST to update FinalizedInsuranceDetails
export const uploadFinalizedInsuranceDetails = (
  finalizedInsuranceDetailsMeta: any,
  postRequest: boolean,
) => {
  if (postRequest) {
    return connectApiCreator.post(`/api/v1/ins-estimation/register`, {
      ...finalizedInsuranceDetailsMeta,
    });
  } else {
    return connectApiCreator.put(`/api/v1/ins-estimation/register`, {
      ...finalizedInsuranceDetailsMeta,
    });
  }
};
