import * as React from 'react';
import styled from 'styles/Styled';
import media from 'styles/Media';

const StyledComponent = styled.main`
  padding: 2.5rem;

  ${media.mobile`
    padding: 1rem 1rem 3rem;
  `}
`;

const Main: React.FC = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

export default Main;
