/**
 * .heic를 .jpeg로 변경
 */
export const convertHeicToJpeg = async (file: File) => {
  const formData = new FormData();
  formData.append('img', file);

  try {
    const response = await fetch(
      process.env.REACT_APP_HEIC_LAMBDA_HOST as string,
      {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_HEIC_LAMBDA_KEY as string,
        },
        body: formData,
      },
    );

    const buffer = await response.arrayBuffer();
    const newFile = new File(
      [buffer],
      `${file.name.substr(0, file.name.lastIndexOf('.'))}.jpeg`,
      {
        type: 'image/jpeg',
      },
    );

    return newFile;
  } catch {
    return false;
  }
};
