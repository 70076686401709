import * as React from 'react';
import classnames from 'classnames';
import styled from 'styles/Styled';

import * as mixins from 'styles/Mixin';
import arrowImage from './images/arrow.png';
import ErrorMessage from 'elements/form/errorMessage';

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  errorMessage?: string;
  bsSize?: 'small' | 'large';
  value?: string;
}

const StyledComponent = styled.div`
  .select-wrap {
    ${mixins.inputSelectWrap}

    select {
      ${mixins.inputButton}

      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px !important;
      background-color: white;
      background-image: url(${arrowImage});
      background-size: 15px auto;
      background-position: right;
      background-repeat: no-repeat;

      &::-ms-expand {
        display: none;
      }
    }
  }

  .error-message {
    margin-top: 0.35rem;
  }
`;

const InputComponent: React.FC<IProps> = ({
  className,
  errorMessage,
  bsSize,
  children,
  ...attrs
}) => (
  <StyledComponent className={className}>
    <div className={classnames('select-wrap', { error: !!errorMessage })}>
      <select
        {...attrs}
        className={classnames({ ...(bsSize && { [bsSize]: true }) })}
      >
        {children}
      </select>
      <div className="arrow"></div>
    </div>
    {!!errorMessage?.trim() && (
      <div className="error-message">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    )}
  </StyledComponent>
);

export default InputComponent;
