import * as React from 'react';
import { Modal } from 'reactstrap';
import styled, { css } from 'styles/Styled';
import Icon from 'elements/helper/icon';

interface IProps {
  width?: string;
  show: boolean;
  title?: string;
  onClose?: () => void;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    padding: 2.5rem;

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      font-size: 1.35rem;
      color: ${colors.gray3};
      transition: color 500ms ease;

      &:hover {
        color: ${colors.gray1};
        cursor: pointer;
      }
    }

    .modal-title {
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      font-weight: 800;
    }
  `}
`;

const ModalComponent: React.FC<IProps> = ({
  width,
  show,
  title,
  onClose,
  children,
}) => (
  <Modal isOpen={show} style={{ width, maxWidth: '90%' }}>
    <StyledComponent>
      {title && <h1 className="modal-title">{title}</h1>}
      {onClose && (
        <span className="close-button" onClick={onClose}>
          <Icon iconName="fa fa-times" />
        </span>
      )}
      <div className="modal-container">{children}</div>
    </StyledComponent>
  </Modal>
);

export default ModalComponent;
