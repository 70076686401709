import styled, { css } from 'styles/Styled';

const PHOTO_MARGIN = 0.5;

export default styled.div`
  ${({ theme: { sizes, colors } }) => css`
    .item-header {
      height: 20px;
    }

    .buttons {
      button {
        margin-left: 3px;
        padding: 2px 8px;
        border: 1px solid ${colors.gray5};
        border-radius: 2px;
        background-color: ${colors.gray6};
        font-size: 0.875rem;
        color: ${colors.gray1};
        outline: none;
        transition: background-color 500ms ease;

        &:not(:disabled):hover {
          background-color: ${colors.gray5};
        }
      }
    }

    .photo-items {
      .no-image {
        padding: 2.5rem;
        background-color: ${colors.gray8};
        text-align: center;
        font-size: 0.925rem;
        color: ${colors.gray3};
      }

      ul {
        width: calc(100% + 0.7rem);
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: -${PHOTO_MARGIN / 2}rem;

        li {
          width: calc(25% - ${PHOTO_MARGIN}rem);
          margin: ${PHOTO_MARGIN / 2}rem;
          margin-top: 0;
          height: 75px;
          position: relative;
          border: 1px solid ${colors.gray6};
          border-radius: ${sizes.radius};
          background-color: ${colors.gray8};
          transition: opacity 200ms ease;

          &.represent {
            background-image: url('/cardoc-logo.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70% auto;

            &:before {
              content: '대표사진';
              height: 15px;
              padding: 0 5px;
              display: inline-block;
              position: absolute;
              z-index: 2;
              top: 2px;
              left: 2px;
              border-radius: 2px;
              background-color: ${colors.primary};
              font-size: 0.775rem;
              color: white;
            }
          }

          &.hover {
            opacity: 0.35;
          }

          .image {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-size: 2rem;
            color: ${colors.gray5};

            img {
              width: 100%;
              height: 100%;
              position: relative;
              z-index: 1;
            }

            .remove-button {
              width: 13px;
              height: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              z-index: 1;
              top: 3px;
              right: 3px;
              font-size: 0.775rem;
              border: 1px solid white;
              border-radius: 2px;
              background-color: white;
              color: ${colors.gray1};
              transition: color 500ms ease;

              &:hover {
                color: ${colors.primary};
              }
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  `}
`;
