import * as React from 'react';
import { Button, Input } from 'reactstrap';

import { numberFormat } from 'core/function/Formatter';
import styled, { css } from 'styles/Styled';

import Section from 'elements/ui/section';
import EstimationSection from 'components/connect/estimation/estimationSection';
import MultipleInputsWithDropdown from 'components/connect/estimation/estimationRequest/MultipleInputsWithDropdown';

import { PossibleInsuranceCompaniesObject } from 'core/interface/PossibleInsuranceCompaniesObject';
import {
  MultipleInsuranceEstimatesMetaObject,
  MultipleInsuranceEstimatesMetaObjectPayload,
} from 'core/interface/MultipleInsuranceEstimates';

interface IProps {
  pending: boolean;
  suggestedAmount: string | null;
  channelUrl: string;
  onCreateEstimation: Function;
  onLinkToChat: Function;
  possibleInsuranceCompanies: PossibleInsuranceCompaniesObject[];
  multipleInsuranceEstimations: any;
}

const { useState, useEffect } = React;
const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .estimation-request {
      &__price {
        height: auto;
        padding: 0.65rem;
        font-size: 1.35rem;
        font-weight: 600;
        text-align: center;

        &[disabled] {
          background-color: ${colors.gray8};
        }
      }
    }
    .send-estimation-or-go-to-chat {
      margin-top: 10px;
    }
  `}
`;

const EstimationRequest: React.FC<IProps> = ({
  pending,
  suggestedAmount,
  channelUrl,
  onCreateEstimation,
  onLinkToChat,
  possibleInsuranceCompanies,
  multipleInsuranceEstimations,
}) => {
  const [state, setState] = useState({
    price: suggestedAmount || '',
  });

  useEffect(() => {
    setState({
      ...state,
      price: suggestedAmount || '',
    });
    // eslint-disable-next-line
  }, [suggestedAmount]);

  const [estimationsSentOut, setEstimationsSentOut] = useState(false);

  useEffect(() => {
    if (multipleInsuranceEstimations.length > 0) {
      setEstimationsSentOut(true);
    } else {
      setEstimationsSentOut(false);
    }
  }, [multipleInsuranceEstimations]);

  const [
    multipleInsuranceEstimatesMetaObjectArray,
    setMultipleInsuranceEstimatesMetaObjectArray,
  ] = useState<MultipleInsuranceEstimatesMetaObject[] | null>(null);

  const [disableButton, toggleDisableButton] = useState(true);
  const [
    insuranceObjectsWithIdAndPricePayload,
    setInsuranceObjectsWithIdAndPricePayload,
  ] = useState<MultipleInsuranceEstimatesMetaObjectPayload[]>([]);

  useEffect(() => {
    const insuranceObjectsWithIdAndPrice = multipleInsuranceEstimatesMetaObjectArray?.filter(
      metaObject => metaObject.insuId > 0 && metaObject.suggestedAmount,
    );
    if (
      insuranceObjectsWithIdAndPrice &&
      insuranceObjectsWithIdAndPrice?.length >= 3
    ) {
      let fieldsWithValidInput = 0;
      const payload = insuranceObjectsWithIdAndPrice.map(object => {
        if (object.suggestedAmount) {
          if (parseInt(object.suggestedAmount, 10) > 0) {
            fieldsWithValidInput += 1;
          }
          return {
            insuId: object.insuId,
            suggestedAmount: parseInt(object.suggestedAmount, 10),
          };
        } else {
          return { insuId: -1, suggestedAmount: -1 };
        }
      });
      setInsuranceObjectsWithIdAndPricePayload(payload);
      if (fieldsWithValidInput >= 3) {
        toggleDisableButton(false);
      } else {
        toggleDisableButton(true);
      }
    } else {
      toggleDisableButton(true);
    }
  }, [multipleInsuranceEstimatesMetaObjectArray]);

  useEffect(() => {
    const defaultPossibleInsuranceCompanies = [
      'DB손해보험',
      '현대해상',
      '삼성화재',
      'KB손해보험',
      '선택하기',
    ];
    const possibleInsuranceCompaniesByName = possibleInsuranceCompanies.map(
      company => {
        return company.insuName;
      },
    );
    const defaultMultipleInsuranceEstimatesMetaObjectArray = defaultPossibleInsuranceCompanies.map(
      (company, index) => {
        return {
          indexClicked: index,
          insuName: company,
          insuId: possibleInsuranceCompaniesByName.indexOf(company) + 1,
          suggestedAmount: '',
        };
      },
    );
    setMultipleInsuranceEstimatesMetaObjectArray(
      defaultMultipleInsuranceEstimatesMetaObjectArray,
    );
  }, [possibleInsuranceCompanies]);

  const processMultipleEstimationsSubmit = () => {
    const validMultipleInsuranceInputs = insuranceObjectsWithIdAndPricePayload.filter(
      object => {
        return object.suggestedAmount > 0;
      },
    );
    onCreateEstimation({
      estimationDetails: validMultipleInsuranceInputs,
    });
  };

  return (
    <StyledComponent className="estimation-request">
      <EstimationSection
        title="견적 금액"
        subtitle={
          estimationsSentOut
            ? ''
            : "* 금액을 '0'으로 입력하시는 해당 보험사의 견적은 저장 되지 않습니다."
        }
      >
        {(!state.price || multipleInsuranceEstimations.length > 0) &&
        multipleInsuranceEstimatesMetaObjectArray ? (
          <MultipleInputsWithDropdown
            possibleInsuranceCompanies={possibleInsuranceCompanies}
            multipleInsuranceEstimatesMetaObjectArray={
              multipleInsuranceEstimatesMetaObjectArray
            }
            setMultipleInsuranceEstimatesMetaObjectArray={
              setMultipleInsuranceEstimatesMetaObjectArray
            }
            multipleInsuranceEstimations={multipleInsuranceEstimations}
            estimationsSentOut={estimationsSentOut}
            channelUrl={channelUrl}
          />
        ) : (
          <Section b={0.35}>
            <Input
              readOnly
              className="estimation-request__price"
              disabled={!!channelUrl}
              value={
                !!state.price ? `${numberFormat(Number(state.price))}원` : ''
              }
            />
          </Section>
        )}
        <Section t={0.35} className="text-right send-estimation-or-go-to-chat">
          {!!channelUrl ? (
            <Button
              size="lg"
              color="primary"
              onClick={() => onLinkToChat(channelUrl)}
            >
              채팅방 바로가기
            </Button>
          ) : (
            <Button
              size="lg"
              color={disableButton ? 'secondary' : 'primary'}
              disabled={pending || disableButton}
              onClick={processMultipleEstimationsSubmit}
            >
              {pending ? '견적 발송중...' : '견적 발송하기'}
            </Button>
          )}
        </Section>
      </EstimationSection>
    </StyledComponent>
  );
};

export default EstimationRequest;
