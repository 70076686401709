import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import { MY_PAGE } from 'core/consts/Path';
import PageTemp from 'components/template/pageTemp';

interface IProps {
  page: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    padding-bottom: 5rem;
    position: relative;

    .my-page {
      &__description {
        position: absolute;
        top: 0.75rem;
        right: 0;
        font-size: 0.85rem;
      }

      &__tabs {
        border-bottom: 1px solid ${colors.gray6};

        ul {
          display: flex;

          li {
            display: flex;

            a {
              width: 100%;
              padding-bottom: 0.75rem;
              position: relative;
              top: 1px;
              font-size: 1.25rem;
              color: ${colors.gray4};
              text-align: center;
              text-decoration: none;
              transition: color 500ms ease;

              &:hover {
                color: ${colors.gray2};
              }
            }

            &.active a {
              border-bottom: 2px solid ${colors.primary};
              font-weight: 500;
              color: ${colors.black};
            }

            &:not(:first-of-type) {
              margin-left: 1.5rem;
            }
          }
        }
      }

      &__content {
        margin-top: 3.5rem;
      }

      ${media.mobile`
        &__description {
          display: none;
        }

        &__tabs {
          ul {
            li {
              width: 50%;
            }
          }
        }

        &__content {
          margin-top: 2rem;
        }
      `}
    }
  `}
`;

const MyPageTemp: React.FC<IProps> = ({ page, children }) => (
  <PageTemp size={9}>
    <StyledComponent>
      <p className="my-page__description">
        {page === 'shop' &&
          '*정확한 업체정보 제공은 고객에게 좋은 인상을 심어주는 동시에 매출 향상으로 이어질 수 있습니다.'}
        {page === 'user' && '*사업자 확인 및 정산에 필요한 정보입니다.'}
      </p>
      <div className="my-page__tabs">
        <ul>
          <li className={classnames({ active: page === 'shop' })}>
            <Link to={`${MY_PAGE}/shop`}>업체정보</Link>
          </li>
          {/* TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함 */}
          {/* <li className={classnames({ active: page === 'user' })}>
            <Link to={`${MY_PAGE}/user`}>회원정보</Link>
          </li> */}
        </ul>
      </div>
      <div className="my-page__content">{children}</div>
    </StyledComponent>
  </PageTemp>
);

export default MyPageTemp;
