import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Sendbird from 'sendbird';

import { CONNECT_CHATTING } from 'core/consts/Path';
import { useAppContext } from 'core/context/appContext';
import { getChannel } from 'core/sdk/sendbird/groupChannel';

import PageTemp from 'components/template/pageTemp';
import ChattingDetailTemp from 'components/connect/chatting/chattingDetailTemp';
import ChattingListCont from 'containers/connect/chatting/chattingListCont';
import ChattingEstCont from 'containers/connect/chatting/chattingEstCont';
import ChattingDetailCont from 'containers/connect/chatting/chattingDetailCont';
import { IContProp } from './interface';

const { useState, useMemo, useEffect, useCallback } = React;

const ChattingContainer: React.FC = () => {
  const history = useHistory();
  const channelUrl = (useParams() as any).channelUrl || '';

  const {
    state: { sendbirdConnection, sendbirdId },
  } = useAppContext();

  const [channel, setChannel] = useState<Sendbird.GroupChannel | null>(null);

  // 채팅에 참여중인 유저
  const user: Sendbird.Member | null = useMemo(() => {
    // 오픈 채팅방에 있는 멤버중 샵을 제외한 멤버가 유저
    return (
      channel?.members?.filter(
        ({ userId }: any) => userId !== sendbirdId,
      )?.[0] || null
    );
  }, [sendbirdId, channel]);

  // 채팅 목록으로 이동
  const handleLinkToList = useCallback(() => {
    history.replace(CONNECT_CHATTING);
  }, [history]);

  // 채팅 상세로 이동
  const handleLinkToDetail = useCallback(
    channelUrl => {
      history.push(`${CONNECT_CHATTING}/${channelUrl}`);
    },
    [history],
  );

  const dataSource: IContProp = {
    channel,
    user,
    onLinkToList: handleLinkToList,
    onLinkToDetail: handleLinkToDetail,
  };

  // 채널 url이 변경될 때마다 채널을 조회
  useEffect(() => {
    if (!channelUrl) {
      setChannel(null);
      return;
    }

    (async () => {
      const channelRes: any = await getChannel(sendbirdConnection, channelUrl);
      setChannel(channelRes);

      // 채널에 대한 읽음 처리
      channelRes.markAsRead();
    })();
  }, [sendbirdConnection, channelUrl]);

  // 채팅 목록과 상세를 같이 보여 주기 때문에, Switch는 넣지 않는다.
  return (
    <>
      {/* 채팅 목록 */}
      <ChattingListCont {...dataSource} />

      {/* 채팅 상세 */}
      {!!channel && (
        <PageTemp.RightSection padding={false} onClose={handleLinkToList}>
          <ChattingDetailTemp>
            {/* 유저의 견적 요청 */}
            <ChattingEstCont {...dataSource} />

            {/* 채팅방 상세 */}
            <ChattingDetailCont {...dataSource} />
          </ChattingDetailTemp>
        </PageTemp.RightSection>
      )}
    </>
  );
};

export default ChattingContainer;
