import * as React from 'react';
import Flexbox from 'elements/ui/flexbox';
import Section from 'elements/ui/section';
import ServiceLabel from 'elements/helper/serviceLabel';
import ReviewLabel from 'elements/helper/reviewLabel';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

interface IProps {
  id: number;
  requestId: string;
  service: string;
  userCar: string;
  userName: string;
  photos: string[];
  review: string;
  score: string;
  quality: string;
  kindness: string;
  reliability: string;
  createdAt: string;
  onShowOriginImage: (imgSrc: string) => void;
}

const Component = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    padding: 2rem 2.25rem;
    position: relative;
    border: 1px solid ${colors.border};
    border-bottom-width: 0;

    &:nth-child(odd) {
      background-color: ${colors.gray9};
    }

    &:first-child {
      border-top-left-radius: ${sizes.borderRadius};
      border-top-right-radius: ${sizes.borderRadius};
    }

    &:last-child {
      border-bottom: 1px solid ${colors.border};
      border-bottom-left-radius: ${sizes.borderRadius};
      border-bottom-right-radius: ${sizes.borderRadius};
    }

    time {
      font-size: 0.85rem;
      color: ${colors.gray3};
    }

    .review-item {
      &__result {
        width: 250px;
        margin-left: 2rem;
        padding-left: 2rem;
        border-left: 1px solid ${colors.gray6};
      }

      &__oid {
        font-size: 1.05rem;
        font-weight: 600;
      }

      &__user-car {
        color: ${colors.gray2};
      }

      &__content {
        > div {
          padding-left: 0.75rem;
          border-left: 3px solid ${colors.gray5};
          font-size: 0.925rem;
          word-break: break-all;
        }
      }

      &__images {
        margin-bottom: 1rem;

        span {
          width: 3rem;
          height: 3rem;
          margin-right: 0.25rem;
          border-radius: ${sizes.borderRadius};
          overflow: hidden;
          transition: opacity 500ms ease;

          img {
            width: 100%;
            height: 100%;
          }

          &:hover {
            opacity: 0.65;
            cursor: pointer;
          }
        }
      }

      &__grades {
        dl {
          padding: 0.15rem 0;
          display: flex;
          align-items: center;
          font-size: 0.925rem;
        }

        dt {
          width: 80px;
        }

        dd {
          flex: 1;
          display: flex;
          align-items: center;

          > strong {
            width: 32px;
            font-weight: 800;
          }

          > div {
            flex: 1;
          }
        }
      }
    }

    ${media.mobile`
      .review-item {
        flex-direction: column;

        &__result {
          width: 100%;
          margin-top: 1.5rem;
          padding-top: 1.5rem;
          margin-left: 0;
          padding-left: 0;
          border-top: 1px solid ${colors.gray6};
          border-left: none;
        }
      }
    `}
  `}
`;

const GradeBar = styled.div<{ value: number }>`
  ${({ value, theme: { colors } }) => css`
    height: 5px;
    position: relative;
    border-radius: 5px;
    background-color: ${colors.gray5};
    overflow: hidden;

    &:before {
      content: '';
      width: ${value}%;
      height: 100%;
      position: absolute;
      background-color: ${colors.primary};
    }
  `}
`;

const ReviewItem: React.FC<IProps> = ({
  id,
  requestId,
  service,
  userCar,
  userName,
  photos,
  review,
  score,
  quality,
  kindness,
  reliability,
  createdAt,
  onShowOriginImage,
}) => {
  return (
    <Component>
      <Flexbox className="review-item">
        <Flexbox.Item flex={1}>
          <Section b={0.5}>
            <Flexbox>
              <Section r={0.35}>
                <ServiceLabel type={service}></ServiceLabel>
              </Section>
              <Section>
                <ReviewLabel isGood={score === '100'} />
              </Section>
            </Flexbox>
          </Section>
          <Section t={0.5} b={0.5}>
            <Flexbox alignItems="center">
              <span className="mr-2 review-item__oid">[{requestId}]</span>
              {userName && <span className="mr-2">{userName}</span>}
              <span className="review-item__user-car">{userCar}</span>
            </Flexbox>
          </Section>
          <Section className="review-item__content" t={0.5}>
            <div>{review}</div>
          </Section>
          <Section t={1.25}>
            <time>{createdAt}</time>
          </Section>
        </Flexbox.Item>
        <Flexbox.Item className="review-item__result">
          {!!photos && (
            <Flexbox className="review-item__images">
              {photos.map(src => (
                <span key={src}>
                  <img
                    src={src}
                    alt=""
                    onClick={() => onShowOriginImage(src)}
                  />
                </span>
              ))}
            </Flexbox>
          )}
          <div className="review-item__grades">
            {[
              {
                label: '전문성',
                value: quality,
              },
              {
                label: '친절도',
                value: kindness,
              },
              {
                label: '상담 신뢰도',
                value: reliability,
              },
            ].map(({ label, value }, index) => (
              <dl key={`${id}-${index}`}>
                <dt>{label}</dt>
                <dd>
                  <strong>{(Number(value) / 20).toFixed(1)}</strong>
                  <GradeBar value={Math.floor(Number(value))} />
                </dd>
              </dl>
            ))}
          </div>
        </Flexbox.Item>
      </Flexbox>
    </Component>
  );
};

export default ReviewItem;
