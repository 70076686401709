import Sendbird from 'sendbird';

export const getChannel = (
  connection: Sendbird.SendBirdInstance,
  channelUrl: string,
) =>
  new Promise((resolve, reject) => {
    connection.GroupChannel.getChannel(channelUrl, (response, error) => {
      if (error) {
        reject(error);
      }
      resolve(response);
    });
  });
