import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styles/Styled';

interface IProps {
  imageSrc: string;
  onClose?: () => void;
}

const { useState, useEffect, useMemo, useCallback } = React;
const $rootElement = document.getElementById(
  'origin-image-root',
) as HTMLDivElement;

const Component = styled.div<{ active: boolean }>`
  ${({ active }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    opacity: 0;
    transition: opacity 300ms ease;

    > div {
      max-width: 100%;
      display: flex;
      background-color: white;

      img {
        max-width: 100%;
      }
    }

    ${active &&
      css`
        opacity: 1;
      `}
  `}
`;

const OriginImage: React.FC<IProps> = ({ imageSrc, onClose }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!imageSrc) return;
    setTimeout(() => {
      setIsMounted(true);
    });
  }, [imageSrc]);

  const handleClose = useCallback(() => {
    if (!onClose) {
      return undefined;
    }
    setIsMounted(false);
    setTimeout(() => {
      onClose();
    }, 500);
  }, [onClose]);

  const element = useMemo(() => {
    return !!imageSrc ? (
      <Component active={isMounted} onClick={handleClose}>
        <div>
          <img src={imageSrc} alt="" />
        </div>
      </Component>
    ) : null;
  }, [imageSrc, isMounted, handleClose]);

  return ReactDOM.createPortal(element, $rootElement);
};

export default OriginImage;
