export const askNotificationPermission = async () => {
  if (!('Notification' in window)) return null;

  // 알림 허용이 되지 않은 경우 권한 요청
  if (window.Notification.permission !== 'granted') {
    return await window.Notification.requestPermission();
  }

  return 'granted';
};

export const notificationEvent = async ({
  title,
  body,
  onClick,
}: {
  title: string;
  body: string;

  onClick?: Function;
}) => {
  const notiPermission = await askNotificationPermission();
  if (notiPermission !== 'granted' || notiPermission === null) {
    // 노티를 받을 수 없거나, 받지 않는 경우
    return;
  }

  const notification = new Notification(title, { body });
  notification.onclick = () => {
    window.focus();
    if (onClick) {
      onClick();
    }
  };
};
