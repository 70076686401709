import * as React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import toastr from 'toastr';

import ToggleArrowDown from 'reusable/toggle-arrow-down.svg';

import styled, { css } from 'styles/Styled';

import { MemoMeta } from 'core/interface/MemoMeta';
import { PossibleInsuranceCompaniesObject } from 'core/interface/PossibleInsuranceCompaniesObject';
import { MultipleInsuranceEstimatesMetaObject } from 'core/interface/MultipleInsuranceEstimates';

interface IProps {
  memoMetaObject?: MemoMeta;
  setMemoMetaObject?: Function;
  prevInsuranceCompany?: string;
  prevInsuranceCompanyPlaceholder?: string;
  type: string;
  possibleInsuranceCompanies: PossibleInsuranceCompaniesObject[];
  multipleInsuranceCompanyMeta?: MultipleInsuranceEstimatesMetaObject;
  multipleInsuranceEstimatesMetaObjectArray?: MultipleInsuranceEstimatesMetaObject[];
  setMultipleInsuranceEstimatesMetaObjectArray?: (
    object: MultipleInsuranceEstimatesMetaObject[],
  ) => void;
  insuNameFromServer?: string;
  estimationsSentOut?: boolean;
  channelUrl?: string;
}

const { useState, useEffect, useCallback } = React;

const StyledComponent = styled.div<{ type: string }>`
  ${({ type }) => css`
    display: ${type === 'multiple-inputs-insurance-company-dropdown'
      ? 'inline-block'
      : 'block'};
    width: ${type === 'multiple-inputs-insurance-company-dropdown'
      ? '40%'
      : '100%'};
    vertical-align: ${type === 'multiple-inputs-insurance-company-dropdown'
      ? 'super'
      : 'baseline'};
    .dropdown {
      .dropdown-menu.show {
        font-size: 1.05rem;
        button {
          color: black;
        }
      }
      button {
        font-weight: 500;
        font-size: 1.05rem;
        padding: 0.8rem;
        width: 100%;
        text-align: left;
        border-color: #ced4da;
        color: #b5b5b5;
        background-color: white !important;
        .toggle-icon {
          position: absolute;
          top: 47%;
          transform: translateY(-40%);
          right: 3%;
        }
        &::after {
          display: none;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(46, 151, 250, 0.25);
        }
        &:active {
          color: #b5b5b5;
          background-color: white !important;
        }
        &.black-text {
          color: black;
        }
        &.dimmed {
          color: #495057;
          background-color: #f9f9f9 !important;
        }
      }
    }
  `}
`;

const MemoMetaInsuranceCompanyDropDown: React.FC<IProps> = ({
  memoMetaObject,
  setMemoMetaObject,
  prevInsuranceCompany,
  prevInsuranceCompanyPlaceholder,
  type,
  possibleInsuranceCompanies,
  multipleInsuranceCompanyMeta,
  multipleInsuranceEstimatesMetaObjectArray,
  setMultipleInsuranceEstimatesMetaObjectArray,
  insuNameFromServer,
  estimationsSentOut,
  channelUrl,
}) => {
  const [dropdownClass, updateDropdownClass] = useState('');
  const [prevInsuranceIdValue, setPrevInsuranceIdValue] = useState();
  useEffect(() => {
    let classToSet = 'dropdown-toggle';
    const blackTextConditionOne =
      memoMetaObject?.cdCarInsuranceCorp?.insuName || prevInsuranceIdValue;
    const blackTextConditionTwo =
      multipleInsuranceCompanyMeta &&
      multipleInsuranceCompanyMeta?.insuName !== '선택하기';
    if (blackTextConditionOne || blackTextConditionTwo) {
      updateDropdownClass('dropdown-toggle black-text');
      classToSet = `${classToSet} black-text`;
      if (estimationsSentOut) {
        classToSet = `${classToSet} dimmed`;
      }
    }
    updateDropdownClass(classToSet);
  }, [
    updateDropdownClass,
    memoMetaObject,
    prevInsuranceIdValue,
    multipleInsuranceCompanyMeta,
    estimationsSentOut,
  ]);

  const getInsuranceCompanies = useCallback(async () => {
    const processDropdownClick = (e: any) => {
      e.preventDefault();
      const insuranceCompanyNameString = e.target.innerHTML;
      const prevInsuranceId = e.target.getAttribute('data-index');
      if (type === 'memo-meta') {
        if (setMemoMetaObject) {
          setPrevInsuranceIdValue(insuranceCompanyNameString);
          setMemoMetaObject({
            ...memoMetaObject,
            prevInsuranceId,
          });
        }
      } else if (type === 'multiple-inputs-insurance-company-dropdown') {
        if (
          multipleInsuranceCompanyMeta &&
          multipleInsuranceEstimatesMetaObjectArray &&
          setMultipleInsuranceEstimatesMetaObjectArray
        ) {
          if (
            multipleInsuranceEstimatesMetaObjectArray.some(
              object => object.insuName === insuranceCompanyNameString,
            )
          ) {
            toastr.error(
              '이미 선택된 보험사 입니다. 확인 후 다시 선택 바랍니다.',
            );
          } else {
            const { indexClicked } = multipleInsuranceCompanyMeta;
            const newMultipleInsuranceCompanyMeta = {
              ...multipleInsuranceCompanyMeta,
              insuName: insuranceCompanyNameString,
              insuId: parseInt(prevInsuranceId, 10),
            };
            const newMultipleInsuranceEstimatesMetaObjectArray = multipleInsuranceEstimatesMetaObjectArray.slice();
            newMultipleInsuranceEstimatesMetaObjectArray[
              indexClicked
            ] = newMultipleInsuranceCompanyMeta;
            setMultipleInsuranceEstimatesMetaObjectArray(
              newMultipleInsuranceEstimatesMetaObjectArray,
            );
          }
        }
      }
    };
    const dropdownItemsMarkup = possibleInsuranceCompanies.map(
      (insuranceCompany: any) => {
        return (
          <DropdownItem
            key={insuranceCompany.insuId}
            data-index={insuranceCompany.insuId}
            onClick={processDropdownClick}
          >
            {insuranceCompany.insuName}
          </DropdownItem>
        );
      },
    );
    setDropdownMarkup(dropdownItemsMarkup);
  }, [
    memoMetaObject,
    setMemoMetaObject,
    possibleInsuranceCompanies,
    type,
    multipleInsuranceCompanyMeta,
    multipleInsuranceEstimatesMetaObjectArray,
    setMultipleInsuranceEstimatesMetaObjectArray,
  ]);

  useEffect(() => {
    getInsuranceCompanies();
  }, [getInsuranceCompanies]);

  const [dropdownMarkup, setDropdownMarkup] = useState<JSX.Element[]>([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const [dropdownValue, setDropdownValue] = useState<string | undefined>('');
  useEffect(() => {
    let valueToSet;
    if (type === 'memo-meta') {
      valueToSet =
        prevInsuranceIdValue ||
        memoMetaObject?.cdCarInsuranceCorp?.insuName ||
        prevInsuranceCompanyPlaceholder;
    } else if (type === 'multiple-inputs-insurance-company-dropdown') {
      valueToSet = insuNameFromServer || multipleInsuranceCompanyMeta?.insuName;
    }
    setDropdownValue(valueToSet);
  }, [
    setDropdownValue,
    prevInsuranceIdValue,
    memoMetaObject,
    prevInsuranceCompanyPlaceholder,
    insuNameFromServer,
    multipleInsuranceCompanyMeta,
    type,
  ]);

  return (
    <StyledComponent type={type}>
      <label htmlFor="prevInsuranceCompany">{prevInsuranceCompany}</label>
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className={dropdownClass}
          disabled={!!channelUrl && estimationsSentOut}
        >
          {dropdownValue}
          {!insuNameFromServer && (
            <img
              className="toggle-icon"
              src={ToggleArrowDown}
              alt="toggle-arrow-down-icon"
            />
          )}
        </DropdownToggle>
        <DropdownMenu>{dropdownMarkup}</DropdownMenu>
      </Dropdown>
    </StyledComponent>
  );
};

export default MemoMetaInsuranceCompanyDropDown;
