import * as React from 'react';
import MyShopSample from 'components/myPage/myShopSample';
import MyShopForm from 'components/myPage/myShopForm';
import Flexbox from 'elements/ui/flexbox';
import { IContProp } from 'containers/myPage/myPageCont/interface';

const ShopPage: React.FC<IContProp> = ({ defaultForm, errors, onChange }) => {
  return (
    <Flexbox>
      <Flexbox.Item>
        <MyShopSample />
      </Flexbox.Item>
      <Flexbox.Item flex={1}>
        <MyShopForm
          errors={errors}
          defaultForm={defaultForm}
          onChange={onChange}
        />
      </Flexbox.Item>

      {/**
       * TODO
       *
       * 1차 오픈에서는 업체 정보 수정을 할 수 없기 때문에
       * 이 레이어로 접근을 막는다.
       */}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 9999,
          left: 0,
          right: 0,
        }}
      />
    </Flexbox>
  );
};

export default ShopPage;
