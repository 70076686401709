import { css } from 'styles/Styled';

const MOBILE_NAV_WIDTH = '250px';

export default (active: boolean) => css`
  width: 100%;
  padding-top: 0;
  z-index: 100;

  .background {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: opacity 500ms ease;
  }

  .wrap {
    width: ${MOBILE_NAV_WIDTH};
    position: relative;
    z-index: 2;
    transform: translateX(-${MOBILE_NAV_WIDTH});
    transition: transform 200ms ease-in-out;

    .close-button {
      display: block;
      position: absolute;
      z-index: 10;
      top: 0.5rem;
      left: 0.5rem;
      border: none;
      background-color: transparent;
      font-size: 1.5rem;
      color: white;
    }

    .nav {
      width: 100%;
    }

    .sub-nav {
      display: block;
    }

    ul {
      width: 100%;

      li hr {
        width: 100% !important;
      }
    }
  }

  ${active &&
    css`
      .background {
        opacity: 1;
      }

      .wrap {
        transform: translateX(0);
      }
    `}
`;
