import * as React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';

import { MY_PAGE } from 'core/consts/Path';
import { useAppContext } from 'core/context/appContext';
import { cardocServiceKeys } from 'core/data/cardocService';

import useValidation from 'core/hooks/form/UseValidation';

import MyPageTemp from 'components/myPage/myPageTemp';
import ShopPage from 'pages/myPage/shopPage';
import { IContProp } from './interface';
import { parseShopData } from './mypageCont.functions';

// TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
// import toastr from 'toastr';
// import useFetch from 'core/hooks/UseFetch';
// import { updatePassword } from 'core/api/account';
// import UserPage from 'pages/myPage/userPage';
// import PasswordModal from 'components/myPage/passwordModal';
// import SubmitButton from 'components/myPage/submitButton';

const { useRef, useState, useMemo, useCallback } = React;

const MyPageCont: React.FC = () => {
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // const history = useHistory();
  const { type } = useParams() as any;

  const {
    state: { shop, connectShop },
  } = useAppContext();

  // 비밀번호 변경 API
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // const [fetchUpdatePw, updatePwPending, , updatePwError] = useFetch(
  //   updatePassword,
  //   null,
  // );

  const [state, setState] = useState({
    isShowPwModal: false,
    isSubmitted: false,
    phoneNumberErrors: {},
  });

  const stateRef = useRef<any>({
    form: parseShopData(shop, connectShop),
  });

  // 폼 유효성 체크
  const [onValidate, formErrors] = useValidation(
    {
      shopName: {
        rule: ['required', 'shopName'],
        msg: {
          required: '업체명을 입력해주세요.',
          shopName:
            '업체명에는 한글, 영문, 숫자, &, 괄호만 사용하실 수 있습니다.',
        },
      },
      address: {
        rule: ['required'],
        msg: {
          required: '주소를 입력해주세요.',
        },
      },
    },
    {},
  );

  // 에러 객체 머지
  const errors = useMemo(() => {
    return {
      ...formErrors,
      ...state.phoneNumberErrors,
    };
  }, [formErrors, state.phoneNumberErrors]);

  // 폼 입력
  const handleChange = useCallback(
    nextState => {
      stateRef.current.form = {
        ...stateRef.current.form,
        ...nextState,
      };

      // 폼 입력시 validation check
      onValidate(stateRef.current.form);

      // 각 서비스의 대표번호가 입력되었는지 여부
      const afterPhoneNumberErrors: any = {};
      cardocServiceKeys.forEach(key => {
        if (!stateRef?.current?.form?.telNumbers?.[key]?.[0]) {
          afterPhoneNumberErrors[key] = '대표번호를 입력해주세요.';
        }
      });
      setState({ ...state, phoneNumberErrors: afterPhoneNumberErrors });
    },
    [state, onValidate],
  );

  // 비밀번호 변경 모달 토글
  const handleTogglePwModal = useCallback(() => {
    setState({
      ...state,
      isShowPwModal: !state.isShowPwModal,
    });
  }, [state]);

  // 비밀번호 변경
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // const handleUpdatePw = useCallback(
  //   (oldPassword, password) => {
  //     fetchUpdatePw({
  //       loginId: shop.loginId,
  //       password,
  //       oldPassword,
  //     });
  //   },
  //   [shop, fetchUpdatePw],
  // );

  // 저장하기
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // const handleSubmit = useCallback(async () => {
  //   setState({
  //     ...state,
  //     isSubmitted: true,
  //   });

  //   if (
  //     Object.keys(formErrors).length !== 0 ||
  //     Object.keys(state.phoneNumberErrors).length !== 0
  //   ) {
  //     toastr.error('필수값을 입력해주세요.');
  //     history.replace('shop');
  //     return;
  //   }

  //   if (stateRef.current.form.description.length > 500) {
  //     toastr.error('업체 소개글을 500자 이하로 입력해주세요.');
  //     return;
  //   }

  //   toastr.success('저장되었습니다.');
  // }, [history, state, formErrors]);

  // 비밀번호 변경 실패
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // useEffect(() => {
  //   if (!updatePwError) return;
  //   toastr.error(
  //     updatePwError?.response?.data?.error?.error_msg ||
  //       '비밀번호 변경에 실패했습니다. 다시 시도해주시기 바랍니다.',
  //   );
  // }, [updatePwError]);

  const dataSource: IContProp = {
    defaultForm: stateRef.current.form,
    errors: state.isSubmitted ? errors : {},
    banks: [],
    onTogglePwModal: handleTogglePwModal,
    onChange: handleChange,
  };

  return (
    <MyPageTemp page={type}>
      <Switch>
        {/* 업체정보 */}
        <Route
          path={`${MY_PAGE}/shop`}
          render={() => <ShopPage {...dataSource} />}
        />

        {/* TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함 */}
        {/* <Route
          path={`${MY_PAGE}/user`}
          render={() => <UserPage {...dataSource} />}
        /> */}
      </Switch>

      {/* 비밀번호 변경 모달 */}
      {/* TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함 */}
      {/* <PasswordModal
        show={state.isShowPwModal}
        pending={updatePwPending}
        onClose={handleTogglePwModal}
        onSubmit={handleUpdatePw}
      /> */}

      {/* <SubmitButton onSubmit={handleSubmit} /> */}
    </MyPageTemp>
  );
};

export default MyPageCont;
