import * as React from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label as ReactstrapLabel,
  FormText,
} from 'reactstrap';
import styled, { css } from 'styles/Styled';
import Label from 'elements/helper/label';
import Section from 'elements/ui/section';
import Flexbox from 'elements/ui/flexbox';
import EstimationStatusLabel from 'components/connect/estimation/estimationStatusLabel';
import InsuranceCertificateModal from 'reusable/InsuranceCertificateModal';

import Icon from 'elements/helper/icon';

import toastr from 'toastr';

import UploadIcon from './ico-upload.svg';
import IconCloseRed from './ico-close-red.svg';

import ChatInsuranceCompanyDropDown from './ChatInsuranceCompanyDropDown';
import SelfOrAutoLiabilityDropDown from './SelfOrAutoLiabilityDropDown';

import { numberFormat } from 'core/function/Formatter';

import useFetch from 'core/hooks/UseFetch';
import {
  getInsuranceCertificateTempUrl,
  uploadFinalizedInsuranceDetails,
} from 'core/api/estimation';

import koStrings from '../../../../meta/ko';

import { MemoMeta } from 'core/interface/MemoMeta';
interface IProps {
  requestId: string;
  status: string;
  category: string;
  carBrand: string;
  carModel: string;
  suggestedAmount: string;
  answers: string;
  inquiries: { type: string; question: string; answer: string }[];
  userName: string;
  memoMeta: MemoMeta;
  finalizedInsuranceDetails: any;
  connectEstimationId: number;
  responsibility: string;
  createdAt: string;
  updateMemoMetaThenUpdateAssignee: (
    estimationId: number,
    memoDescription: string,
    assignee: string,
    indexToUpdate: number,
  ) => void;
  indexToUpdate: number;
}

const {
  chatEstimateItem: {
    memo: { title, memoPlaceholder, maxMemoLength },
    assignee: { title: assigneeTitle, assigneePlaceholder },
    saveButton: { save, saving },
  },
  chattingDetailPage: {
    putFinalizedInsuranceRegistrationDetailsModal: {
      title: chattingDetailPageTitle,
      insuranceCompany,
      insuranceCompanyPlaceholder,
      insuranceRegistrationDate,
      insuranceRegistrationDatePlaceholder,
      insuranceAmount,
      insuranceAmountPlaceholder,
      propertyDamageLiabilityTitle,
      propertyDamageLiabilityPlaceholder,
      bodilyInjuryCoverage,
      bodilyInjuryCoveragePlaceholder,
      bodilyInjuryCoverageDropdown: { auto, self },
      uploadArea: { title: uploadAreaTitle, dragAreaText, buttonText },
      uploadButtonText,
      isUploadingButtonText,
      toastr: {
        uploadSuccess,
        uploadFail,
        tempFileUrlSuccess,
        tempFileUrlError,
      },
    },
  },
} = koStrings;

const { useState, useEffect, useRef } = React;
const Component = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    > label {
      padding: 1rem;
      display: block;
      border-bottom: 1px solid ${colors.border};
      transition: background-color 500ms ease;
    }

    time {
      font-size: 0.825rem;
      color: ${colors.gray3};
    }

    hr {
      margin: 1rem 0;
      border-top: 1px solid ${colors.gray5};
      border-bottom: none;
    }
    .estimation-request__assignee {
      margin-bottom: 1.2rem;
      background-color: transparent;
      border: solid 1px #dddddd;
      font-weight: 500;
      color: #666666;
      &:focus{
        background-color: white;
      }
    }

    input {
      font-size: 0.925rem;
      height: auto;
      padding: 0.7rem 0.65rem 0.9rem 10px;
      &::placeholder {
        font-size: 0.925rem;
      }
    }

    .input-with-counter {
      position: relative;
      input {
        background-color: transparent;
        border: solid 1px #dddddd;
        font-weight: 500;
        color: #666666;
        &:focus{
          background-color: white;
        }
      }
    }

    .input-counter {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      color: ${colors.linkWater};
      font-size: 85%;
    }

    .dl-chat-qna {
      margin-bottom: 1.2rem;
    }

    dl {
      margin-bottom: 0.75rem;
      font-size: 0.925rem;

      textarea {
        font-size: 0.925rem;
      }

      dt {
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      dd {
        p {
          margin-top: 0.25rem;
          color: ${colors.gray1};

          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
      }
    }

    .chatting-est-item {
      &__req-id {
        font-weight: 700;
      }

      &__user-car {
        margin-left: 0.25rem;
        font-weight: 700;
      }

      &__user-name {
        margin-left: 0.25rem;
        font-size: 0.925rem;
        font-weight: 400;
        color: ${colors.gray2};
      }

      &__message {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: ${colors.gray1};
      }

      &__amount {
        font-size: 1.05rem;
        font-weight: 700;
      }

      &__detail {
        display: none;
      }

      &__qna {
        padding: 1rem;
        border: 1px solid ${colors.gray5};
        border-radius: ${sizes.borderRadius};
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked {
        + label {
          background-color: ${colors.gray7};

          .chatting-est-item__detail {
            display: block;
          }
        }
      }
    }

    &:hover {
      > label {
        background-color: ${colors.gray7};
        cursor: pointer;
      }
    }
    .request-label{
      padding-bottom: 35px;
    }

    button {
      margin-top: 1.2rem;
      font-size: 0.925rem;
      &.save-inchatMeta-button {
        margin-right: 6px;
      }
        }
        .icon-certificate {
          height: 1.6rem;
          vertical-align: bottom;
        }
      } */
    }
    .insurance-certificate-modal-button {
      border: solid 1px #d0d0d0;
      background-color: #f2f2f2;
      color: #666666;
      &:active{
        border: solid 1px #d0d0d0;
        background-color: white !important;
        color: #666666 !important;
      }
      &:hover{
        border-color: #9e9e9e;
      }

    .save-inchatMeta-button {
      margin-top: 1.2rem;
    }
  `}
`;

const ChattingEstItem: React.FC<IProps> = ({
  requestId,
  status,
  category,
  carBrand,
  carModel,
  suggestedAmount,
  answers,
  inquiries,
  userName,
  memoMeta,
  finalizedInsuranceDetails,
  connectEstimationId,
  responsibility,
  createdAt,
  updateMemoMetaThenUpdateAssignee,
  indexToUpdate,
}) => {
  const [formInput, setFormInput] = useState<FinalizedInsuranceForm>({});
  const [
    disableSubmitInsuranceButton,
    toggleDisableSubmitInsuranceButton,
  ] = useState(false);

  useEffect(() => {
    if (finalizedInsuranceDetails) {
      const {
        cdCarInsuranceCorp: { id: insuranceId },
        insuranceRenewalDate,
        price,
        propertyDamageLiability,
        cdBodilyInjuryCoverage: { id: bodilyInjuryCoverageId },
        insuranceCertificate,
      } = finalizedInsuranceDetails;
      if (insuranceCertificate) {
        setFileAttached(true);
      }
      setFormInput({
        insuranceId,
        insuranceRenewalDate: insuranceRenewalDate.slice(0, 10),
        price,
        propertyDamageLiability,
        bodilyInjuryCoverageId,
        insuranceCertificate,
      });
    }
  }, [finalizedInsuranceDetails]);

  const [memoDescription, setMemoDescription] = useState(
    memoMeta.description || '',
  );
  const [memoCount, setMemoCount] = useState(0);
  const [assignee, setAssignee] = useState(responsibility || '');
  const [disableButton, toggleDisableButton] = useState(false);

  useEffect(() => {
    setMemoCount(memoMeta?.description?.length || 0);
  }, [memoMeta]);

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    const name = e.target.name;
    switch (name) {
      case 'description':
        setMemoCount(value.length);
        setMemoDescription(value);
        break;
      case 'assignee':
        setAssignee(value);
        break;
      default:
    }
  };

  const handleButtonClick = async () => {
    try {
      toggleDisableButton(true);
      await updateMemoMetaThenUpdateAssignee(
        connectEstimationId,
        memoDescription,
        assignee,
        indexToUpdate,
      );
    } catch (err) {
      // TODO: Comment to prevent `no-empty` eslint message.
      // Figure out whether to display or ignore error.
    } finally {
      toggleDisableButton(false);
    }
  };

  const processKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
  };

  const toggle = () => setModal(!modal);

  interface FinalizedInsuranceForm {
    insuranceId?: number;
    insuranceIdStringValue?: string;
    insuranceRenewalDate?: string;
    price?: number;
    propertyDamageLiability?: number;
    bodilyInjuryCoverageId?: number;
    bodilyInjuryCoverageIdStringValue?: string;
    file?: { name: string };
    insuranceCertificateTempUrl?: { fileUrl: string };
    insuranceCertificate?: string;
  }

  const [isUploadingDetails, toggleIsUploadingDetails] = useState(false);

  const submitForm = async () => {
    toggleIsUploadingDetails(true);
    let postRequest = !formInput.insuranceCertificate;
    let newObject = {
      insuranceId: formInput.insuranceId?.toString(),
      insuranceRenewalDate: formInput.insuranceRenewalDate?.toString(),
      propertyDamageLiability: formInput.propertyDamageLiability?.toString(),
      price: formInput.price?.toString(),
      bodilyInjuryCoverageId: formInput.bodilyInjuryCoverageId?.toString(),
      estimationId: connectEstimationId,
      insuranceCertificate:
        formInput.insuranceCertificate ||
        formInput.insuranceCertificateTempUrl?.fileUrl.toString(),
    };
    const response = await onUploadFinalizedInsuranceDetails(
      newObject,
      postRequest,
    );
    if (response !== false) {
      toastr.success(uploadSuccess);
      toggle();
    } else {
      toastr.error(uploadFail);
    }
    toggleIsUploadingDetails(false);
  };

  useEffect(() => {
    if (
      formInput.insuranceId &&
      formInput.insuranceRenewalDate &&
      !(
        formInput.insuranceRenewalDate.length > 0 &&
        formInput.insuranceRenewalDate.length < 10
      ) &&
      formInput.propertyDamageLiability &&
      formInput.price &&
      formInput.bodilyInjuryCoverageId &&
      (formInput.insuranceCertificate ||
        formInput.file?.name ||
        formInput.insuranceCertificateTempUrl?.fileUrl)
    ) {
      toggleDisableSubmitInsuranceButton(false);
    } else {
      toggleDisableSubmitInsuranceButton(true);
    }
  }, [formInput]);

  const onInputChange = (e: any) => {
    let { name, value } = e.target;
    let tempValueToCheck = '';
    let isValidInput = false;
    switch (name) {
      case 'insuranceRenewalDate':
        value = value.replace(/[-\s]/g, '');
        tempValueToCheck = value.replace(/-/g, '');
        if (!Number.isNaN(Number(tempValueToCheck))) {
          value = value.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
          isValidInput = true;
          if (value.length > 0 && value.length < 10) {
            toggleDisableSubmitInsuranceButton(true);
          } else {
            toggleDisableSubmitInsuranceButton(false);
          }
        }
        break;
      // Explicit fall-through for numeric value input.
      case 'price':
      case 'propertyDamageLiability':
        value = value.replace(/[^0-9]/g, '');
        if (value === '0') {
          value = '';
        }
        isValidInput = true;
        break;
      default:
    }
    if (isValidInput) {
      setFormInput({
        ...formInput,
        [name]: value,
      });
    }
  };

  const hiddenFileUploadRef = useRef<HTMLInputElement>(null);
  const chooseFileToUpload = () => {
    const cur = hiddenFileUploadRef.current;
    if (cur) {
      cur.click();
    }
  };

  const [onGetInsuranceCertificateTempUrl] = useFetch(
    getInsuranceCertificateTempUrl,
    null,
  );
  const [onUploadFinalizedInsuranceDetails] = useFetch(
    uploadFinalizedInsuranceDetails,
    null,
  );

  const fileSelectHandler = (e: any) => {
    const { files } = e.target;
    const file = files[0];
    handleAttachment(file);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    const file = files[0];
    handleAttachment(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleAttachment = async (file: any) => {
    let formData = new FormData();
    formData.append('file', file);

    const insuranceCertificateTempUrl = await onGetInsuranceCertificateTempUrl(
      formData,
    );

    if (insuranceCertificateTempUrl) {
      setFormInput({
        ...formInput,
        file,
        insuranceCertificateTempUrl,
      });
      toastr.success(tempFileUrlSuccess);
      setFileAttached(true);
    } else {
      toastr.error(tempFileUrlError);
    }
  };

  const [attachButtonHTML, setAttachButtonHTML] = useState<{ __html: string }>(
    dragAreaText.beforeFile,
  );

  useEffect(() => {
    if (
      formInput.insuranceCertificate ||
      formInput.insuranceCertificateTempUrl?.fileUrl
    ) {
      setAttachButtonHTML(dragAreaText.beforeFile);
    } else {
      setAttachButtonHTML(dragAreaText.beforeFile);
    }
  }, [
    formInput.file,
    formInput.insuranceCertificate,
    formInput.insuranceCertificateTempUrl,
  ]);

  const undoFileAttach = () => {
    setFormInput({
      ...formInput,
      file: { name: '' },
      insuranceCertificateTempUrl: { fileUrl: '' },
      insuranceCertificate: '',
    });
    setFileAttached(false);
  };

  const [fileAttached, setFileAttached] = useState(false);

  const [previewImageUrl, setPreviewImageUrl] = useState<string | undefined>(
    '',
  );
  useEffect(() => {
    const { insuranceCertificate, insuranceCertificateTempUrl } = formInput;
    setPreviewImageUrl(
      insuranceCertificateTempUrl?.fileUrl || insuranceCertificate,
    );
  }, [formInput]);

  return (
    <Component>
      <Modal
        className="insurance-details-upload-modal"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>{chattingDetailPageTitle}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <ChatInsuranceCompanyDropDown
                insuranceCompanySavedString={
                  finalizedInsuranceDetails?.cdCarInsuranceCorp.insuName ||
                  formInput.insuranceIdStringValue
                }
                insuranceCompany={insuranceCompany}
                insuranceCompanyPlaceholder={insuranceCompanyPlaceholder}
                formInput={formInput}
                setFormInput={setFormInput}
              />
            </FormGroup>
            <FormGroup>
              <ReactstrapLabel for="insuranceRenewalDate">
                {insuranceRegistrationDate}
              </ReactstrapLabel>
              <Input
                type="text"
                maxLength={10}
                name="insuranceRenewalDate"
                value={formInput?.insuranceRenewalDate?.slice(0, 10)}
                onChange={onInputChange}
                placeholder={insuranceRegistrationDatePlaceholder}
              />
            </FormGroup>
            <FormGroup>
              <ReactstrapLabel for="price">{insuranceAmount}</ReactstrapLabel>
              <Input
                className="price-input"
                name="price"
                value={
                  formInput?.price
                    ? `${numberFormat(Number(formInput?.price))}원`
                    : ''
                }
                onKeyDown={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
                onChange={onInputChange}
                placeholder={insuranceAmountPlaceholder}
              />
            </FormGroup>
            <FormGroup>
              <ReactstrapLabel for="propertyDamageLiability">
                {propertyDamageLiabilityTitle}
              </ReactstrapLabel>
              <Input
                className="property-damage-liability-input"
                name="propertyDamageLiability"
                value={
                  formInput?.propertyDamageLiability
                    ? `${numberFormat(
                        Number(formInput?.propertyDamageLiability),
                      )}억원`
                    : ''
                }
                onKeyDown={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
                onChange={onInputChange}
                placeholder={propertyDamageLiabilityPlaceholder}
              />
            </FormGroup>
            <FormGroup>
              <SelfOrAutoLiabilityDropDown
                options={[auto, self]}
                typeOfBodilyInjuryCoverageSavedString={
                  finalizedInsuranceDetails?.cdBodilyInjuryCoverage
                    .description || formInput.bodilyInjuryCoverageIdStringValue
                }
                typeOfBodilyInjuryCoverage={bodilyInjuryCoverage}
                typeOfBodilyInjuryCoveragePlaceholder={
                  bodilyInjuryCoveragePlaceholder
                }
                formInput={formInput}
                setFormInput={setFormInput}
              />
            </FormGroup>
            <FormGroup>
              <ReactstrapLabel for="file">{uploadAreaTitle}</ReactstrapLabel>
              <input
                ref={hiddenFileUploadRef}
                hidden
                type="file"
                name="file"
                onChange={fileSelectHandler}
              />
              <FormText
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                color="muted"
              >
                <div className="file-drag-area">
                  {fileAttached && previewImageUrl && (
                    <>
                      <span className="upload-file-preview">
                        <img
                          className="upload-file-preview-image"
                          onClick={toggleNested}
                          src={previewImageUrl}
                          alt="upload file preview"
                        />
                        <img
                          src={IconCloseRed}
                          className="icon-close-red"
                          alt="icon-close-red"
                          onClick={undoFileAttach}
                        />
                      </span>
                      <InsuranceCertificateModal
                        isOpen={nestedModal}
                        toggle={toggleNested}
                        insuranceCertificateUrl={previewImageUrl}
                      />
                    </>
                  )}
                  <img
                    className="upload-icon"
                    src={UploadIcon}
                    alt="upload icon"
                  />
                  {formInput.insuranceCertificate ? (
                    <div className="upload-description-text">
                      기 등록 파일이 있습니다. 새로운 파일을 올리시려면
                    </div>
                  ) : formInput?.file?.name ? (
                    <span className="upload-description-text">
                      첨부된 파일명: {formInput?.file?.name}
                    </span>
                  ) : (
                    <br />
                  )}
                  <div
                    className="upload-description-text"
                    dangerouslySetInnerHTML={attachButtonHTML}
                  ></div>
                  <Button
                    className="upload-file-button"
                    color="primary"
                    size="lg"
                    onClick={chooseFileToUpload}
                  >
                    {buttonText}
                  </Button>
                </div>
              </FormText>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className="full-width-button"
            color={disableSubmitInsuranceButton ? 'secondary' : 'primary'}
            disabled={disableSubmitInsuranceButton || isUploadingDetails}
            size="lg"
            onClick={submitForm}
          >
            {isUploadingDetails ? isUploadingButtonText : uploadButtonText}
          </Button>
        </ModalFooter>
      </Modal>
      <input type="checkbox" id={requestId} />
      <label className="request-label" htmlFor={requestId}>
        <Section b={0.25}>
          <Flexbox>
            <Label className="mr-1" color="info">
              {category}
            </Label>
            <EstimationStatusLabel status={status} />
            <time className="ml-auto">{createdAt}</time>
          </Flexbox>
        </Section>
        <Section t={0.1} b={0.1}>
          <strong className="chatting-est-item__req-id">[{requestId}]</strong>{' '}
          <strong className="chatting-est-item__user-car">
            {carBrand} {carModel}
          </strong>{' '}
          <span className="chatting-est-item__user-name">{userName}님</span>
        </Section>
        <Section t={0.1} b={0.1}>
          <p className="chatting-est-item__message">{answers}</p>
        </Section>
        <Section t={0.1}>
          견적금액:{' '}
          <strong className="chatting-est-item__amount">
            {suggestedAmount}원
          </strong>
        </Section>
        <Section className="chatting-est-item__detail">
          <hr />
          <dl className="dl-chat-qna">
            <dt>견적요청 내용</dt>
            <dd>
              <div className="chatting-est-item__qna">
                {inquiries.map(({ type, question, answer }) => (
                  <React.Fragment key={type}>
                    <h6>{question}</h6>
                    <p>- {answer}</p>
                  </React.Fragment>
                ))}
              </div>
            </dd>
          </dl>
          <dl>
            <dt>{assigneeTitle}</dt>
            <dd>
              <Input
                className="estimation-request__assignee"
                name="assignee"
                onKeyDown={processKeyDown}
                placeholder={assigneePlaceholder}
                value={assignee}
                onChange={handleInputChange}
              />
            </dd>
            <dt>{title}</dt>
            <dd>
              <div className="input-with-counter">
                <Input
                  type="text"
                  name="description"
                  onKeyDown={processKeyDown}
                  placeholder={memoPlaceholder}
                  maxLength={maxMemoLength}
                  value={memoDescription}
                  onChange={handleInputChange}
                />
                <div className="input-counter">
                  {memoCount}/{30}
                </div>
              </div>
            </dd>
            <div className="text-right">
              <Button
                onClick={handleButtonClick}
                className="save-inchatMeta-button btn-lg"
                color="secondary"
                disabled={disableButton}
              >
                {disableButton ? saving : save}
              </Button>
              <Button
                className="insurance-certificate-modal-button btn-lg"
                type="button"
                onClick={toggle}
              >
                <Icon iconName="fa fa-file-alt" /> <span>완료하기</span>
              </Button>
            </div>
          </dl>
        </Section>
      </label>
    </Component>
  );
};

export default ChattingEstItem;
