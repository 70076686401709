// 채팅 윈도우 높이 계산
export const getCurrentScrollHeight = ($el: HTMLElement) => {
  return ($el.querySelector('ul') as HTMLElement).offsetHeight;
};

// 스크롤 위치 이동
export const setScrollPosition = ($el: HTMLElement, position: number) => {
  if (!!$el.scrollTo) {
    $el.scrollTo(0, position);
  } else {
    $el.scrollTop = 9999;
  }
};

// 스크롤 맨 밑으로 이동
export const setScollToBottom = ($el: HTMLElement) => {
  setScrollPosition($el, getCurrentScrollHeight($el));
};
