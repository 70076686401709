import * as React from 'react';

const { useContext, useState, createContext } = React;
const AppContext = createContext<any>(null);

const AppProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<any>({
    shop: null,
    sendbirdConnection: null,
    sendbirdId: '',
    notiCount: {},
    banks: [],
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export default AppProvider;
