import * as React from 'react';
import PageTemp from 'components/template/pageTemp';
import BoardListTemp from 'components/template/boardListTemp';
import SearchForm from 'components/base/searchForm';
import Pagination from 'components/base/pagination';
import Section from 'elements/ui/section';
import ServiceSelect from 'elements/helper/serviceSelect';
import ReviewSelect from 'components/review/reviewSelect';
import ReviewResult from 'components/review/reviewResult';
import ReviewItem from 'components/review/reviewItem';
import Empty from 'elements/helper/empty';
import Loading from 'elements/helper/loading';
import { IContProps } from 'containers/review/reviewCont/interface';

const ReviewListPage: React.FC<IContProps> = ({
  pending,
  page,
  form,
  items,
  summary,
  isSelectedScore,
  onInputForm,
  onSearch,
  onChangePage,
  onShowOriginImage,
}) => {
  return (
    <PageTemp
      header={
        <>
          <PageTemp.Title>후기 관리</PageTemp.Title>
          <PageTemp.SubTitle>
            고객들의 후기를 모아둔 게시판입니다.
          </PageTemp.SubTitle>
        </>
      }
    >
      <BoardListTemp
        searchForm={
          <SearchForm
            form={[
              {
                type: 'element',
                label: '서비스 구분',
                element: (
                  <ServiceSelect
                    defaultValue={form.service}
                    onChange={e =>
                      onInputForm(
                        'service',
                        (e.target as HTMLInputElement).value,
                      )
                    }
                  />
                ),
              },
              {
                name: 'review',
                type: 'element',
                label: '후기',
                element: (
                  <ReviewSelect
                    defaultvalue={form.score}
                    onChange={nextState => onInputForm('score', nextState)}
                  />
                ),
              },
            ]}
            onSubmit={onSearch}
          />
        }
        pagination={
          <Pagination
            currentPage={page}
            perPage={summary.itemCount}
            itemCount={summary.totalCount}
            onChange={onChangePage}
          />
        }
      >
        {!!summary.totalCount && (
          <Section b={0.5}>
            <ReviewResult
              isSelectedScore={isSelectedScore}
              total={summary.totalCount}
              good={summary.goodCount}
              bad={summary.badCount}
              avg={summary.avgScore}
            />
          </Section>
        )}
        {pending && (
          <Loading style={{ minHeight: '30rem' }}>
            알림 목록을 불러오고 있습니다.
          </Loading>
        )}
        {!pending && items.length === 0 ? (
          <Empty>후기 목록이 없습니다.</Empty>
        ) : (
          <div>
            {items.map(
              ({
                reviewId,
                requestId,
                service,
                userDesc,
                authUserName,
                files,
                review,
                score,
                quality,
                kindness,
                reliability,
                createdAt,
              }) => (
                <ReviewItem
                  key={reviewId}
                  id={reviewId}
                  requestId={requestId}
                  service={service}
                  userCar={userDesc}
                  userName={authUserName}
                  photos={files?.map(({ url }: any) => url) || []}
                  review={review}
                  score={score}
                  quality={quality}
                  kindness={kindness}
                  reliability={reliability}
                  createdAt={createdAt}
                  onShowOriginImage={onShowOriginImage}
                />
              ),
            )}
          </div>
        )}
      </BoardListTemp>
    </PageTemp>
  );
};

export default ReviewListPage;
