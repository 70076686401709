import * as React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import { cardocServices, cardocServiceKeys } from 'core/data/cardocService';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import repair from './svgs/ico-repair.svg';
import techshop from './svgs/ico-techshop.svg';
import wash from './svgs/ico-wash.svg';
import connectBattery from './svgs/ico-battery.svg';
import connectInsurance from './svgs/ico-insurance.svg';
import etc from './svgs/ico-etc.svg';

interface IProps {
  activeFields: any;
}

const icons: { [key: string]: string } = {
  repair,
  techshop,
  wash,
  connectBattery,
  connectInsurance,
  etc,
};

const StyledComponent = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    ul {
      width: calc(100% + 0.7rem);
      display: flex;
      flex-wrap: wrap;
      position: relative;
      left: -0.35rem;

      li {
        width: calc(25% - 0.7rem);
        margin: 0.35rem;
        padding: 2.25rem 0 1.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border: 1px solid ${colors.gray6};
        border-radius: ${sizes.radius};
        background-color: ${colors.blueLightGray};

        p {
          margin-top: 0.75rem;
          font-weight: 500;
          color: ${colors.primary};
        }

        &.disabled {
          background-color: ${colors.gray9};

          .activity-fields__circle,
          p {
            color: ${colors.gray1};
            filter: grayscale(100%);
            opacity: 0.25;
          }
        }
      }
    }

    .activity-fields {
      &__circle {
        width: 85px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${colors.primary};
        border-radius: 50%;
        background-color: white;
      }

      &__tech-buttons {
        width: 100%;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;

        button {
          width: 33.3333%;
          padding: 0.25rem 0;
          border: none;
          border-bottom: 1px solid ${colors.gray6};
          background: white;
          font-size: 0.875rem;
          color: ${colors.primary};
          outline: none;

          &:not(:last-child) {
            border-right: 1px solid ${colors.gray6};
          }

          &.active {
            background-color: ${colors.primary};
            color: white;
          }
        }
      }
    }

    ${media.mobile`
      .pc-only-text {
        display: none;
      }

      ul {
        li {
          width: calc(50% - 0.7rem);
        }
      }
    `}
  `}
`;

const ActivityField: React.FC<IProps> = ({ activeFields }) => {
  return (
    <StyledComponent>
      <MyShopFormItem
        label="활동분야"
        labelSurffix={
          <small>
            <span className="pc-only-text">
              * 활동분야 추가, 수정을 희망할 시 카닥 고객센터(1599-4572)로
              연락주세요.
            </span>
          </small>
        }
      >
        <ul>
          {cardocServiceKeys.map(key => (
            <li
              key={key}
              className={classnames({
                disabled: activeFields?.[key]?.activeYN !== 'Y',
              })}
            >
              {/* TODO: 1차 스펙에서 제거되어 주석 처리 함 */}
              {/* {key === 'techshop' && activeFields?.[key]?.activeYN === 'Y' && (
                <div className="activity-fields__tech-buttons">
                  <button className="active">전체</button>
                  <button>수입</button>
                  <button>국산</button>
                </div>
              )} */}
              <div className="activity-fields__circle">
                <img src={icons[key]} alt={key} />
              </div>
              <p>{cardocServices[key]}</p>
            </li>
          ))}
        </ul>
      </MyShopFormItem>
    </StyledComponent>
  );
};

export default ActivityField;
