import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { IMemuItems } from 'core/interface/Menu';
import styled, { css } from 'styles/Styled';

interface IProps extends RouteComponentProps {
  menu: IMemuItems;
}

const StyledComponent = styled.div`
  ${({ theme: { sizes } }) => css`
    ol {
      height: ${sizes.breadcrumnbHeight};
      margin: 0;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.colors.border};
      background-color: white;
      font-size: 0.925rem;

      li {
        color: ${props => props.theme.colors.gray2};

        &:last-child {
          font-weight: 700;
          color: ${props => props.theme.colors.black};
        }
      }
    }
  `}
`;

const { useMemo } = React;

const BreadcrumbComponent: React.FC<IProps> = ({ location, menu }) => {
  const menuItems: JSX.Element[] = useMemo(() => {
    const [, , depth1, depth2] = location.pathname.split('/');

    if (!menu[depth1]) {
      return [];
    }

    const breadcrumb = [];
    const { label: menuLabel, children: menuChildren } = menu[depth1];

    if (menuChildren) {
      const { label: childLabel } = menuChildren[`${depth1}-${depth2}`];
      breadcrumb.push(<>{menuLabel}</>, <>{childLabel}</>);
    } else {
      breadcrumb.push(<span>{menuLabel}</span>);
    }

    return breadcrumb;
  }, [menu, location]);

  return menuItems.length === 0 ? null : (
    <StyledComponent>
      <Breadcrumb>
        <BreadcrumbItem>홈</BreadcrumbItem>
        {menuItems.map((el, index) => (
          // eslint-disable-next-line
          <BreadcrumbItem key={index}>{el}</BreadcrumbItem>
        ))}
      </Breadcrumb>
    </StyledComponent>
  );
};

export default withRouter(BreadcrumbComponent);
