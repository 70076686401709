import * as React from 'react';
import styled, { css } from 'styles/Styled';
import Icon from 'elements/helper/icon';

interface IProps {
  size?: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${colors.gray5};
    font-size: 5rem;

    .icon {
      position: relative;
      left: 1px;
      bottom: 1px;
      font-size: 0.3em;
      color: ${colors.gray3};
    }
  `}
`;

const UserProfile: React.FC<IProps> = ({ size }) => (
  <StyledComponent style={{ width: size, height: size, fontSize: size }}>
    <span className="icon">
      <Icon iconName="fa fa-user" />
    </span>
  </StyledComponent>
);

export default UserProfile;
