import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import mobileStyles from './Header.styles.mobile';

export default styled.header`
  ${({ theme: { sizes, colors } }) => css`
    width: 100%;
    min-width: ${sizes.containerWidth};
    height: ${sizes.headerHeight};
    display: flex;
    justify-content: space-between;
    z-index: 99;
    top: 0;
    left: 0;
    position: fixed;
    border-bottom: 1px solid ${colors.border};
    background-color: ${colors.gray8};

    .menu-button {
      display: none;
    }

    .logo-wrap {
      width: ${sizes.asideWidth};
      display: flex;

      .home-button {
        width: ${sizes.headerHeight};
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.gray6};
        font-size: 1.25rem;
        color: ${colors.subGray};
        transition: background-color 500ms ease;
        outline: none !important;

        &:hover {
          background-color: ${colors.gray5};
        }
      }

      .logo {
        flex: 1;
        background: url('/cardoc-logo.png') no-repeat 15px;
        background-size: auto 45%;
      }
    }

    .button-wrap {
      margin-right: 1.5rem;

      ul {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 0.925rem;

        li {
          display: flex;
          align-items: center;
          position: relative;
          color: ${colors.gray1};

          &:not(:nth-child(1)):before {
            content: '';
            width: 1px;
            height: 0.75rem;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            background-color: ${colors.gray5};
          }

          &.my {
            display: flex;
            align-items: center;

            span {
              max-width: 20rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .my-page-button {
              margin-left: 0.5rem;
              padding: 2px 5px 3px;
              border: 1px solid ${colors.gray2};
              border-radius: 10px;
              background-color: transparent;
              font-size: 0.775rem;
              color: ${colors.gray2};
              text-decoration: none;
              transition: border-color 500ms, color 500ms ease;

              &:hover {
                border-color: ${colors.primary};
                color: ${colors.primary};
              }
            }
          }

          .noti-count {
            margin-right: 0.35rem;
            position: relative;
            top: 1px;
            font-size: 1.15rem;

            &:after {
              content: '';
              width: 5px;
              height: 5px;
              display: block;
              position: absolute;
              top: 0;
              left: 11px;
              border-radius: 50%;
              background-color: ${colors.subBlue};
            }
          }

          .link {
            display: flex;
            align-items: center;
            color: inherit;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  `}

  ${media.mobile`
    ${mobileStyles}
  `}
`;
