import * as React from 'react';
import produce from 'immer';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

import { cardocServices, cardocServiceKeys } from 'core/data/cardocService';
import Input from 'elements/form/input';
import MyShopFormItem from 'components/myPage/myShopFormItem';
import SmallDescription from 'components/myPage/smallDescription';

interface IDataSource {
  [key: string]: string[];
}

interface IProps {
  errors: { [key: string]: string };
  activeFields: any;
  defaultValue: IDataSource;
  onChange: (nextState: IDataSource) => void;
}

const { useState, useCallback } = React;
const StyledComponent = styled.div`
  ${({ theme: { sizes } }) => css`
    .item-row {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .service-name {
        width: 5.5rem;
        display: flex;
        align-items: center;
        border-radius: ${sizes.radius};
        background-color: #5a7685;

        span {
          width: 100%;
          padding: 0 0.5rem;
          line-height: 1.15;
          font-size: 0.925rem;
          line-height: 1.25;
          text-align: center;
          color: white;
          word-break: keep-all;
        }
      }

      .input-wrap {
        flex: 1;
        display: flex;

        > div {
          width: 50%;
          margin-left: 0.5rem;

          input::placeholder {
            font-size: 0.925rem;
          }
        }
      }

      ${media.mobile`
        .service-name {
          height: 40px;
        }

        .input-wrap {
          flex-direction: column;

          > div {
            width: 100%;

            + div {
              margin-top: 0.5rem;
            }

            .input-wrap {
              height: 40px;
            }
          }
        }
      `}
    }
  `}
`;

const TelNumber: React.FC<IProps> = ({
  errors,
  activeFields,
  defaultValue,
  onChange,
}) => {
  const [state, setState] = useState(defaultValue);
  const handleChange = useCallback(
    (key, index, value) => {
      const nextState = produce(state, draft => {
        if (!draft[key]) draft[key] = [];
        draft[key][index] = value;
      });
      setState(nextState);
      onChange(nextState);
    },
    [state, onChange],
  );

  return (
    <MyShopFormItem label="담당자 연락처" required>
      <StyledComponent>
        {cardocServiceKeys.map(key =>
          activeFields?.[key]?.activeYN === 'Y' ? (
            <div key={key} className="item-row">
              <div className="service-name">
                <span>{cardocServices[key]}</span>
              </div>
              <div className="input-wrap">
                {[0, 1].map(index => (
                  <Input
                    type="number"
                    key={index}
                    placeholder="휴대폰 번호(-없이)"
                    value={state?.[key]?.[index] || ''}
                    errorMessage={index === 0 && errors[key] ? ' ' : undefined}
                    maxLength={11}
                    visibleLength={false}
                    onChange={e => handleChange(key, index, e.target.value)}
                  />
                ))}
              </div>
            </div>
          ) : null,
        )}
        <SmallDescription>
          <p>첫번째 연락처가 각 서비스의 대표번호로 지정됩니다.</p>
          <p>
            추가 번호는 카닥 안내문자를 추가로 받을 담당자가 있는 경우
            입력해주세요.
          </p>
        </SmallDescription>
      </StyledComponent>
    </MyShopFormItem>
  );
};

export default TelNumber;
