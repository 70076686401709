import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

// chatting estimation template
const CHattingReqComp = styled.div`
  ${({ theme: { colors } }) => css`
    width: 400px;
    border-right: 1px solid ${colors.border};
    overflow-x: hidden;
    overflow-y: auto;

    ${media.mobile`
      display: none;
    `}
  `}
`;
const EstReq: React.FC = ({ children }) => (
  <CHattingReqComp>{children}</CHattingReqComp>
);

// chatting window template
const ChatWindowComp = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;
const ChatWindow: React.FC = ({ children }) => (
  <ChatWindowComp>{children}</ChatWindowComp>
);

// chatting detail template types
type ChattingDetailTempType = {
  EstReq: typeof EstReq;
  ChatWindow: typeof ChatWindow;
};

// main template
const StyledComponent = styled.div`
  height: 100%;
  display: flex;
`;

const ChattingDetailTemp: React.FC & ChattingDetailTempType = ({
  children,
}) => <StyledComponent>{children}</StyledComponent>;

ChattingDetailTemp.EstReq = EstReq;
ChattingDetailTemp.ChatWindow = ChatWindow;
export default ChattingDetailTemp;
