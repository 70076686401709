import * as React from 'react';
import Icon from 'elements/helper/icon';
import styled, { css } from 'styles/Styled';

interface IProps {
  full?: boolean;
  style?: React.CSSProperties;
}

const StyledComponent = styled.div<{ full: boolean }>`
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.gray8};

  svg {
    font-size: 3rem;
    color: #999;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: #666;
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;
      height: 100%;
    `}
`;

const Empty: React.FC<IProps> = ({ full = false, style, children }) => (
  <StyledComponent full={full} style={style}>
    <Icon iconName="fas fa-exclamation-circle" />
    <p>{children || '데이터가 없습니다.'}</p>
  </StyledComponent>
);

export default Empty;
