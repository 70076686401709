import * as React from 'react';
import Label from 'elements/helper/label';

interface IProps {
  status: string;
}

const { useMemo } = React;

const EstimationStatusLabel: React.FC<IProps> = ({ status }) => {
  const item = useMemo(() => {
    switch (status) {
      case 'wait':
        return {
          color: 'warning',
          label: '대기중',
        };
      case 'ing':
        return {
          color: 'success',
          label: '채팅중',
        };
      case 'complete':
        return {
          color: 'secondary',
          label: '평가완료',
        };
      default:
        return null;
    }
  }, [status]);

  return item ? <Label color={item.color}>{item.label}</Label> : null;
};

export default EstimationStatusLabel;
