import React, { Fragment } from 'react';

import MemoMetaInsuranceCompanyDropDown from '../estimationDetailMemo/MemoMetaInsuranceCompanyDropDown';
import { Input } from 'reactstrap';

import { numberFormat } from 'core/function/Formatter';

import styled, { css } from 'styles/Styled';

import { MultipleInputsWithDropdownProps } from 'core/interface/MultipleInsuranceEstimates';

const StyledInput = styled.div`
  ${({ theme: { colors } }) => css`
    display: inline-block;
    width: 60%;
    padding-left: 10px;
    .multiple-estimation-request {
      &__price {
        padding: 0.8rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;

        &[disabled] {
          background-color: ${colors.gray8};
        }
        &::placeholder {
          font-weight: 500;
          font-size: 1.05rem;
          text-align: center;
          transform: translateY(-3px);
        }
      }
    }
  `}
`;

const MultipleInputsWithDropdown: React.FC<MultipleInputsWithDropdownProps> = ({
  possibleInsuranceCompanies,
  multipleInsuranceEstimatesMetaObjectArray,
  setMultipleInsuranceEstimatesMetaObjectArray,
  multipleInsuranceEstimations,
  estimationsSentOut,
  channelUrl,
}) => {
  let arrayToMap = multipleInsuranceEstimatesMetaObjectArray;
  if (multipleInsuranceEstimations.length > 0) {
    arrayToMap = multipleInsuranceEstimations;
  }

  const handleMultipleInputChange = (e: any) => {
    e.preventDefault();
    let { value } = e.target;
    value = `${value.replace(/[^0-9]/g, '')}`;
    const clickedId = e.target.getAttribute('data-index');
    updateMultipleInsuranceEstimatesMetaObjectArray(clickedId, value);
  };

  const processKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      let { value } = e.target;
      value = `${value.replace(/[^0-9]/g, '')}`;
      const clickedId = e.target.getAttribute('data-index');
      updateMultipleInsuranceEstimatesMetaObjectArray(
        clickedId,
        value.slice(0, -1),
      );
    }
  };

  const updateMultipleInsuranceEstimatesMetaObjectArray = (
    clickedId: number,
    value: string,
  ) => {
    const newInsuranceEstimatesMetaObject = {
      ...multipleInsuranceEstimatesMetaObjectArray[clickedId],
      suggestedAmount: value,
    };
    const newMultipleInsuranceEstimatesMetaObjectArray = multipleInsuranceEstimatesMetaObjectArray.slice();
    newMultipleInsuranceEstimatesMetaObjectArray[
      clickedId
    ] = newInsuranceEstimatesMetaObject;
    setMultipleInsuranceEstimatesMetaObjectArray(
      newMultipleInsuranceEstimatesMetaObjectArray,
    );
  };

  const markup = arrayToMap.map((multipleInsuranceCompanyMeta, index) => {
    const { insuName, suggestedAmount } = multipleInsuranceCompanyMeta;
    const suggestedAmountFromServer =
      multipleInsuranceEstimations[index]?.suggestedAmount;
    const insuNameFromServer =
      multipleInsuranceEstimations[index]?.cdCarInsuranceCorp.insuName;
    let suggestedAmountToShow = suggestedAmountFromServer;
    if (!suggestedAmountFromServer) {
      suggestedAmountToShow = suggestedAmount;
    }
    return (
      <Fragment key={`${insuName}-fragment-${index}`}>
        <MemoMetaInsuranceCompanyDropDown
          type="multiple-inputs-insurance-company-dropdown"
          possibleInsuranceCompanies={possibleInsuranceCompanies}
          key={`${insuName}-dropdown-${index}`}
          multipleInsuranceCompanyMeta={multipleInsuranceCompanyMeta}
          multipleInsuranceEstimatesMetaObjectArray={
            multipleInsuranceEstimatesMetaObjectArray
          }
          setMultipleInsuranceEstimatesMetaObjectArray={
            setMultipleInsuranceEstimatesMetaObjectArray
          }
          insuNameFromServer={insuNameFromServer}
          estimationsSentOut={estimationsSentOut}
          channelUrl={channelUrl}
        />
        <StyledInput>
          <Input
            className="multiple-estimation-request__price"
            placeholder="금액을 입력해주세요."
            disabled={!!channelUrl && estimationsSentOut}
            key={`${insuName}-input-${index}`}
            data-index={index}
            value={
              suggestedAmountToShow
                ? `${numberFormat(Number(suggestedAmountToShow))}원`
                : ''
            }
            onKeyDown={processKeyDown}
            onChange={handleMultipleInputChange}
          />
        </StyledInput>
      </Fragment>
    );
  });
  return <div>{markup}</div>;
};

export default MultipleInputsWithDropdown;
