import styled from 'styles/Styled';
import media from 'styles/Media';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    flex-wrap: wrap;
    margin: 0;
    border-radius: ${props => props.theme.sizes.borderRadius};

    button {
      padding: 0.75rem 1.15rem;
      border-radius: ${props => props.theme.sizes.borderRadius} !important;
      white-space: nowrap;
    }
  }

  ${media.mobile`
    .page-number:not(.active) {
      display: none;
    }
  `}
`;
