import React from 'react'
import { Modal, ModalBody } from 'reactstrap';

import IconCloseDarkBackground from './ico-close-dark-background.svg';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  insuranceCertificateUrl: string;
}

const InsuranceCertificateModal: React.FC<IProps> = ({ isOpen, toggle, insuranceCertificateUrl }) => {
  return (
    <Modal
        className="insurance-certificate-preview-modal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <span>
          <img
            src={IconCloseDarkBackground}
            className="icon-close-dark-background"
            alt="icon-close-dark-background"
            onClick={toggle}
          />
        </span>
        <ModalBody>
          <img
            className="insurance-certificate-image"
            src={insuranceCertificateUrl}
            alt="insurance-certificate"
          />
        </ModalBody>
      </Modal>
  )
}

export default InsuranceCertificateModal