import * as React from 'react';
import moment from 'moment';
import styled, { css } from 'styles/Styled';

import { TChattingItem } from 'core/interface/Sendbird';
import { useAppContext } from 'core/context/appContext';
import ChattingDetailItem from 'components/connect/chatting/chattingDetailItem';

interface IProps {
  hasMore: boolean;
  items: TChattingItem[];
  setEl: ($el: HTMLElement) => void;
  onToggleOriginImage: (imgSrc: string) => void;
}

const { useRef, useEffect } = React;

const COMP_NAME = 'chatting-window';
const HOVER_CLASS_NAME = 'hover';
const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    flex: 1;
    position: relative;
    background-color: ${colors.gray8};
    overflow-x: hidden;
    overflow-y: auto;
    transition: background-color 300ms ease;

    &.${HOVER_CLASS_NAME} {
      background-color: ${colors.gray6};
    }

    .${COMP_NAME} {
      &__first-item {
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 15rem;
          height: 3.5rem;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3.5rem;
          background-color: ${colors.gray5};
          color: ${colors.gray1};
        }
      }
    }
  `}
`;

const ChattinWindow: React.FC<IProps> = ({
  hasMore,
  items,
  setEl,
  onToggleOriginImage,
}) => {
  const {
    state: { sendbirdId },
  } = useAppContext();
  const $el = useRef<any>(null);

  useEffect(() => {
    // 마운트 시, element를 emit하여 컨테이너에서 사용할 수 있도록 준비 해둠
    setEl($el.current);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledComponent ref={$el}>
      <ul>
        {!hasMore && (
          <li className={`${COMP_NAME}__first-item`}>
            <p>채팅 시작</p>
          </li>
        )}
        {(items as any[]).map(
          ({
            messageId,
            messageType,
            message,
            url,
            name,
            createdAt,
            _sender: { userId },
          }) => (
            <ChattingDetailItem
              key={messageId}
              type={messageType}
              myMessage={userId === sendbirdId}
              message={message}
              fileUrl={url}
              fileName={name}
              createdAt={moment(createdAt).format('YYYY.MM.DD HH:mm')}
              onShowImage={onToggleOriginImage}
            />
          ),
        )}
      </ul>
    </StyledComponent>
  );
};

export default ChattinWindow;
