import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import peoduce from 'immer';

import { NOTIFICATION } from 'core/consts/Path';
import {
  getNotifications,
  getNotificationCount,
  readNotification,
} from 'core/api/Notification';
import { useAppContext } from 'core/context/appContext';
import useFetch from 'core/hooks/UseFetch';

import NotificationPage from 'pages/notification/NotificationPage';
import { IContProps } from './interface';

const { useState, useEffect, useCallback } = React;

const NotificationCont: React.FC = () => {
  const { state: ctxState, setState: setCtxState } = useAppContext();
  const history = useHistory();
  const { page: pageParam } = useParams();
  const page = Number(pageParam || 1);
  const [state, setState] = useState<any>({
    items: [],
  });

  // 알림 목록 조회 API
  const [onFetch, pending] = useFetch(getNotifications, []);

  // 알림 목록 카운트 API
  const [onFetchCount, , countData] = useFetch(getNotificationCount, null);

  // 알림 메세지 조회
  const getNotificationItems = useCallback(
    async (reqPage = 1) => {
      onFetchCount('all');
      const response = await onFetch(reqPage);
      if (response) {
        setState({ ...state, items: response });
      }
    },
    [state, onFetch, onFetchCount],
  );

  // 알림 메세지 읽기
  const handleRead = useCallback(item => {
    // 읽음 처리
    readNotification({
      notiid: item.notiid,
      updatetype: 'readbyuser',
    });

    if (item?.notimsg?.url) {
      window.location.href = item.notimsg.url;
    }
  }, []);

  const handleReadAll = useCallback(async () => {
    if (!window.confirm('전체 읽음 처리 하시겠습니까?')) return;
    if (
      await readNotification({
        updatetype: 'readAllWithinMonth',
      })
    ) {
      // 안 읽음 알림 수 업데이트
      setCtxState(
        peoduce(ctxState, (draft: any) => {
          draft.notiCount.notification = 0;
        }),
      );

      // item 읽음 처리
      setState({
        ...state,
        items: state.items.map((item: object) => ({
          ...item,
          readyn: 'Y',
        })),
      });
    }
  }, [ctxState, setCtxState, state]);

  // 페이지 이동
  const handleChangePage = useCallback(
    (nextPage: number) => {
      history.push(`${NOTIFICATION}/${nextPage}`);
    },
    [history],
  );

  // 페이지 변경시 알림 목록 조회
  useEffect(() => {
    getNotificationItems(page);
    // eslint-disable-next-line
  }, [page]);

  const dataSource: IContProps = {
    pending,
    page,
    items: state.items,
    itemCount: countData?.count || 0,
    perPage: countData?.page_count || 0,
    onRead: handleRead,
    onReadAll: handleReadAll,
    onChangePage: handleChangePage,
  };

  return <NotificationPage {...dataSource} />;
};

export default NotificationCont;
