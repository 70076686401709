import * as React from 'react';
import _ from 'lodash';

type TValidKey = 'required' | 'shopName';

type TAction = {
  [key: string]: string;
};

type TError = {
  [key: string]: string;
};

type TValidations = {
  [key: string]: {
    rule: TValidKey[];
    msg: {
      [key: string]: string;
    };
  };
};

type TState = {
  validations: TValidations;
  error: TError;
};

type TReturn = [(values: TAction) => void, TError];

const { useReducer, useCallback } = React;

function reducer(state: TState, action: TAction) {
  const { validations } = state;
  const nextError: TError = {};

  Object.keys(validations).forEach((key: string) => {
    const targetValue = action[key];
    const { rule, msg } = validations[key];

    rule.forEach(ruleName => {
      if (ruleName === 'required') {
        if (!targetValue.trim()) nextError[key] = msg[ruleName];
      }
      if (ruleName === 'shopName') {
        if (!/^[가-힣a-zA-Z0-9&)([\]\s]{1,25}$/.test(targetValue))
          nextError[key] = msg[ruleName];
      }
    });
  });

  if (_.isEqual(state.error, nextError)) {
    return state;
  }

  return {
    ...state,
    error: nextError,
  };
}

export default (validations: TValidations, error: TError = {}): TReturn => {
  const [state, dispatch] = useReducer(reducer, {
    validations,
    error,
  });

  const onValidate = useCallback((values: TAction) => {
    dispatch(values);
  }, []);

  return [onValidate, state.error];
};
