import { IMemuItems } from 'core/interface/Menu';
import * as PATH from 'core/consts/Path';
// TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
// import * as URL from 'core/consts/Url';
// import { cardocService } from 'core/data/cardocService';

const menu: IMemuItems = {
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // repair: {
  //   label: cardocService.repair,
  //   path: URL.REPAIR_ADMIN_DOMAIN,
  // },
  // techshop: {
  //   label: cardocService.techshop,
  //   path: URL.TECHSHOP_ADMIN_DOMAIN,
  // },
  connect: {
    label: '빠른 견적',
    children: {
      'connect-estimation': {
        label: '받은 견적',
        path: PATH.CONNECT_ESTIMATION,
      },
      'connect-chatting': {
        label: '채팅',
        path: PATH.CONNECT_CHATTING,
      },
    },
  },
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // mall: {
  //   label: '사업자 몰',
  //   path: URL.MALL_DOMAIN,
  //   openWindow: true,
  // },
  'my-page': {
    label: '내정보',
    path: PATH.MY_PAGE,
    hideAside: true,
  },
  // TODO: 회원정보 1차 스펙에서 제거되어 주석 처리 함
  // notice: {
  //   label: '공지사항',
  //   path: PATH.NOTICE,
  // },
  notification: {
    label: '알림',
    path: PATH.NOTIFICATION,
    hideAside: true,
  },
  review: {
    label: '후기 관리',
    path: PATH.REVIEW,
  },
};

export default menu;
