import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import { lighten, darken } from 'polished';
import mobileStyles from './Aside.styles.mobile';

export default styled.aside<{ active: boolean }>`
  ${({ active, theme: { sizes, colors } }) => css`
    width: ${sizes.asideWidth};
    height: 100vh;
    padding-top: ${sizes.headerHeight};
    position: fixed;
    z-index: 98;
    bottom: 0;
    left: 0;
    overflow: hidden;
    color: white;

    .background {
      display: none;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${colors.subGray};

      .close-button {
        display: none;
      }

      .profile {
        padding: 2.25rem;

        .icon {
          display: flex;
          justify-content: center;
        }

        p {
          margin-top: 1.15rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.05rem;
          text-align: center;
        }
      }
    }

    .nav {
      width: calc(${sizes.asideWidth} + 100px);
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .sub-nav {
      display: none;
    }

    ul {
      width: ${sizes.asideWidth};

      li {
        position: relative;

        hr {
          width: calc(100% - 2rem);
          margin: 0 auto;
          border-color: ${darken(0.05, colors.subGray)};
        }

        .first-depth {
          padding: 1.5rem;
          display: flex;
          align-items: center;

          .label {
            font-size: 1.05rem;
            transition: color 500ms ease;

            &.active {
              color: ${colors.primary};
            }
          }

          .dot {
            width: 5px;
            height: 5px;
            margin-top: 1px;
            margin-left: 2px;
            align-self: flex-start;
            border-radius: 50%;
            background-color: ${colors.primary};
          }

          .arrow {
            margin-left: auto;
            font-size: 0.85rem;
            color: ${lighten(0.25, colors.subGray)};
            transition: transform 500ms ease;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .second-depth {
          height: 0;
          overflow: hidden;
          background-color: ${darken(0.05, colors.subGray)};

          p {
            padding: 0.5rem 1.5rem;
            display: flex;
            align-items: center;
            font-size: 0.95rem;
            color: ${colors.gray3};
            opacity: 0;
            transform: translateX(-0.75rem);
            transition: color 500ms, opacity 500ms, transform 300ms ease;

            &.active {
              color: ${colors.primary} !important;
            }

            &:hover {
              color: white;
              cursor: pointer;
            }

            .count {
              width: 28px;
              padding: 1px 0 3px;
              margin-left: auto;
              border-radius: 18px;
              background-color: ${colors.primary};
              font-size: 10px;
              text-align: center;
              color: white;
            }
          }
        }

        input[type='checkbox'] {
          display: none;
          &:checked {
            + .first-depth {
              .arrow {
                transform: rotate(540deg);
              }

              + .second-depth {
                height: auto;
                padding: 1rem 0 1.15rem;

                p {
                  opacity: 1;
                  transform: translateX(0);
                }
              }
            }
          }
        }
      }
    }

    ${media.mobile`
      ${mobileStyles(active)}
    `}
  `}
`;
