import { cardocApiCreator } from 'core/api/ApiCreator';

/**
 * 파일 업로드
 */
export const uploadFiles = (
  files: File[],
  uploadType: 'shopjoin',
  shopId?: number | string,
) => {
  const formData = new FormData();
  formData.append('uploadtype', uploadType);
  if (shopId) formData.append('shopid', shopId.toString());

  [].forEach.call(files, file => {
    formData.append('files[]', file);
  });

  return cardocApiCreator.post('v2/fileObject', formData);
};
