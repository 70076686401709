import { css } from 'styles/Styled';

export default css`
  min-width: 0;
  background-color: white;

  .menu-button {
    margin-left: 0.5rem;
    display: block;
    border: none;
    background-color: transparent;
    font-size: 1.35rem;
  }

  .logo-wrap {
    .home-button {
      display: none;
    }

    .logo {
      background-position: center;
    }
  }

  .button-wrap {
    margin-right: 0.5rem;

    ul li {
      &:before,
      &.my,
      &.link,
      &.logout {
        display: none;
      }

      &.notification {
        .noti-count {
          font-size: 1.425rem;
        }

        .text {
          display: none;
        }
      }
    }
  }
`;
