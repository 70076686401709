import * as React from 'react';
import styled, { css } from 'styles/Styled';

interface IProps {}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    margin-top: 1rem;

    p {
      margin-bottom: 0.25rem;
      padding-left: 0.5rem;
      position: relative;
      font-size: 0.775rem;
      color: ${colors.gray3};

      &:before {
        content: '*';
        position: absolute;
        top: 0.1rem;
        left: 0;
      }
    }
  `}
`;

const SmallDescription: React.FC<IProps> = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

export default SmallDescription;
