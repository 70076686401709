import * as React from 'react';
import { Route } from 'react-router-dom';
import { useAppContext } from 'core/context/appContext';
import { ACTIVITY_FIELD } from 'core/consts/Path';
import { cardocServiceKeys } from 'core/data/cardocService';

interface IProps {
  path: string;
  component: React.FC;
}

const { useEffect, useState } = React;
const WithPermissionRoute: React.FC<IProps> = ({ path, component }) => {
  const {
    state: { connectShop },
  } = useAppContext();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (
      !cardocServiceKeys.some(
        serviceKey => connectShop?.[serviceKey]?.activeYN === 'Y',
      )
    ) {
      window.location.href = ACTIVITY_FIELD;
    } else {
      setIsReady(true);
    }
  }, [connectShop]);

  return isReady ? <Route path={path} component={component} /> : null;
};

export default WithPermissionRoute;
