import { cardocApiCreator } from 'core/api/ApiCreator';

/**
 * 알림 목록 조회
 */
export interface TNotificationItem {
  notiid: string;
  regdttm: string;
  readyn: 'Y' | 'N';
  notimsg: {
    msg: string;
  };
}

export const getNotifications = (page: number) => {
  const shopId = sessionStorage.getItem('connectShopId');
  return cardocApiCreator.get(
    `/v2/notification/type/connectShop/ownerid/${shopId}/page/${page}`,
  );
};

/**
 * 알림 목록 카운트 조회
 */
export const getNotificationCount = (status: 'all' | 'unread') => {
  const shopId = sessionStorage.getItem('connectShopId');
  return cardocApiCreator.get(
    `/v2/notification/type/connectShop/ownerid/${shopId}/count/${status}`,
  );
};

/**
 * 알림 목록 업데이트
 */
export const updateNotifications = (updateType: 'readAllWithinMonth') =>
  cardocApiCreator.put(`/notification?updatetype=${updateType}`);

/**
 * 읽음 처리
 */
export const readNotification = (params: {
  notiid?: string;
  updatetype: 'readbyuser' | 'readAllWithinMonth';
}) =>
  cardocApiCreator.put(
    `/v2/notification${!!params.notiid ? `/${params.notiid}` : ''}`,
    params,
  );
