import axios from 'axios';

// cardoc-web
export const cardocApiCreator = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 120000,
  withCredentials: true,
});

// cardoc connect
export const connectApiCreator = axios.create({
  baseURL: process.env.REACT_APP_CONNECT_HOST,
  timeout: 120000,
  withCredentials: true,
});

export default null;
