import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import RightSection from './rightSection';

// 페이지 타이틀 컴포넌트
const TitleComponent = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
`;
const Title: React.FC = ({ children }) => (
  <TitleComponent>{children}</TitleComponent>
);

// 서브 타이틀 컴포넌트
const SubTitleComponent = styled.small`
  ${({ theme: { colors } }) => css`
    margin-left: 1rem;
    margin-bottom: 4px;
    padding-left: 1rem;
    border-left: 2px solid ${colors.gray5};
    font-size: 0.925rem;
    color: ${colors.gray2};

    ${media.mobile`
      display: none;
    `}
  `}
`;
const SubTitle: React.FC = ({ children }) => (
  <SubTitleComponent>{children}</SubTitleComponent>
);

type TPageTempType = {
  Title: typeof Title;
  SubTitle: typeof SubTitle;
  RightSection: typeof RightSection;
};

interface IProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  header?: JSX.Element;
}

const StyledComponent = styled.div<{ size: number }>`
  width: ${props => 1020 - (8 - props.size) * 100}px;
  margin: 0 auto;
  position: relative;

  .page-temp {
    &__header {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
  }

  ${media.mobile`
    width: 100%;
  `}
`;

const PageTemp: React.FC<IProps> & TPageTempType = ({
  size = 8,
  header,
  children,
}) => (
  <StyledComponent size={size}>
    {header && <div className="page-temp__header">{header}</div>}
    {children}
  </StyledComponent>
);

PageTemp.Title = Title;
PageTemp.SubTitle = SubTitle;
PageTemp.RightSection = RightSection;
export default PageTemp;
