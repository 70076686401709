import * as React from 'react';
import _ from 'lodash';

import { useAppContext } from 'core/context/appContext';
import { getGroupChannelList } from 'core/sdk/sendbird/groupChannelList';
import { updateChannelHanlder } from 'core/sdk/sendbird/eventHandler';
import ChattingListPage from 'pages/connect/chatting/ChattingListPage';

import { IContProp } from './interface';
import { IContProp as IParentContProps } from '../chattingCont/interface';

const { useRef, useState, useEffect, useCallback } = React;

const ChattingListContainer: React.FC<IParentContProps> = ({
  channel,
  onLinkToDetail,
}) => {
  const {
    state: ctxState,
    state: { sendbirdConnection },
    setState: setCtxCtate,
  } = useAppContext();

  const [state, setState] = useState<any>({
    pending: true,
    channels: [],
    prevListQuery: null,
  });

  const stateRef = useRef<any>({});

  // 스크롤 이벤트
  const scollEvent = _.debounce(() => {
    if (
      document.documentElement.scrollHeight - window.innerHeight <=
      document.documentElement.scrollTop
    ) {
      (async () => {
        const { channels, prevListQuery }: any = await getGroupChannelList(
          sendbirdConnection,
          stateRef.current.prevListQuery,
        );
        setState({
          ...stateRef.current,
          prevListQuery,
          channels: _.uniqBy(
            [...stateRef.current.channels, ...channels],
            ({ url }) => url,
          ),
        });
      })();
    }
  }, 300);

  // 그룹 채널 조회
  const getSendbirdGroupChannels = useCallback(async () => {
    try {
      setState({
        ...state,
        pending: true,
      });

      const { channels, prevListQuery }: any = await getGroupChannelList(
        sendbirdConnection,
      );

      setState({
        ...state,
        channels,
        prevListQuery,
        pending: false,
      });
    } catch {
      setState({
        ...state,
        pending: false,
      });
    }
  }, [sendbirdConnection, state]);

  // 채널 업데이트
  const updateChannel = useCallback(
    targetChannel => {
      setState({
        ...stateRef.current,
        channels: _.uniqBy(
          [targetChannel, ...stateRef.current.channels],
          ({ url }) => url,
        ),
      });

      // 채널 업데이트 후 안 읽은 메세지 수 업데이트
      sendbirdConnection.getTotalUnreadMessageCount(
        (count: any, error: any) => {
          if (error) return;
          setCtxCtate({
            ...ctxState,
            notiCount: {
              ...ctxState.notiCount,
              'connect-chatting': count,
            },
          });
        },
      );
    },
    [sendbirdConnection, ctxState, setCtxCtate],
  );

  // 마운트 시, 그룹 채널 목록 조회, 스크롤 이벤트 리스너 등록
  useEffect(() => {
    getSendbirdGroupChannels();
    window.addEventListener('scroll', scollEvent);

    return () => {
      // 언마운트 시, 스크롤 이벤트 리스너 제거
      window.removeEventListener('scroll', scollEvent);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // 언마운트 시, 스크롤 이벤트 리스너 해제
    updateChannelHanlder(sendbirdConnection, updateChannel);
    // eslint-disable-next-line
  }, [sendbirdConnection]);

  // 이벤트 리스너에서 사용하기 위해 state가 변경될 때마다 reference에 저장 해둠
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const dataSource: IContProp = {
    pending: state.pending,
    channels: state.channels,
    selectedChannel: channel,
    onLinkToDetail,
  };

  return <ChattingListPage {...dataSource} />;
};

export default ChattingListContainer;
