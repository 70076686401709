export default {
  clientSearch: {
    inputDropdowns: [
      { value: 'responsibility', string: '담당자' },
      { value: 'ceReqId', string: '견적번호' },
      { value: 'platenum', string: '차량번호' },
      { value: 'userName', string: '이름' },
      { value: 'mobileNum', string: '전화번호' },
      { value: 'dateOfBirth', string: '생년월일' },
    ],
    searchFunction: {
      buttonString: '검색',
      filters: [
        { string: '전체', status: '' },
        { string: '대기중', status: 'on-call' },
        { string: '채팅중', status: 'on-chat' },
        { string: '평가완료', status: 'complete' },
        { string: '가입완료', status: 'register-complete' },
      ],
    },
  },
  estimationDetailHeader: {
    requesterSubheader: '님의 견적 요청 내용',
  },
  estimationDetail: {
    memo: {
      title: '메모',
      memoPlaceholder: '메모를 입력해주세요. 고객님에게는 노출되지 않습니다.',
      maxMemoLength: 30,
      vinTitle: '차대번호',
      vinPlaceholder: '차대번호를 입력해주세요.',
      maxVinLength: 17,
      carRegDate: '자동차 등록일',
      carRegDatePlaceholder: 'ex) 20171009',
      regDocYear: '등록증상 연식',
      regDocYearPlaceholder: 'ex) 2017',
      prevInsuranceCompany: '기보험사',
      prevInsuranceCompanyPlaceholder: '선택하기',
      // NOTE: insuranceDate would better understood as plannedInsuranceRenewalDate
      insuranceDate: '보험가입 예정일 (갱신일)',
      insuranceDatePlaceholder: 'ex) 20171009',
    },
    saveButton: {
      clickToSave: '저장하기',
      saving: '저장중...',
    },
  },
  estimationAssignResponsibility: {
    assignee: {
      title: '담당자',
      assigneePlaceholder: '담당자의 이름을 작성해주세요.',
    },
    saveButton: {
      edit: '수정',
      save: '담당자 저장',
      saving: '저장중...',
    },
  },
  estimationFinalizedDetails: {
    title: '가입완료',
    details: {
      insuName: '가입보험사',
      insuranceRenewalDate: '가입일 (신차 경우 효력일)',
      price: '가격',
      propertyDamageLiability: '대물',
      bodilyInjuryCoverageDescription: '자상/자손',
    },
    viewInsuranceVertificate: {
      buttonText: '가입증명서 보기',
    },
  },
  estimationDetailContainer: {
    toastr: {
      estimationDetail: {
        fetchFail: '견적서 조회에 실패했습니다.',
      },
      createEstimation: {
        createSuccess: '견적서가 발송되었습니다.',
        createFail: '견적서 발송에 실패했습니다. 다시 시도해주시기 바랍니다.',
      },
      memo: {
        updateSuccess: '메모가 저장 되었습니다.',
        updateFail: '메모 저장에 실패 했습니다. 다시 시도해 주시기 바랍니다.',
      },
      assignee: {
        updateSuccess: '담당자가 저장 되었습니다.',
        updateFail: '담당자 저장에 실패 했습니다. 다시 시도해 주시기 바랍니다.',
      },
      getInsuranceCompanies: {
        fetchFail:
          '기보험사 정보 조회를 실패했습니다. 다시 시도해 주시기 바랍니다.',
      },
    },
    loading: {
      message: '견적 요청서 정보를 조회하고 있습니다.',
    },
  },
  chattingDetailPage: {
    toastr: {
      getInsuranceCompanies: {
        fetchFail:
          '기보험사 정보 조회를 실패했습니다. 다시 시도해 주시기 바랍니다.',
      },
    },
    putFinalizedInsuranceRegistrationDetailsModal: {
      title: '가입정보',
      insuranceCompany: '가입보험사',
      insuranceCompanyPlaceholder: '보험사를 선택해주세요.',
      insuranceRegistrationDate: '가입일 (신차 경우 효력일 입력)',
      insuranceRegistrationDatePlaceholder: '실제 가입한 날짜를 입력해주세요. ex) 20171009',
      insuranceAmount: '가격',
      insuranceAmountPlaceholder: '보험 가입 금액을 작성해주세요.',
      propertyDamageLiabilityTitle: '대물',
      propertyDamageLiabilityPlaceholder: '보험 가입 금액을 작성해주세요.',
      bodilyInjuryCoverage: '자상/자손',
      bodilyInjuryCoveragePlaceholder: '자상/자손 여부를 선택해주세요.',
      bodilyInjuryCoverageDropdown: {
        auto: '자동차 상해',
        self: '자기신체사고',
      },
      uploadArea: {
        title: '가입증명서 업로드',
        dragAreaText: {
          beforeFile: {
            __html:
              'JPG, JPEG, PNG 파일을 끌어다 놓거나<br/>‘첨부하기’ 버튼을 사용하세요.',
          },
          afterFile: {
            __html: '다른 파일 첨부를 원하시면 버튼은 눌러주세요.',
          },
        },
        buttonText: '첨부하기',
      },
      uploadButtonText: '등록하기',
      isUploadingButtonText: '등록 중입니다...',
      toastr: {
        uploadSuccess: '가입 정보 및 가입증명서 내용이 등록되었습니다.',
        uploadFail: '가입 정보 및 가입증명서 내용이 정상적으로 등록되지 못했습니다. 다시 시도 해주시기를 바랍니다.',
        tempFileUrlSuccess: '파일이 성공적으로 첨부 되었습니다.',
        tempFileUrlError: '파일이 성공적으로 첨부 되지 못했습니다. JPG, JPEG, PNG 파일 여부 확인 후 다시 시도해 주시기 바랍니다.'
      }
    },
  },
  chattingEstimateContainer: {
    toastr: {
      memoAndAssignee: {
        updateSuccess: '메모와 담당자가 정상적으로 저장 되었습니다.',
        updateFail:
          '메모와 담당자 저장에 실패 했습니다. 다시 시도해 주시기 바랍니다.',
      },
    },
  },
  chatEstimateItem: {
    memo: {
      title: '메모',
      memoPlaceholder: '메모를 입력해주세요. 고객님에게는 노출되지 않습니다.',
      maxMemoLength: 30,
    },
    assignee: {
      title: '담당자',
      assigneePlaceholder: '담당자의 이름을 작성해주세요.',
    },
    saveButton: {
      save: '저장하기',
      saving: '저장중...',
    },
  },
};
