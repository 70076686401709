import * as React from 'react';
import Label from 'elements/helper/label';

interface IProps {
  isGood: boolean;
}

const ReviewLabel: React.FC<IProps> = ({ isGood }) => (
  <Label color={isGood ? 'success' : 'danger'}>{isGood ? 'Good' : 'Bad'}</Label>
);

export default ReviewLabel;
