import * as React from 'react';
import { Link } from 'react-router-dom';

import { useAppContext } from 'core/context/appContext';
import * as PATH from 'core/consts/Path';
import Icon from 'elements/helper/icon';
import StyledComponent from './Header.styles';

interface IProps {
  onLogout: () => void;
  onToggleMenu: () => void;
}

const Header: React.FC<IProps> = ({ onLogout, onToggleMenu }) => {
  const {
    state: {
      connectShop,
      notiCount: { notification: unreadNotiCount },
    },
  } = useAppContext();

  return (
    <StyledComponent>
      <button type="button" className="menu-button" onClick={onToggleMenu}>
        <Icon iconName="fa fa-bars" />
      </button>
      <div className="logo-wrap">
        <Link to={PATH.BACKOFFICE} className="home-button">
          <Icon iconName="fa fa-home" />
        </Link>
        <Link to={PATH.BACKOFFICE} className="logo" />
      </div>
      <div className="button-wrap">
        <ul>
          <li className="my">
            <span>{connectShop?.name}</span>
            <Link className="my-page-button" to={`${PATH.MY_PAGE}/shop`}>
              내정보
            </Link>
          </li>
          <li className="notification">
            <Link className="link" to={PATH.NOTIFICATION}>
              {!!unreadNotiCount && (
                <span className="noti-count">
                  <Icon iconName="far fa-bell" />
                </span>
              )}
              <span className="text">알림</span>
            </Link>
          </li>
          <li className="logout">
            <span className="link" onClick={onLogout}>
              로그아웃
            </span>
          </li>
        </ul>
      </div>
    </StyledComponent>
  );
};

export default Header;
