import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';

const StyledComponent = styled.div`
  ${({ theme: { sizes } }) => css`
    min-width: 1200px;
    padding-top: ${sizes.headerHeight};
    padding-left: ${sizes.asideWidth};
    transition: padding-left 300ms ease;

    ${media.mobile`
      min-width :0;
      padding-left: 0;
    `}
  `}
`;

const Content: React.FC = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

export default Content;
