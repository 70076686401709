import * as React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import styled from 'styles/Styled';

import ToggleArrowDown from 'reusable/toggle-arrow-down.svg';

interface FinalizedInsuranceForm {
  insuranceId?: number;
  insuranceRenewalDate?: string;
  price?: number;
  propertyDamageLiability?: number;
  bodilyInjuryCoverageId?: number;
  file?: { name: string };
}

interface IProps {
  options: any;
  typeOfBodilyInjuryCoverageSavedString: string;
  typeOfBodilyInjuryCoverage: string;
  typeOfBodilyInjuryCoveragePlaceholder: string;
  formInput: FinalizedInsuranceForm;
  setFormInput: (formInput: {}) => void;
}

const { useState, useEffect } = React;

const StyledComponent = styled.div`
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      border-color: #ced4da;
      color: #b5b5b5;
      background-color: white;
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        right: 3%;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(46, 151, 250, 0.25);
      }
      &:active {
        color: #b5b5b5;
        background-color: white;
      }
      &.black-text {
        color: black;
      }
    }
  }
`;

const SelfOrAutoLiabilityDropDown: React.FC<IProps> = ({
  options,
  typeOfBodilyInjuryCoverageSavedString,
  typeOfBodilyInjuryCoverage,
  typeOfBodilyInjuryCoveragePlaceholder,
  formInput,
  setFormInput,
}) => {
  const [dropdownClass, updateDropdownClass] = useState('');

  const [typeOfbodilyInjuryCoverageIdStringValue, setTypeOfbodilyInjuryCoverageIdStringValue] = useState(
    '',
  );

  const [dropdownMarkup, setDropdownMarkup] = useState([]);
  useEffect(() => {
    const processDropdownClick = (e: any) => {
      e.preventDefault();
      const bodilyInjuryCoverageNameString = e.target.innerHTML;
      setTypeOfbodilyInjuryCoverageIdStringValue(bodilyInjuryCoverageNameString);
      const bodilyInjuryCoverageId = e.target.getAttribute('data-index');
      setFormInput({
        ...formInput,
        bodilyInjuryCoverageId,
        bodilyInjuryCoverageIdStringValue: bodilyInjuryCoverageNameString,
      });
    };
    const dropdownItemsMarkup = options.map((option: any, index: number) => {
      return (
        <DropdownItem
          key={index}
          data-index={index + 1}
          onClick={processDropdownClick}
        >
          {option}
        </DropdownItem>
      );
    });
    setDropdownMarkup(dropdownItemsMarkup);
  }, [setDropdownMarkup, options, formInput, setFormInput]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (typeOfBodilyInjuryCoverageSavedString) {
      updateDropdownClass('dropdown-toggle black-text');
    }
  }, [typeOfBodilyInjuryCoverageSavedString]);

  return (
    <StyledComponent>
      <label htmlFor="typeOfBodilyInjuryCoverage">
        {typeOfBodilyInjuryCoverage}
      </label>
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className={dropdownClass}>
          {typeOfbodilyInjuryCoverageIdStringValue ||
            typeOfBodilyInjuryCoverageSavedString ||
            typeOfBodilyInjuryCoveragePlaceholder}
            <img
              className="toggle-icon"
              src={ToggleArrowDown}
              alt="toggle-arrow-down-icon"
            />
        </DropdownToggle>
        <DropdownMenu>{dropdownMarkup}</DropdownMenu>
      </Dropdown>
    </StyledComponent>
  );
};

export default SelfOrAutoLiabilityDropDown;
