import * as React from 'react';

type TReturn = [string[] | null];
const { useState, useEffect } = React;

export default (files: FileList | null): TReturn => {
  const [result, setResult] = useState<string[] | null>(null);

  useEffect(() => {
    if (!files) {
      setResult(null);
      return;
    }

    if (!files.length) {
      setResult([]);
      return;
    }

    const resultArray: string[] = [];
    [].forEach.call(files, file => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        if (fileReader.result) {
          resultArray.push(fileReader.result as string);
          if (resultArray.length === files.length) {
            setResult(resultArray);
          }
        }
      };
    });
  }, [files]);

  return [result];
};
