import Sendbird from 'sendbird';

export const getChatting = (
  channel: Sendbird.GroupChannel,
  listQuery: Sendbird.PreviousMessageListQuery,
  messageCount = 20,
) =>
  new Promise((resolve, reject) => {
    if (listQuery.isLoading || !listQuery.hasMore) {
      resolve({
        listQuery,
        chatting: [],
      });
    }

    listQuery.load(messageCount, false, (response, error) => {
      if (error) {
        reject(error);
      }
      resolve({
        listQuery,
        chatting: response,
      });
    });
  });

export const sendChatting = (
  connection: Sendbird.SendBirdInstance,
  channel: Sendbird.GroupChannel,
  message: string,
) =>
  new Promise((resolve, reject) => {
    const params = new connection.UserMessageParams();
    params.message = message;
    channel.sendUserMessage(params, (response, error) => {
      if (error) {
        reject(error);
      }
      resolve(response);
    });
  });

export const sendFile = (channel: Sendbird.GroupChannel, files: FileList) =>
  new Promise((resolve, reject) => {
    const resultFiles: any = [];
    [].forEach.call(files, file => {
      channel.sendFileMessage(file, (response, error) => {
        if (error) {
          reject(error);
        }
        resultFiles.push(response);
        if (files.length === resultFiles.length) {
          resolve(resultFiles);
        }
      });
    });
  });
