import { darken, lighten } from 'polished';
import styled, { css } from 'styles/Styled';

export default styled.li<{ myMessage: boolean }>`
  ${({ myMessage, theme: { sizes, colors } }) => css`
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;

    .content {
      flex: 1;
      padding-left: 1.5rem;
      display: flex;
      justify-content: flex-start;
      font-size: 0.925rem;

      .wrap {
        max-width: 85%;
        min-width: 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        .box {
          width: 100%;
          padding: 1rem;
          border-radius: ${sizes.borderRadius};
          box-shadow: 0 0 5px #ddd;
          background-color: white;
          color: ${colors.gray1};
          white-space: pre-wrap;

          .card-icon {
            margin-bottom: 1rem;
            font-size: 5em;
            line-height: 1.5rem;
            text-align: center;
          }
        }

        img {
          max-width: 300px;
          border-radius: ${sizes.borderRadius};
          transition: opacity 500ms ease;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }

        time {
          margin-top: 0.75rem;
          font-size: 10.5px;
          color: ${colors.gray3};
        }
      }
    }

    ${myMessage &&
      css`
        .content {
          padding: 0;
          justify-content: flex-end;

          .wrap {
            align-items: flex-start;

            .box {
              background-color: ${lighten(0.35, colors.primary)};
              color: ${darken(0.35, colors.primary)};
            }
          }
        }
      `}
  `}
`;
