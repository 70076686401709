import * as React from 'react';
import tostr from 'toastr';

type TReturn = [FileList | null, () => void];
const { useState } = React;

export default (attrs?: {
  multiple?: boolean;
  accept: string;
  maxLength?: number;
}): TReturn => {
  const [files, setFiles] = useState<FileList | null>(null);

  const onClick = () => {
    const input = document.createElement('input');
    input.id = 'fake-file-selector';
    input.type = 'file';
    input.style.display = 'none';

    if (attrs) {
      input.multiple = attrs.multiple || false;
      input.accept = attrs.accept || '';
    }

    input.onchange = e => {
      document.body.removeChild(
        document.getElementById('fake-file-selector') as HTMLElement,
      );
      const { files: selectedFiles } = e.target as HTMLInputElement;
      if (!selectedFiles) {
        return;
      }
      if (!!attrs?.maxLength && selectedFiles.length > attrs.maxLength) {
        tostr.error(`최대 ${attrs.maxLength}개 까지만 등록할 수 있습니다.`);
        return;
      }
      setFiles(selectedFiles);
    };

    document.body.appendChild(input);
    input.click();
  };

  return [files, onClick];
};
