import { css } from './Styled';
import theme from './Theme';

const { sizes, colors } = theme;

export const inputButton = css`
  height: 3rem;
  padding: 0 1rem;

  &.small {
    height: 2.5rem;
    padding: 0 1rem;
    font-size: 0.925rem;
  }

  &.large {
    height: 3.85rem;
    padding: 1 1.75rem;
    font-size: 0.95rem;
    font-size: 1.05rem;
  }
`;

export const checkboxRadio = css`
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-left: 2rem;
  }

  &.disabled .box {
    background-color: ${colors.gray7};
  }

  input {
    display: none;

    &:checked + .box {
      border-color: ${colors.primary};

      i {
        opacity: 1;
      }
    }
  }

  .box {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 1px;
    border: 1px solid ${colors.gray5};
    background-color: white;
    transition: border-color 300ms, background-color 300ms ease;

    i {
      font-size: 10px;
      color: ${colors.primary};
      opacity: 0;
      transform: scale(0.825);
      transition: opacity 300ms ease;
    }
  }

  .box-label {
    margin-left: 0.35rem;
    font-size: 0.925rem;
    color: ${colors.gray1};
  }

  &:hover {
    .box {
      background-color: ${colors.gray7};
      cursor: pointer;
    }

    cursor: pointer;
  }
`;

export const inputSelectWrap = css`
  border: 1px solid ${colors.gray6};
  border-radius: ${sizes.radius};
  transition: border-color 300ms ease;

  &:focus-within {
    border-color: ${colors.primary};
  }

  &.error {
    border-color: ${colors.red};
  }

  input,
  select,
  textarea {
    width: 100%;
    border: none;
    font-size: 1rem;
    outline: none !important;

    &::placeholder {
      color: ${colors.gray4};
    }
  }
`;
