import * as React from 'react';
import styled from 'styles/Styled';

const StyledComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .spinner {
      > div {
        width: 40px;
        height: 40px;
        font-size: 15px;
        color: ${props => props.theme.colors.gray1};
      }
    }

    .message {
      margin-top: 25px;
      font-size: 14px;
      text-align: center;
      color: ${props => props.theme.colors.gray1};
    }
  }
`;

interface IProps {
  show?: boolean;
  style?: React.CSSProperties;
}

const Loading: React.FC<IProps> = ({ show = true, style, children }) =>
  show ? (
    <StyledComponent style={style}>
      <div className="wrap">
        <div className="spinner">
          <div className="spinner-border" />
        </div>
        {children && <div className="message">{children}</div>}
      </div>
    </StyledComponent>
  ) : null;

export default Loading;
